/**
 * @author      Nandani.V.Patel
 * @date        16 Oct 2024
 * @description ActionTypes and ActionCreator Function for User Reviews.
 * @param
 * @response      
**/

export const ActionTypes = {
  GET_LATEST_USER_REVIEW_REQUEST: 'GET_LATEST_USER_REVIEW_REQUEST',
  GET_LATEST_USER_REVIEW_SUCCESS: 'GET_LATEST_USER_REVIEW_SUCCESS',
  GET_LATEST_USER_REVIEW_FAILURE: 'GET_LATEST_USER_REVIEW_FAILURE',
};

export const getlatestUserReviewRequest = () => {
  return {
    type: ActionTypes.GET_LATEST_USER_REVIEW_REQUEST,
  };
};