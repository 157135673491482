import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './dashboardcountActions';
import { getRequestAPI } from '../../global/api';
import { defaultErrorMsg } from '../../utils/conts';

/**
 * @author      Nandani.V.Patel
 * @date        22 Oct 2024
 * @description Dashboard count Saga and handling API response.
 * @param       
 * @response    Dashboard count is success or fail.  
**/

function* dashboardusercountSaga() {
  try {
    const response = yield call(getRequestAPI, { url: 'dashboard' });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.GET_DASHBOARD_USER_COUNT_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.GET_DASHBOARD_USER_COUNT_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_DASHBOARD_USER_COUNT_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* dashboardCommissionSaga() {
  try {
    const response = yield call(getRequestAPI, { url: 'commission' });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.GET_TEAM_LEADER_DASHBOARD_COMMISSION_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.GET_TEAM_LEADER_DASHBOARD_COMMISSION_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_TEAM_LEADER_DASHBOARD_COMMISSION_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* dashboarduserReviewSaga() {
  try {
    const response = yield call(getRequestAPI, { url: 'company/user-review-count' });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.GET_COMPANY_DASHBOARD_USER_REVIEW_COUNT_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.GET_COMPANY_DASHBOARD_USER_REVIEW_COUNT_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_COMPANY_DASHBOARD_USER_REVIEW_COUNT_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* dashboardteamleadercountSaga() {
  try {
    const response = yield call(getRequestAPI, { url: 'team-leader/dashboard' });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.GET_TEAM_LEADER_DASHBOARD_COUNT_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.GET_TEAM_LEADER_DASHBOARD_COUNT_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_TEAM_LEADER_DASHBOARD_COUNT_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* dashboardcompanycountSaga() {
  try {
    const response = yield call(getRequestAPI, { url: 'company/dashboard' });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.GET_COMPANY_DASHBOARD_COUNT_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.GET_COMPANY_DASHBOARD_COUNT_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_COMPANY_DASHBOARD_COUNT_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* watchDashboardUserCountRequest() {
  yield takeLatest(ActionTypes.GET_DASHBOARD_USER_COUNT_REQUEST, dashboardusercountSaga);
  yield takeLatest(ActionTypes.GET_TEAM_LEADER_DASHBOARD_COMMISSION_REQUEST, dashboardCommissionSaga);
  yield takeLatest(ActionTypes.GET_COMPANY_DASHBOARD_USER_REVIEW_COUNT_REQUEST, dashboarduserReviewSaga);
  yield takeLatest(ActionTypes.GET_TEAM_LEADER_DASHBOARD_COUNT_REQUEST, dashboardteamleadercountSaga);
  yield takeLatest(ActionTypes.GET_COMPANY_DASHBOARD_COUNT_REQUEST, dashboardcompanycountSaga);
}

function* dashboardUserCountSaga() {
  yield fork(watchDashboardUserCountRequest);
}

export default dashboardUserCountSaga;