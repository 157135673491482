import { ActionTypes } from './resetpasswordActions';

/**
 * @author      Nandani.V.Patel
 * @date        14 Oct 2024
 * @description Reset-password Reducer.
 * @param       takes default and previous state value
 * @response    update a state value and store Reset-password api response  
**/

const defaultState = {
  isreset: false,
  data: undefined,
};

const resetPasswordReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        data: undefined,
        isreset: true,
      };
    case ActionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        data: action.data,
        isreset: false,
      };
    case ActionTypes.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isreset: false,
        data: undefined,
        error: action.error,
      };
    default:
      return state;
  }
}

export default resetPasswordReducer;