import { ActionTypes } from './forgotpasswordActions';

/**
 * @author      Nandani.V.Patel
 * @date        14 Oct 2024
 * @description forgot-password Reducer.
 * @param       takes default and previous state value
 * @response    update a state value and store forgot-password api response  
**/

const defaultState = {
    isforgotpassword: false,
    data: undefined
};

const forgotPasswordReducer = (state = defaultState, action) => {
    switch (action.type) {
        case ActionTypes.FORGOT_PASSWORD_REQUEST:
            return {
                ...state,
                data: undefined,
                isforgotpassword: true,
            };
        case ActionTypes.FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                data: action.data,
                isforgotpassword: false,
            };
        case ActionTypes.FORGOT_PASSWORD_FAILURE:
            return {
                ...state,
                isforgotpassword: false,
                data: undefined,
                error: action.error,
            };
        default:
            return state;
    }
}

export default forgotPasswordReducer;