/**
 * @author      Nandani.V.Patel
 * @date        10 Oct 2024
 * @description ActionTypes and ActionCreator Function for User Logout Request.
 * @param
 * @response   
**/

export const ActionTypes = {
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',
};

export const logoutRequest = (token) => {
  return {
    type: ActionTypes.LOGOUT_REQUEST,
    payload: token,
  };
};