import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CREATEACCOUNT, DASHBOARD } from '../../global/routes';
import { isAuth } from '../../utils/auth';
import { useNavigate } from 'react-router-dom';
import Preloader from '../../component/Preloader';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import * as UserReviewActions from '../userReview/userReviewActions';
import * as DashboardCountActions from '../dashboardCount/dashboardcountActions';
import * as CategoryListActions from '../categorylist/categorylistActions';
import * as CompanylistingActions from '../companylist/companylistActions';
import styles from './styles.module.scss';

/**
 * @author      Nandani.V.Patel
 * @date        8th Oct 2024
 * @description UI Implementation of Home
 * @param
 * @response 
**/

const Home = () => {
  const navigate = useNavigate();
  const userReview = useSelector((state) => state?.userReview);
  const categorylist = useSelector((state) => state?.categorylist?.data?.data);
  const counts = useSelector((state) => state?.dashboardCounts?.dashboardcount);
  const reviewSites = useSelector((state) => state?.companylist?.websites);

  const [firstInit, setFirstInit] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const auth = isAuth();
  useEffect(() => {
    if (auth) {
      navigate(DASHBOARD);
    }
  }, [auth, navigate]);

  useEffect(() => {
    if (firstInit) {
      dispatch(UserReviewActions.getlatestUserReviewRequest());
      dispatch(DashboardCountActions.getdashboardcount());
      dispatch(CategoryListActions.getCategorylistRequest());
      dispatch(CompanylistingActions.getwebsiteRequest());
      setFirstInit(false);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [firstInit]);

  useEffect(() => {
    setFirstInit(true);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const reviewsettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const categorysettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const reviews = userReview?.userreview?.data || [];

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <div className="banner-area bg-f0f4fc" style={{ paddingTop: '75px' }}>
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="banner-content">
                    <div className="banner-title">
                      <h1>Boost Your Brand with Trusted Reviews</h1>
                    </div>
                    <div className="shape-1">
                      <img src="/assets/images/banner/shape-1.png" alt="Image" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="banner-image-content">
                    <div className="row aligns-item-center">
                      <div
                        className="col-lg-6 col-sm-6 col-6 aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-duration={1200}
                        data-aos-delay={400}
                      >
                        <div className="banner-img-1">
                          <img
                            src="/assets/images/banner/banner-img-1.jpg"
                            alt="Image"
                          />
                          <div className="shape-2">
                            <img src="/assets/images/banner/shape-2.png" alt="Image" />
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-lg-6 col-sm-6 col-6 aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-duration={1200}
                        data-aos-delay={400}
                      >
                        <div className="banner-img-1">
                          <img
                            src="/assets/images/banner/banner-img-4.jpg"
                            alt="Image"
                          />
                          <div className="shape-2">
                            <img src="/assets/images/banner/shape-2.png" alt="Image" />
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-lg-6 col-sm-6 col-6 aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-duration={1200}
                        data-aos-delay={400}
                      >
                        <div className="banner-img-1">
                          <img
                            src="/assets/images/banner/banner-img-2.jpg"
                            alt="Image"
                          />
                          <div className="shape-2">
                            <img src="/assets/images/banner/shape-2.png" alt="Image" />
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-lg-6 col-sm-6 col-6 aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-duration={1200}
                        data-aos-delay={400}
                      >
                        <div className="banner-img-1">
                          <img
                            src="/assets/images/banner/banner-img-3.jpg"
                            alt="Image"
                          />
                          <div className="shape-2">
                            <img src="/assets/images/banner/shape-2.png" alt="Image" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="assisted-candidate d-none"
                      data-aos="fade-up-right" USERS
                      data-aos-duration={1300}
                      data-aos-delay={600}
                    >
                      <div className="icon">
                        <img src="/assets/images/icon/icon-1.png" alt="Image" />
                      </div>
                      <h3>50K+</h3>
                      <span>Assisted Candidate</span>
                    </div>
                    <div
                      className="creative-agency d-none"
                      data-aos="fade-down-left"
                      data-aos-duration={1400}
                      data-aos-delay={600}
                    >
                      <div className="icon">
                        <i className="flaticon-bag" />
                      </div>
                      <h3>Creative Agency</h3>
                      <span>Upload Your CV</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="job-categories-area ptb-100" style={{ padding: '50px 0' }}>
            <div className="container" style={{ maxWidth: '1200px', margin: '0 auto' }}>
              <div className="section-title" style={{ textAlign: 'center', marginBottom: '40px' }}>
                <h2>Review Sites</h2>
                <p>Explore our curated list of 16 popular review sites where users can share their experiences and feedback. From trusted platforms to niche review sites, these options provide valuable insights to help businesses grow and improve.</p>
              </div>
              <Slider {...settings}>
                {reviewSites?.map((website) => (
                  <div key={website?.id} style={{ flex: '1 1 auto' }}>
                    <div
                      className="single-category-card single-review-site-card"
                      tabIndex="-1"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '165px',
                        padding: '20px',
                        boxSizing: 'border-box',
                        textAlign: 'center',
                        margin: '0 5px',
                        width: 'auto',
                      }}
                    >
                      <div className="icon d-flex align-items-center" style={{ marginBottom: '20px' }}>
                        <img
                          src={website?.review_site_logo || '/assets/images/placeholderImage/50x50.svg'}
                          alt={website?.review_site_tbl_name}
                          style={{ width: '100%', height: 'auto', maxHeight: '100px', objectFit: 'contain' }} // Ensures responsive image
                          onError={(e) => {
                            e.target.onerror = null; // Prevents infinite fallback loop
                            e.target.src = '/assets/images/placeholderImage/50x50.svg';
                          }}
                        />
                      </div>
                      <h3
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '200px',
                        }}
                      >
                        {website?.review_site_tbl_name}
                      </h3>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          <div className="job-categories-area ptb-100">
            <div className="container">
              <div className="section-title">
                <h2>Most Demanded Categories</h2>
                <p>Discover the top categories that businesses are actively seeking reviews for. From tech startups to retail giants, explore the sectors driving demand and find the perfect fit for your company's needs.</p>
              </div>
              <Slider {...categorysettings}>
                {categorylist?.map((category) => (
                  <div key={category?.id}>
                    <div
                      className="single-category-card"
                      tabIndex="-1"
                      style={{ display: 'inline-block', width: 'auto', margin: '0 5px' }}
                    >
                      <div className="icon">
                        <img
                          src={category.category_image_url || '/assets/images/placeholderImage/50x50.svg'}
                          onError={(e) => {
                            e.target.onerror = null; // Prevents infinite fallback loop
                            e.target.src = '/assets/images/placeholderImage/50x50.svg';
                          }}
                        />
                      </div>
                      <h3>{category.category_name}</h3>
                      <p className='catg-descr'>{category.category_description}</p>
                    </div>
                  </div>
                ))}
              </Slider>
            </div >
          </div >
          <div className="reviews-area bg-f0f5f7 ptb-100">
            <div className="container">
              <div className="section-title">
                <h2>Review Of The Users</h2>
                <p>Check out reviews from both users and team leaders who actively contribute to the platform. Their valuable insights and feedback help shape the experience, ensuring continuous improvement and reliable results for businesses.</p>
              </div>
              {reviews?.length > 0 ? (
                <Slider {...reviewsettings}>
                  {reviews?.map((review) => (
                    <div key={review?.review_id}>
                      <div
                        className="single-reviews-card bu"
                        tabIndex="-1"
                        style={{
                          minHeight: '300px',
                          maxHeight: '300px',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          margin: '0 10px',
                        }}
                      >
                        <p>
                          {/* {`"${review.user_first_name} ${review.user_last_name} shared their review."`} */}
                          “Morbi porttitor ligula id varius consectetur. Integer ipsum justo, congue sit amet massa vel, porttitor semper magna. Orci varius natoque penatibus et magnis dis parturient”
                        </p>
                        <div className="clien-info">
                          <h3>{review?.user_first_name}</h3>
                          <span>{review?.user_last_name}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              ) : (
                <div className={styles.reviewtxt}>
                  <h3>No reviews available</h3>
                </div>
              )}
            </div>
          </div>
          <div className="counter-area">
            <div className="container">
              <div className="counter-overly">
                <div className="row">
                  <div className={`${'col-lg-4 col-md-6'} ${styles.counts}`}>
                    <div className="single-counter-item ">
                      <div className="icon">
                        <i className="flaticon-company" />
                      </div>
                      <h1>
                        <span className="odometer" data-count={counts?.companyCount || 0}>
                          {counts?.companyCount || 0}
                        </span>
                        <span className="target">+</span>
                      </h1>
                      <p>Companies</p>
                    </div>
                  </div>
                  <div className={`${'col-lg-4 col-md-6'} ${styles.counts}`}>
                    <div className="single-counter-item">
                      <div className="icon">
                        <i className="flaticon-recruitment" />
                      </div>
                      <h1>
                        <span className="odometer" data-count={counts?.reviewCount || 0}>
                          {counts?.reviewCount || 0}
                        </span>
                      </h1>
                      <p>Reviews</p>
                    </div>
                  </div>
                  <div className={`${'col-lg-4 col-md-6'} ${styles.counts}`}>
                    <div className="single-counter-item">
                      <div className="icon">
                        <i className="flaticon-user" />
                      </div>
                      <h1>
                        <span className="odometer" data-count={counts?.userCount || 0}>
                          {counts?.userCount || 0}
                        </span>
                      </h1>
                      <p>Users</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="contact-area bg-f0f5f7">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-8 col-md-9">
                  <div className="contact-left-content">
                    <h2>Find Your Next Opportunity!</h2>
                  </div>
                </div>
                <div className="col-lg-4 col-md-3">
                  <div className="contact-btn">
                    <a href={CREATEACCOUNT} className="default-btn btn">
                      Register Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
export default Home;
