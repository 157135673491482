/**
 * @author      Nandani.V.Patel
 * @date        14 Oct 2024
 * @description ActionTypes and ActionCreator Function for forgot-password Request.
 * @param
 * @response      
**/

export const ActionTypes = {
  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',
};

export const forgotpasswordRequest = (forgotpasswordData) => {
  return {
      type: ActionTypes.FORGOT_PASSWORD_REQUEST,
      payload: forgotpasswordData,
  };
};