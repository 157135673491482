import { ActionTypes } from './teamleaderuserActions';

/**
 * @author      Nandani.V.Patel
 * @date        24 Oct 2024
 * @description Team Leader User Reducer.
 * @param       takes default and previous state value
 * @response    update a state value and store Team leader User api response  
**/

const defaultState = {
  isteamleaderuser: false,
  data: undefined,
};

const TeamleaderuserReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.GET_TEAM_LEADER_USER_REQUEST:
      return {
        ...state,
        isteamleaderuser: true,
      };
    case ActionTypes.GET_TEAM_LEADER_USER_SUCCESS:
      return {
        ...state,
        data: action.data,
        isteamleaderuser: false,
      };
    case ActionTypes.GET_TEAM_LEADER_USER_FAILURE:
      return {
        ...state,
        isteamleaderuser: false,
        error: action.error,
      };
    default:
      return state;
  }
}
export default TeamleaderuserReducer;