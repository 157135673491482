import React from 'react';

const StatsBlock = ({ icon, label, count, additionalClass = "" }) => (
  <div className="col-lg-3 col-md-6 col-sm-6">
    <div className={`stats-fun-fact-box ${additionalClass}`}>
      <div className="icon-box">
        <i className={icon} />
      </div>
      <div className='d-flex flex-column justify-content-center' style={{ height: "100px" }}>
        <h3>{count}</h3>
        <span className="sub-title">{label}</span>
      </div>
    </div>
  </div>
);

export default StatsBlock;
