import { combineReducers } from 'redux'
import signUpReducer from '../../pages/registration/registerReducer';
import signInReducer from '../../pages/login/loginReducer';
import userProfileReducer from '../../pages/profile/profileReducer';
import logoutReducer from '../../pages/logout/logoutReducer';
import comapnySignUpReducer from '../../pages/comapny-Register/comapnyRegisterReducer';
import forgotPasswordReducer from '../../pages/forgotpassword/forgotpasswordReducer';
import resetPasswordReducer from '../../pages/resetpassword/resetpasswordReducer';
import categorylistReducer from '../../pages/categorylist/categorylistReducer';
import comapnyListingReducer from '../../pages/companylist/companylistReducer';
import userReviewReducer from '../../pages/userReview/userReviewReducer';
import purchaseReviewReducer from '../../pages/purchaseReview/purchaseReviewReducer';
import dashboardcountReducer from '../../pages/dashboardCount/dashboardcountReducer';
import comapnyDetailsReducer from '../../pages/companyDetails/companyDetailsReducer';
import changePasswordReducer from '../../pages/changePassword/changepasswordReducer';
import dashboardusercountReducer from '../../pages/dashboard/dashboardcountReducer';
import reviewsReducer from '../../pages/reviews/reviewsReducer';
import TeamleaderuserReducer from '../../pages/team-leader-users/teamleaderuserReducer';
import TeamleadercompanyReducer from '../../pages/team-leader-companylist/teamleaderCompanylistReducer';
import AddTeamleaderuserReducer from '../../pages/team-leader-add-user/teamleaderAdduserReducer';
import TeamleadercompanyreviewsiteReducer from '../../pages/team-leader-reviewsite/teamleaderReviewsiteReducer';
import teamleaderRevenueReducer from '../../pages/teamleaderRevenue/teamleaderRevenueReducer';
import companyUserReviewReducer from '../../pages/companyUserReview/companyUserReviewReducer';
import companyPanelReducer from '../../pages/companyPanel/redux/reducer';
import subTLReducer from '../../pages/team-leader-subtl-list/redux/reducer';
import employeePanelReducer from '../../pages/employeePanel/redux/reducer';
import withdrawalReducer from '../../pages/Withdrawal/redux/reducer';
import TeamLeaderTargetsReducer from '../../pages/team-leader-targets/redux/reducer';

/**
 * @author      Nandani.V.Patel
 * @date        08 Oct 2024
 * @description rootReducer which combaines all react-application Reducer.
 * @param       
 * @response      
**/

export const rootReducer = combineReducers({
  signup: signUpReducer,
  signin: signInReducer,
  profile: userProfileReducer,
  logout: logoutReducer,
  companySignup: comapnySignUpReducer,
  forgotpassword: forgotPasswordReducer,
  resetpassword: resetPasswordReducer,
  categorylist: categorylistReducer,
  companylist: comapnyListingReducer,
  userReview: userReviewReducer,
  purchaseReview: purchaseReviewReducer,
  dashboardCounts: dashboardcountReducer,
  companydetails: comapnyDetailsReducer,
  changepassword: changePasswordReducer,
  userdashboardcount: dashboardusercountReducer,
  reviews: reviewsReducer,
  teamleaderusers: TeamleaderuserReducer,
  teamleadercompanies: TeamleadercompanyReducer,
  addteamleaderusers: AddTeamleaderuserReducer,
  teamleaderSubTL: subTLReducer,
  teamleaderReviewsite: TeamleadercompanyreviewsiteReducer,
  teamLeaderTargets: TeamLeaderTargetsReducer,
  teamleaderRevenue: teamleaderRevenueReducer,
  companyUserReview: companyUserReviewReducer,
  companyPanel: companyPanelReducer,
  employeePanel: employeePanelReducer,
  withdrawal: withdrawalReducer,
})