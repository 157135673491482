import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { SIGNIN } from '../../global/routes';
import { useForm } from "react-hook-form";
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ForgotPasswordActions from './forgotpasswordActions';
import { NotificationContainer } from '../../component/notificationContainer';
import { notification } from '../../component/notificationContainer/notification';
import styles from './styles.module.scss'
import { validationRegex } from '../../utils/conts';

/**
 * @author      Nandani.V.Patel
 * @date        14 Oct 2024
 * @description Forgot-Password Form.
 * @param
 * @response 
**/

const Forgotpassword = (props) => {
  const { actions, forgotpassword } = props;
  const { register, handleSubmit, resetField, formState: { errors }, } = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  const onSubmit = (data) => {
    actions.forgotpasswordRequest(data);
  }

  useEffect(() => {
    if (typeof forgotpassword?.data !== 'undefined' && !forgotpassword?.isforgotpassword && forgotpassword?.data?.status === 'success') {
      notification(forgotpassword?.data?.message, 'success');
      dispatch({ type: ForgotPasswordActions.ActionTypes.FORGOT_PASSWORD_SUCCESS, data: undefined });
      resetField('user_email');
    }
    if (typeof forgotpassword?.data === 'undefined' && forgotpassword?.error?.message && !forgotpassword?.isforgotpassword) {
      notification(forgotpassword?.error?.message, 'error');
      dispatch({ type: ForgotPasswordActions.ActionTypes.FORGOT_PASSWORD_FAILURE, error: undefined });
    }
  }, [forgotpassword?.data, forgotpassword?.isforgotpassword]);

  return (
    <>
      <div className="login-area ptb-50">
        <div className="container">
          <div className="login">
            <h3>Enter your email to reset password</h3>
            <div className="form-group">
              <input
                type="email"
                {...register("user_email", {
                  required: 'Email Address is required.',
                  pattern: {
                    value: validationRegex.email,
                    message: 'Please Enter a valid Email Address.',
                  },
                })}
                className="form-control"
                placeholder="Email Address"
              />
              {errors?.user_email && <span className={styles.errormsg}>{errors.user_email.message}</span>}
            </div>
            <button
              type="submit"
              className="default-btn btn"
              onClick={handleSubmit(onSubmit)}
              disabled={forgotpassword.isforgotpassword && forgotpassword.data !== 'undefiend'}
            >
              {(!forgotpassword.isforgotpassword && forgotpassword.data !== 'undefiend')
                ?
                <>Send</>
                :
                <><div className="spinner-border " style={{ width: '1.2rem', height: '1.2rem' }} role="status" /></>
              }
            </button>
            <Link to={SIGNIN} className={styles.link}>{"<<"} Back to Login</Link>
          </div>
        </div>
      </div >
      <NotificationContainer />
    </>
  )
}
const mapStateToProps = (state) => ({
  forgotpassword: state.forgotpassword,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ForgotPasswordActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Forgotpassword);