import React from 'react';
import Profile from '../profile';
import CompanyProfile from '../companyPanel/companyProfile';
import { encryptStorage, roleId } from '../../utils/auth';
import { SIGNIN } from '../../global/routes';

/**
 * @author      Nandani.V.Patel
 * @date        28th Oct 2024
 * @description Profile Managemnet based on Role
 * @param
 * @response 
**/

const DashboardProfile = () => {
  const userRoleId = encryptStorage.getItem(roleId);

  if (typeof userRoleId === 'undefined' || userRoleId === null || userRoleId === "") {
    window.location.href = SIGNIN;
    return false;
  }

  return (
    <>
      {([1, 2, 4, 5].includes(userRoleId)) && <Profile />}
      {userRoleId === 3 && <CompanyProfile />}
    </>
  )
}
export default DashboardProfile;
