import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './teamleaderRevenueActions';
import { postRequestAPI } from '../../global/api';
import { defaultErrorMsg } from '../../utils/conts';

/**
 * @author      Nandani.V.Patel
 * @date        30 Oct 2024
 * @description Revenue Saga and handling API response.
 * @param       
 * @response    Revenue is success or fail.  
**/

function* teamleaderRevenueSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'team-leader/revenue', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.GET_TEAMLEADER_REVENUE_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.GET_TEAMLEADER_REVENUE_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_TEAMLEADER_REVENUE_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* watchTeamLeaderRevenueequest() {
  yield takeLatest(ActionTypes.GET_TEAMLEADER_REVENUE_REQUEST, teamleaderRevenueSaga);
}

function* TeamleaderRevenueSaga() {
  yield fork(watchTeamLeaderRevenueequest);
}

export default TeamleaderRevenueSaga;