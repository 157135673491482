/**
 * @author      Nandani.V.Patel
 * @date        22 Oct 2024
 * @description ActionTypes and ActionCreator Function for Dashboard count for user, companies and reviews
 * @param
 * @response      
**/

export const ActionTypes = {
  GET_DASHBOARD_USER_COUNT_REQUEST: 'GET_DASHBOARD_USER_COUNT_REQUEST',
  GET_DASHBOARD_USER_COUNT_SUCCESS: 'GET_DASHBOARD_USER_COUNT_SUCCESS',
  GET_DASHBOARD_USER_COUNT_FAILURE: 'GET_DASHBOARD_USER_COUNT_FAILURE',

  GET_TEAM_LEADER_DASHBOARD_COMMISSION_REQUEST: 'GET_TEAM_LEADER_DASHBOARD_COMMISSION_REQUEST',
  GET_TEAM_LEADER_DASHBOARD_COMMISSION_SUCCESS: 'GET_TEAM_LEADER_DASHBOARD_COMMISSION_SUCCESS',
  GET_TEAM_LEADER_DASHBOARD_COMMISSION_FAILURE: 'GET_TEAM_LEADER_DASHBOARD_COMMISSION_FAILURE',

  GET_TEAM_LEADER_DASHBOARD_COUNT_REQUEST: 'GET_TEAM_LEADER_DASHBOARD_COUNT_REQUEST',
  GET_TEAM_LEADER_DASHBOARD_COUNT_SUCCESS: 'GET_TEAM_LEADER_DASHBOARD_COUNT_SUCCESS',
  GET_TEAM_LEADER_DASHBOARD_COUNT_FAILURE: 'GET_TEAM_LEADER_DASHBOARD_COUNT_FAILURE',

  GET_COMPANY_DASHBOARD_USER_REVIEW_COUNT_REQUEST: 'GET_COMPANY_DASHBOARD_USER_REVIEW_COUNT_REQUEST',
  GET_COMPANY_DASHBOARD_USER_REVIEW_COUNT_SUCCESS: 'GET_COMPANY_DASHBOARD_USER_REVIEW_COUNT_SUCCESS',
  GET_COMPANY_DASHBOARD_USER_REVIEW_COUNT_FAILURE: 'GET_COMPANY_DASHBOARD_USER_REVIEW_COUNT_FAILURE',

  GET_COMPANY_DASHBOARD_COUNT_REQUEST: 'GET_COMPANY_DASHBOARD_COUNT_REQUEST',
  GET_COMPANY_DASHBOARD_COUNT_SUCCESS: 'GET_COMPANY_DASHBOARD_COUNT_SUCCESS',
  GET_COMPANY_DASHBOARD_COUNT_FAILURE: 'GET_COMPANY_DASHBOARD_COUNT_FAILURE',
};

export const getUserdashboardcount = () => {
  return {
    type: ActionTypes.GET_DASHBOARD_USER_COUNT_REQUEST,
  };
};

export const getdashboardCommission = () => {
  return {
    type: ActionTypes.GET_TEAM_LEADER_DASHBOARD_COMMISSION_REQUEST,
  };
};

export const getdashboardreviewcount = () => {
  return {
    type: ActionTypes.GET_COMPANY_DASHBOARD_USER_REVIEW_COUNT_REQUEST,
  };
};

export const getTeamleaderashboardcount = () => {
  return {
    type: ActionTypes.GET_TEAM_LEADER_DASHBOARD_COUNT_REQUEST,
  };
};

export const getcompanydashboardcount = () => {
  return {
    type: ActionTypes.GET_COMPANY_DASHBOARD_COUNT_REQUEST,
  };
};