import { ActionTypes } from './teamleaderAdduserActions';

/**
 * @author      Nandani.V.Patel
 * @date        24 Oct 2024
 * @description Add Team Leader User Reducer.
 * @param       takes default and previous state value
 * @response    update a state value and store Add Team leader User api response  
**/

const defaultState = {
  isadduser: false,
  data: undefined,
  unassigneduserData: undefined,
  isunassigneduser: false
};

const AddTeamleaderuserReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_TEAM_LEADER_USER_REQUEST:
      return {
        ...state,
        isadduser: true,
      };
    case ActionTypes.ADD_TEAM_LEADER_USER_SUCCESS:
      return {
        ...state,
        data: action.data,
        isadduser: false,
      };
    case ActionTypes.ADD_TEAM_LEADER_USER_FAILURE:
      return {
        ...state,
        isadduser: false,
        error: action.error,
      };

    case ActionTypes.GET_UNASSIGNED_USER_REQUEST:
      return {
        ...state,
        isunassigneduser: true,
      };
    case ActionTypes.GET_UNASSIGNED_USER_SUCCESS:
      return {
        ...state,
        unassigneduserData: action.data,
        isunassigneduser: false,
      };
    case ActionTypes.GET_UNASSIGNED_USER_FAILURE:
      return {
        ...state,
        isunassigneduser: false,
        error: action.error,
      };

    default:
      return state;
  }
}
export default AddTeamleaderuserReducer;