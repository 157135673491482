/**
 * @author      Nandani.V.Patel
 * @date        15 Oct 2024
 * @description ActionTypes and ActionCreator Function for Coampny-list Request.
 * @param
 * @response      
**/

export const ActionTypes = {
  COMPANY_LISTING_REQUEST: 'COMPANY_LISTING_REQUEST',
  COMPANY_LISTING_SUCCESS: 'COMPANY_LISTING_SUCCESS',
  COMPANY_LISTING_FAILURE: 'COMPANY_LISTING_FAILURE',

  GET_WEBSITE_REQUEST: 'GET_WEBSITE_REQUEST',
  GET_WEBSITE_SUCCESS: 'GET_WEBSITE_SUCCESS',
  GET_WEBSITE_FAILURE: 'GET_WEBSITE_FAILURE',

  GET_PENDING_TARGET_COMPANIES: 'GET_PENDING_TARGET_COMPANIES',
  GET_PENDING_TARGET_COMPANIES_SUCCESS: 'GET_PENDING_TARGET_COMPANIES_SUCCESS',
  GET_PENDING_TARGET_COMPANIES_FAILURE: 'GET_PENDING_TARGET_COMPANIES_FAILURE',
};

export const companylistingRequest = (companylistData) => {
  return {
    type: ActionTypes.COMPANY_LISTING_REQUEST,
    payload: companylistData,
  };
};

export const getwebsiteRequest = () => {
  return {
    type: ActionTypes.GET_WEBSITE_REQUEST,
  };
};

export const getPendingTargetCompanies = (payload) => {
  return {
    type: ActionTypes.GET_PENDING_TARGET_COMPANIES,
    payload
  };
};