import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './purchaseReviewActions';
import { getRequestAPI } from '../../global/api';
import { defaultErrorMsg } from '../../utils/conts';

/**
 * @author      Nandani.V.Patel
 * @date        16 Oct 2024
 * @description Company Purchase Review Saga and handling API response.
 * @param       
 * @response    Company Purchase Review is success or fail.  
**/

function* companyPurchaseReviewSaga() {
  try {
    const response = yield call(getRequestAPI, { url: 'get-latestPurchaseReview' });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.GET_PURCHASE_REVIEW_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.GET_PURCHASE_REVIEW_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_PURCHASE_REVIEW_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* watchPurchaseReviewRequest() {
  yield takeLatest(ActionTypes.GET_PURCHASE_REVIEW_REQUEST, companyPurchaseReviewSaga);
}

function* purchaseReviewsSaga() {
  yield fork(watchPurchaseReviewRequest);
}

export default purchaseReviewsSaga;