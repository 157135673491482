import { Fragment, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { store } from './store/index';
import { BrowserRouter, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import {
  SIGNIN,
  SIGNUP,
  NOTFOUND,
  HOME,
  WORKFLOW,
  PROFILE,
  COMPANYDETAILS,
  CATEGORYLIST,
  COMPANYLIST,
  COMPANYREGISTER,
  FORGOTPASSWORD,
  RESETPASSWORD,
  CREATEACCOUNT,
  DASHBOARD,
  COMPANYID,
  CHANGEPASSWORD,
  REVIEWS,
  TERMSCONDITIONS,
  TEAMLEADERUSERS,
  ADDTEAMLEADERUSERS,
  PURCHASE_REVIEWS,
  CHECKOUT_PAGE,
  TRANSACTION_HISTORY,
  ORDER_DETAILS,
  USER_DETAILS,
  REVENUE,
  WITHDRAWAL,
  SUBTL_LIST,
  ADD_SUBTL,
  CREATETEAMLEADERUSERS,
  EMPCOMPANIES,
  CREATEEMPCOMPANY,
  MYTARGETS,
  EMPCOMPANYTARGETHISTORY,
  EMPEARNINGS,
  ASSIGNTARGET_SUBTL,
  UNAUTHORIZED
} from './global/routes';

import Login from './pages/login';
import Registration from './pages/registration';
import NotFound from './pages/notfound';
import Home from './pages/Home';
import DashboardProfile from './pages/dashboardProfile';
import ComapnyDeatils from './pages/companyDetails';
import CategoryList from './pages/categorylist';
import CompanyList from './pages/companylist';
import CompanyRegister from './pages/comapny-Register';
import Forgotpassword from './pages/forgotpassword';
import Resetpassword from './pages/resetpassword';
import CreateAccount from './pages/createAccount';
import Dashboard from './pages/dashboard';
import ChangePassword from './pages/changePassword';
import Reviews from './pages/reviews';
import TermsConditions from './pages/terms-conditions';

import TeamLeaderUsers from './pages/team-leader-users';
import TeamLeaderCompanyList from './pages/team-leader-companylist';
import TeamleaderAddUser from './pages/team-leader-add-user';
import CreateUser from './pages/team-leader-add-user/createUser';
import TeamLeaderReviewsite from './pages/team-leader-reviewsite';
import TeamLeaderRevenue from './pages/teamleaderRevenue';
import CreateSubTL from './pages/team-leader-subtl-list/create';
import SubTLList from './pages/team-leader-subtl-list';
import TLTargets from "./pages/team-leader-targets";
import AssignSubTLTarget from './pages/team-leader-targets/assignSubTLTarget';

// import CompanyUserList from './pages/companyUserlist';
import PurchaseReviews from './pages/companyPanel/purchaseReviews';
import CheckoutPage from './pages/companyPanel/purchaseReviews/checkoutPage';
import TransactionHistory from './pages/companyPanel/transactionHistory';
import OrderDetails from './pages/companyPanel/orderDetails';
import UserDetails from './pages/companyPanel/orderDetails/userDetails';
// import CompanyUserReview from './pages/companyUserReview';

import EmployeeCompanyList from './pages/employeePanel/companyList';
import CreateCompany from './pages/employeePanel/companyList/createCompany';
import MyTargets from './pages/employeePanel/myTargets';
import CompanyTargetsHistory from './pages/employeePanel/companyList/companyTargetsHistory';
import EarningsOverview from './pages/employeePanel/earningsOverview';

import Index from './pages/Withdrawal';

import MainLayout from './layouts/mainLayout';
import LayoutWrapper from './layouts/layoutWrapper';
import PrivateRoute from './utils/privateRoute';
import { encryptStorage, isAuth, roleId } from './utils/auth';

import WorkFlow from './component/How-It-Works';
import UnauthorizedPage from './pages/unauthorizedPage/UnauthorizedPage';

/**
 * @author      Nandani.V.Patel
 * @date        08 Oct 2024
 * @description Rendering React Application component based on Routes.
 * @param
 * @response 
**/

const RegistrationWithRedirect = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const accountType = queryParams.get("accountType");

    if (!accountType) {
      navigate(CREATEACCOUNT);
    }
  }, [location, navigate]);

  return <Registration />;
};

const TeamLeaderUsersWrapper = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('userid');
  const companyId = queryParams.get('companyid');
  // Conditionally render components based on query parameters
  if (userId && companyId) {
    return <TeamLeaderReviewsite userId={userId} companyId={companyId} />;
  } else if (userId) {
    return <TeamLeaderCompanyList userId={userId} />;
  } else {
    return <TeamLeaderUsers />;
  }
};

// ProtectedRoute Component for Company without sidebar and header UI and only for RoleId 1-users
const ProtectedRoute = ({ element, requiredRole }) => {
  const userRoleId = encryptStorage.getItem(roleId);
  const auth = isAuth();

  if (!auth) {
    return <Navigate to={SIGNIN} replace />;
  } else if (auth && userRoleId !== requiredRole) {
    // return <Navigate to={NOTFOUND} replace />;
    return <Navigate to={UNAUTHORIZED} replace />;
  }

  return element;
};

// render My Targets component based on role
const MyTargetWrapper = () => {
  const role_id = encryptStorage.getItem(roleId);

  if (role_id === 4) {
    return <MyTargets />
  } else if (role_id === 2 || role_id === 5) {
    return <TLTargets />
  }
};

const App = () => {
  // const [location, setLocation] = useState("");
  // useEffect(() => {
  //   const fetchLocation = async () => {
  //     try {
  //       const response = await fetch(`https://ipinfo.io/json`);
  //       const data = await response.json();
  //       console.log("data:", data);

  //       setLocation(data.country);
  //     } catch (error) {
  //       console.error("Error fetching geolocation:", error);
  //     }
  //   };
  //   fetchLocation();
  // }, []);
  // console.log("location:", location);
  // useEffect(() => {
  //   const tooltipTriggerEl = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  //   console.log("tooltipTriggerEl:", tooltipTriggerEl);
  //   tooltipTriggerEl.forEach((tooltipTriggerEl) => {
  //     new window.bootstrap.Tooltip(tooltipTriggerEl);
  //   })
  // });

  return (
    <Fragment>
      <BrowserRouter>
        <Provider store={store}>
          <Routes>
            <Route
              path="/"
              element={<MainLayout pageTitle="Home"><Home /></MainLayout>}
            />
            <Route
              path={SIGNIN}
              element={<MainLayout pageTitle="Login"><Login /></MainLayout>}
            />
            <Route
              path={SIGNUP}
              element={<MainLayout pageTitle="Registration"><RegistrationWithRedirect /></MainLayout>}
            />
            <Route
              path={CREATEACCOUNT}
              element={<MainLayout pageTitle="Create Account"><CreateAccount /></MainLayout>}
            />
            <Route
              path={TERMSCONDITIONS}
              element={<MainLayout pageTitle="Terms & Conditions"><TermsConditions /></MainLayout>}
            />
            <Route
              path={HOME}
              element={<MainLayout pageTitle="Home"><Home /></MainLayout>}
            />
            <Route
              path={WORKFLOW}
              element={<MainLayout pageTitle="How It Works"><WorkFlow /></MainLayout>}
            />
            <Route
              path={NOTFOUND}
              element={<MainLayout pageTitle="404 Error"><NotFound /></MainLayout>}
            />
            <Route
              path={UNAUTHORIZED}
              element={<MainLayout pageTitle="403 Error"><UnauthorizedPage /></MainLayout>}
            />
            <Route
              path={CATEGORYLIST}
              element={<MainLayout pageTitle="Category list"><CategoryList /></MainLayout>}
            />
            <Route
              path={COMPANYLIST}
              element={
                <ProtectedRoute
                  element={<MainLayout pageTitle="Company list"><CompanyList /></MainLayout>}
                  requiredRole={1}
                />
              }
            />
            <Route
              path={`${COMPANYDETAILS}${COMPANYID}`}
              element={
                <ProtectedRoute
                  element={<MainLayout pageTitle="CompanyDetails"><ComapnyDeatils /></MainLayout>}
                  requiredRole={1}
                />
              }
            />
            <Route
              path={COMPANYREGISTER}
              element={<MainLayout pageTitle="Company Registration"><CompanyRegister /></MainLayout>}
            />
            <Route
              path={FORGOTPASSWORD}
              element={<MainLayout pageTitle="Forgot Password"><Forgotpassword /></MainLayout>}
            />
            <Route
              path={RESETPASSWORD}
              element={<MainLayout pageTitle="Rest Password"><Resetpassword /></MainLayout>}
            />
            <Route path="/" element={<PrivateRoute />}>
              <Route element={<LayoutWrapper />}>
                <Route path={DASHBOARD} element={<Dashboard />} />
                <Route path={CHANGEPASSWORD} element={<ChangePassword />} />
                <Route path={PROFILE} element={<DashboardProfile />} />
                <Route path={REVIEWS} element={<Reviews />} />
                <Route path={TEAMLEADERUSERS} element={<TeamLeaderUsersWrapper />} />
                <Route path={ADDTEAMLEADERUSERS} element={<TeamleaderAddUser />} />
                <Route path={CREATETEAMLEADERUSERS} element={<CreateUser />} />
                <Route path={SUBTL_LIST} element={<SubTLList />} />
                <Route path={ADD_SUBTL} element={<CreateSubTL />} />
                <Route
                  path={ASSIGNTARGET_SUBTL}
                  element={
                    <ProtectedRoute
                      element={<AssignSubTLTarget />}
                      requiredRole={2}
                    />
                  }
                />
                <Route path={REVENUE} element={<TeamLeaderRevenue />} />
                <Route path={WITHDRAWAL} element={<Index />} />
                <Route path={PURCHASE_REVIEWS} element={<PurchaseReviews />} />
                <Route path={CHECKOUT_PAGE} element={<CheckoutPage />} />
                <Route path={TRANSACTION_HISTORY} element={<TransactionHistory />} />
                <Route path={ORDER_DETAILS} element={<OrderDetails />} />
                <Route path={USER_DETAILS} element={<UserDetails />} />
                <Route path={EMPCOMPANIES} element={<EmployeeCompanyList />} />
                <Route path={CREATEEMPCOMPANY} element={<CreateCompany />} />
                <Route path={EMPCOMPANYTARGETHISTORY} element={<CompanyTargetsHistory />} />
                <Route path={MYTARGETS} element={<MyTargetWrapper />} />
                <Route path={EMPEARNINGS} element={<EarningsOverview />} />
              </Route>
            </Route>
            <Route path={UNAUTHORIZED} element={<UnauthorizedPage />} />
            <Route
              path="*"
              element={<Navigate to={NOTFOUND} replace />}
            />
          </Routes>
        </Provider>
      </BrowserRouter>
    </Fragment >
  )
}
export default App;