import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './forgotpasswordActions';
import { postRequestAPI } from '../../global/api';
import { defaultErrorMsg } from '../../utils/conts';

/**
 * @author      Nandani.V.Patel
 * @date        14 Oct 2024
 * @description forgot-password Reducer.
 * @param       takes default and previous state value
 * @response    update a state value and store forgot-password api response  
**/

function* forgotpasswordSaga(action) {
    try {
        const response = yield call(postRequestAPI, { url: 'forgot-password', data: action.payload });
        if (response.data.status === 'success') {
            yield put({ type: ActionTypes.FORGOT_PASSWORD_SUCCESS, data: response.data });
        } else {
            yield put({ type: ActionTypes.FORGOT_PASSWORD_FAILURE, error: response.data });
        }
    } catch (err) {
        yield put({ type: ActionTypes.FORGOT_PASSWORD_FAILURE, error: { message: defaultErrorMsg } });
    }
}

function* watchForgotPasswordRequest() {
    yield takeLatest(ActionTypes.FORGOT_PASSWORD_REQUEST, forgotpasswordSaga);
}

function* forgotPasswordSaga() {
    yield fork(watchForgotPasswordRequest);
}

export default forgotPasswordSaga;