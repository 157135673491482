import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './changepasswordActions';
import { postRequestAPI } from '../../global/api';
import { tokenkey } from '../../utils/auth';
import { defaultErrorMsg } from '../../utils/conts';

/**
 * @author      Nandani.V.Patel
 * @date        14 Oct 2024
 * @description forgot-password Reducer.
 * @param       takes default and previous state value
 * @response    update a state value and store forgot-password api response  
**/

function* changepasswordSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'change-password', data: action.payload });
    if (response.data.status === 'success') {
      localStorage.setItem(tokenkey, response?.data?.access_token);
      yield put({ type: ActionTypes.CHANGE_PASSWORD_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.CHANGE_PASSWORD_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.CHANGE_PASSWORD_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* watchChangePasswordRequest() {
  yield takeLatest(ActionTypes.CHANGE_PASSWORD_REQUEST, changepasswordSaga);
}

function* changePasswordSaga() {
  yield fork(watchChangePasswordRequest);
}

export default changePasswordSaga;