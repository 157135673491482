import { ActionTypes } from "./actions";

const defaultState = {
  targetList: {},
  loading: false,
  targetSiteList: {},
  siteLoad: false,
  mailSentLoad: false,
  successMsg: "",
  errorMsg: "",
};

const TeamLeaderTargetsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.GET_TL_TARGETS:
    case ActionTypes.ASSIGN_SUBTL_TARGETS:
      return {
        ...state,
        loading: true,
      }
    case ActionTypes.GET_TL_TARGETS_FAILURE:
    case ActionTypes.ASSIGN_SUBTL_TARGETS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
      }
    case ActionTypes.GET_TL_TARGETS_SUCCESS:
      return {
        ...state,
        targetList: action.payload,
        loading: false,
      }
    case ActionTypes.GET_COMPANY_REVIEW_SITES:
      return {
        ...state,
        siteLoad: true,
      };
    case ActionTypes.GET_COMPANY_REVIEW_SITES_FAILURE:
      return {
        ...state,
        siteLoad: false,
        errorMsg: action.error,
      }
    case ActionTypes.GET_COMPANY_REVIEW_SITES_SUCCESS:
      return {
        ...state,
        targetSiteList: action.payload,
        siteLoad: false,
      }
    case ActionTypes.ASSIGN_SUBTL_TARGETS_SUCCESS:
      return {
        ...state,
        successMsg: action.payload.message,
        loading: false,
      }
    case ActionTypes.SEND_MAIL_TO_USERS:
      return {
        ...state,
        mailSentLoad: true,
      }
    case ActionTypes.SEND_MAIL_TO_USERS_FAILURE:
      return {
        ...state,
        mailSentLoad: false,
        errorMsg: action.error,
      }
    case ActionTypes.SEND_MAIL_TO_USERS_SUCCESS:
      return {
        ...state,
        successMsg: action.payload.message,
        mailSentLoad: false,
      }
    default:
      return state;
  }
};

export default TeamLeaderTargetsReducer;