import { ActionTypes } from "./actions";

const defaultState = {
  checkoutPrices: "",
  transactionHistory: {},
  orderList: "",
  orderedSiteList: {},
  purchasedSiteReviews: {},
  data: undefined,
  companydata: undefined,
  latestOrder: "",
  loading: false,
  orderSitesLoader: false,
  iscompanyprofile: false,
  isCompanyDetails: false,
  successMsg: "",
  errorMsg: "",
};

const companyPanelReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.GET_LATEST_ORDER:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.GET_LATEST_ORDER_SUCCESS:
      return {
        ...state,
        latestOrder: action.payload,
        loading: false
      };
    case ActionTypes.GET_LATEST_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error
      };
    case ActionTypes.CALC_CHECKOUT_PRICE:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.CALC_CHECKOUT_PRICE_SUCCESS:
      return {
        ...state,
        checkoutPrices: action.payload,
        loading: false
      };
    case ActionTypes.CALC_CHECKOUT_PRICE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error
      };
    case ActionTypes.GET_ALL_TRANSACTION_HISTORY:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.GET_ALL_TRANSACTION_HISTORY_SUCCESS:
      return {
        ...state,
        transactionHistory: action.payload,
        loading: false
      };
    case ActionTypes.GET_ALL_TRANSACTION_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error
      };
    case ActionTypes.GET_ORDERS:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.GET_ORDERS_SUCCESS:
      return {
        ...state,
        orderList: action.payload,
        loading: false
      };
    case ActionTypes.GET_ORDERS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error
      };
    case ActionTypes.GET_SITES_BY_ORDER:
      return {
        ...state,
        orderSitesLoader: true
      };
    case ActionTypes.GET_SITES_BY_ORDER_SUCCESS:
      return {
        ...state,
        orderedSiteList: {
          ...state.orderedSiteList,
          [action.payload?.order_id]: action.payload?.review_campaigns || []
        },
        orderSitesLoader: false
      };
    case ActionTypes.GET_SITES_BY_ORDER_FAILURE:
      return {
        ...state,
        orderSitesLoader: false,
        errorMsg: action.error
      };
    case ActionTypes.GET_PURCHASED_SITE_USER_REVIEWS:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.GET_PURCHASED_SITE_USER_REVIEWS_SUCCESS:
      return {
        ...state,
        purchasedSiteReviews: action.payload,
        loading: false
      }
    case ActionTypes.GET_PURCHASED_SITE_USER_REVIEWS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error
      };
    case ActionTypes.UPDATE_COMPANY_PROFILE_REQUEST:
      return {
        ...state,
        data: undefined,
        iscompanyprofile: true,
      };
    case ActionTypes.UPDATE_COMPANY_PROFILE_SUCCESS:
      return {
        ...state,
        data: action.data,
        iscompanyprofile: false,
      };
    case ActionTypes.UPDATE_COMPANY_PROFILE_FAILURE:
      return {
        ...state,
        iscompanyprofile: false,
        data: undefined,
        errorMsg: action.error,
      };
    case ActionTypes.GET_COMPANY_DETAILS_REQUEST:
      return {
        ...state,
        isCompanyDetails: true,
      };
    case ActionTypes.GET_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        companydata: action.data,
        isCompanyDetails: false,
      };
    case ActionTypes.GET_COMPANY_DETAILS_FAILURE:
      return {
        ...state,
        isCompanyDetails: false,
        errorMsg: action.error,
      };
    case ActionTypes.PAYMENT_ORDER:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.PAYMENT_ORDER_SUCCESS:
      return {
        ...state,
        successMsg: action.payload.message,
        loading: false
      };
    case ActionTypes.PAYMENT_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error
      };
    default:
      return state;
  }
};

export default companyPanelReducer;