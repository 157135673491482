import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import ImageModal from '../../component/ImageModal';

import styles from "./styles.module.scss";
import { getWithdrawalHistoryRequest } from './redux/actions';

const staticWithdrawalData = [
  {
    bank_name: 'Bank of America',
    account_holder_name: 'Nandani Patel',
    account_number: '123456789012',
    IFSC_code: 'BOFAUS3N',
    amount: '5000',
  },
  {
    bank_name: 'Chase Bank',
    account_holder_name: 'Shruti Bhutani',
    account_number: '987654321098',
    IFSC_code: 'CHASUS33',
    amount: '3000',
  },
  {
    bank_name: 'Wells Fargo',
    account_holder_name: 'Deep Javiya',
    account_number: '112233445566',
    IFSC_code: 'WFBIUS6S',
    amount: '7000',
  },
];

const WithdrawHistory = () => {
  const dispatch = useDispatch();
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const payload = {
      per_page: rowsPerPage,
      page: currentPage
    };

    // dispatch(getWithdrawalHistoryRequest(payload));
  }, [dispatch, rowsPerPage, currentPage]);

  const propState = useSelector(({ withdrawal }) => ({
    withdrawalHistory: withdrawal.withdrawalHistory,
    loading: withdrawal.isHistoryData
  }));

  const handleReasonClick = (imageUrl) => {
    setImageSrc(imageUrl);
    setOpenImageModal(true);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setRowsPerPage(newPerPage);
    setCurrentPage(1); // Reset to first page when rows per page changes
  };

  const columns = [
    {
      name: 'Bank Name',
      selector: row => row?.bank_name || '-',
      sortable: true,
      minWidth: '160px',
    },
    {
      name: 'Account Holder Name',
      selector: row => row?.account_holder_name || '-',
      sortable: true,
      minWidth: '170px',
    },
    {
      name: 'Account Number',
      selector: row => row?.account_number || '-',
      minWidth: '170px',
    },
    {
      name: 'IFSC Code',
      selector: row => row?.IFSC_code || '-',
      minWidth: '130px',
    },
    {
      name: 'Amount',
      sortable: true,
      selector: row => `Rs. ${row?.amount || '-'}`,
    },
    {
      name: 'Status',
      minWidth: '140px',
      selector: row => row?.review_site_tbl_status_text || '-',
      cell: row => {
        const renderStatusBadge = (status) => {
          switch (status) {
            case 'Active':
              return <span
                className={`${'status - btn'} ${styles.btnactive}`}
                data-review-status="approved"
              >
                Active
              </span>
            case 'Inactive':
              return (
                <span
                  className={`${'status - btn'} ${styles.btnInactive}`}
                  data-review-status="rejected"
                >
                  In Active
                </span>
              );
            default:
              return <span
                className={`${'status - btn'} ${styles.btnunknow}`}
                data-review-status="rejected"
              >
                Unknown
              </span>
          }
        };
        return renderStatusBadge(row?.review_site_tbl_status_text);
      },
    },
    {
      name: 'Reason',
      center: 'yes',
      cell: (row) => (
        <div className="action-btn">
          <button
            className={`${styles.viewImg} option-btn d-inline-block`}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="View"
            type="button"
            onClick={() => handleReasonClick(row?.imageUrl || '/assets/images/placeholderImage/50x50.svg')}
          >
            <i className="fa-solid fa-eye" />
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
      <DataTable
        columns={columns}
        data={propState.withdrawalHistory?.data || []}
        pagination
        paginationServer
        paginationPerPage={rowsPerPage}
        // paginationTotalRows={propState.empTargets?.total || 0}
        paginationRowsPerPageOptions={[5, 15, 25, 50]}
        paginationComponentOptions={{
          rowsPerPageText: "Orders per page:",
        }}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerPageChange}
        // customStyles={customStyles}
        progressPending={propState.loading}
        fixedHeader
        responsive
      />
      {openImageModal && <ImageModal onClose={() => setOpenImageModal(false)} imageSrc={imageSrc} />}
    </>
  )
}

export default WithdrawHistory;