import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './companyUserReviewActions';
import { postRequestAPI } from '../../global/api';
import { defaultErrorMsg } from '../../utils/conts';

/**
 * @author      Nandani.V.Patel
 * @date        25 Oct 2024
 * @description company user review Saga and handling API response.
 * @param       company user review Saga Request paylod
 * @response    company user review is success or fail.  
**/

function* companyuserreviewsaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'company/review-site-wise-userreview', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.REVIEW_SITE_WISE_USER_REVIEW_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.REVIEW_SITE_WISE_USER_REVIEW_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.REVIEW_SITE_WISE_USER_REVIEW_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* companyreviewsitelistingsaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'company/reviewsite', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.REVIEW_SITE_LISTING_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.REVIEW_SITE_LISTING_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.REVIEW_SITE_LISTING_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* companyuserlistingsaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'company/reviewsite-users', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.REVIEW_SITE_USER_LISTING_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.REVIEW_SITE_USER_LISTING_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.REVIEW_SITE_USER_LISTING_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* watchcompanyUserReviewRequest() {
  yield takeLatest(ActionTypes.REVIEW_SITE_WISE_USER_REVIEW_REQUEST, companyuserreviewsaga);
  yield takeLatest(ActionTypes.REVIEW_SITE_LISTING_REQUEST, companyreviewsitelistingsaga);
  yield takeLatest(ActionTypes.REVIEW_SITE_USER_LISTING_REQUEST, companyuserlistingsaga);
}

function* companyUserReviewSaga() {
  yield fork(watchcompanyUserReviewRequest);
}

export default companyUserReviewSaga;