import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './teamleaderuserActions';
import { postRequestAPI } from '../../global/api';
import { defaultErrorMsg } from '../../utils/conts';

/**
 * @author      Nandani.V.Patel
 * @date        24 Oct 2024
 * @description Team-leader User Saga and handling API response.
 * @param       
 * @response    list of user in system.  
**/

function* TeamleaderUserSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'team-leader/users', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.GET_TEAM_LEADER_USER_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.GET_TEAM_LEADER_USER_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_TEAM_LEADER_USER_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* watchteamleaderUserRequest() {
  yield takeLatest(ActionTypes.GET_TEAM_LEADER_USER_REQUEST, TeamleaderUserSaga);
}

function* teamleaderuserSaga() {
  yield fork(watchteamleaderUserRequest);
}

export default teamleaderuserSaga;