import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { PURCHASE_REVIEWS } from "../../../global/routes";
import { calcCheckoutPrice, paymentOrder } from "../redux/actions";
import Breadcrumb from '../../../component/breadcrumbs';

const CheckoutPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const purchasedDetails = location.state || {};

  const checkoutPrices = useSelector(({ companyPanel }) => companyPanel?.checkoutPrices || {});

  const breadcrumbs = [
    { name: 'Purchase Reviews', link: PURCHASE_REVIEWS },
    { name: 'Checkout', link: "" },
  ];

  useEffect(() => {
    purchasedDetails.length && dispatch(calcCheckoutPrice(purchasedDetails));
  }, [purchasedDetails]);

  const handlePayNow = () => {
    // const cart = {
    //   order: purchasedDetails,
    // }

    dispatch(paymentOrder(purchasedDetails));
  };

  return (
    <div>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div className={`p-3 mb-4 mt-2 header-box`}>
        <h4 className="header-text">Checkout</h4>
      </div>
      <div className="card shadow checkout-card">
        <div className="card-body">
          {/* <h5 className="card-title">Order Summary</h5> */}
          <div className="d-flex justify-content-between mb-2">
            <span>Subtotal Price</span>
            <span>₹ {(checkoutPrices.total_original_price || 0)?.toFixed(2)}</span>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <span>Discount</span>
            <span>{(checkoutPrices.discount_percentage || 0)?.toFixed(2)} %</span>
          </div>
          <hr />
          <div className="d-flex justify-content-between font-weight-bold">
            <span>Total Price</span>
            <span>₹ {(checkoutPrices.discounted_price || 0)?.toFixed(2)}</span>
          </div>
        </div>
      </div>
      <button className="blue-btn rounded mt-4 fw-medium" onClick={handlePayNow}>Pay Now</button>
    </div>
  )
}

export default CheckoutPage;