/**
 * @author      Nandani.V.Patel
 * @date         09 Oct 2024
 * @description ActionTypes and ActionCreator Function for User Login Request.
 * @param
 * @response      
**/

export const ActionTypes = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  GOOGLE_LOGIN_REQUEST: 'GOOGLE_LOGIN_REQUEST',
  GOOGLE_LOGIN_SUCCESS: 'GOOGLE_LOGIN_SUCCESS',
  GOOGLE_LOGIN_FAILURE: 'GOOGLE_LOGIN_FAILURE',

  FACEBOOK_LOGIN_REQUEST: 'FACEBOOK_LOGIN_REQUEST',
  FACEBOOK_LOGIN_SUCCESS: 'FACEBOOK_LOGIN_SUCCESS',
  FACEBOOK_LOGIN_FAILURE: 'FACEBOOK_LOGIN_FAILURE',

  LINKEDIN_LOGIN_REQUEST: 'LINKEDIN_LOGIN_REQUEST',
  LINKEDIN_LOGIN_SUCCESS: 'LINKEDIN_LOGIN_SUCCESS',
  LINKEDIN_LOGIN_FAILURE: 'LINKEDIN_LOGIN_FAILURE',

};

export const loginRequest = (loginData) => {
  return {
    type: ActionTypes.LOGIN_REQUEST,
    payload: loginData,
  };
};

export const googleLoginRequest = (googleLoginData) => {
  return {
    type: ActionTypes.GOOGLE_LOGIN_REQUEST,
    payload: googleLoginData,
  };
};

export const facebookLoginRequest = (facebookLoginData) => {
  return {
    type: ActionTypes.FACEBOOK_LOGIN_REQUEST,
    payload: facebookLoginData,
  };
};

export const linkedinLoginRequest = (linkedinLoginData) => {
  return {
    type: ActionTypes.LINKEDIN_LOGIN_REQUEST,
    payload: linkedinLoginData,
  };
};