import { ActionTypes } from './purchaseReviewActions';

/**
 * @author      Nandani.V.Patel
 * @date        16 Oct 2024
 * @description purchase Review Reducer.
 * @param       takes default and previous state value
 * @response    update a state value and store purchase Review api response  
**/

const defaultState = {
  ispurchaseeview: false,
  purchasereview: undefined
};

const purchaseReviewReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.GET_PURCHASE_REVIEW_REQUEST:
      return {
        ...state,
        ispurchaseeview: true,
      };
    case ActionTypes.GET_PURCHASE_REVIEW_SUCCESS:
      return {
        ...state,
        purchasereview: action.data,
        ispurchaseeview: false,
      };
    case ActionTypes.GET_PURCHASE_REVIEW_FAILURE:
      return {
        ...state,
        ispurchaseeview: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default purchaseReviewReducer;