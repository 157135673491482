import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './userReviewActions';
import { getRequestAPI } from '../../global/api';
import { defaultErrorMsg } from '../../utils/conts';

/**
 * @author      Nandani.V.Patel
 * @date        16 Oct 2024
 * @description User Review  Saga and handling API response.
 * @param       
 * @response    User Review is success or fail.  
**/

function* userReviewSaga() {
  try {
    const response = yield call(getRequestAPI, { url: 'get-latestUserReview' });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.GET_LATEST_USER_REVIEW_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.GET_LATEST_USER_REVIEW_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_LATEST_USER_REVIEW_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* watchUserReviewRequest() {
  yield takeLatest(ActionTypes.GET_LATEST_USER_REVIEW_REQUEST, userReviewSaga);
}

function* userReviewsSaga() {
  yield fork(watchUserReviewRequest);
}

export default userReviewsSaga;