/**
 * @author      Nandani.V.Patel
 * @date        29 Oct 2024
 * @description ActionTypes and ActionCreator Function for Add Team-leader User Request.
 * @param
 * @response      
**/

export const ActionTypes = {
  GET_UNASSIGNED_USER_REQUEST: 'GET_UNASSIGNED_USER_REQUEST',
  GET_UNASSIGNED_USER_SUCCESS: 'GET_UNASSIGNED_USER_SUCCESS',
  GET_UNASSIGNED_USER_FAILURE: 'GET_UNASSIGNED_USER_FAILURE',

  ADD_TEAM_LEADER_USER_REQUEST: 'ADD_TEAM_LEADER_USER_REQUEST',
  ADD_TEAM_LEADER_USER_SUCCESS: 'ADD_TEAM_LEADER_USER_SUCCESS',
  ADD_TEAM_LEADER_USER_FAILURE: 'ADD_TEAM_LEADER_USER_FAILURE',
};

export const addTeamleaderuserRequest = (user_id) => {
  return {
    type: ActionTypes.ADD_TEAM_LEADER_USER_REQUEST,
    payload: user_id,
  };
};

export const getunassigneduserRequest = (paginationData) => {
  return {
    type: ActionTypes.GET_UNASSIGNED_USER_REQUEST,
    payload: paginationData,
  };
};