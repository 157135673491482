import React, { useState } from 'react';
import Navbar from '../component/nav-bar';
import Footer from '../component/footer';
import PageBanner from '../component/page-banner';
import DashboardNavbar from '../component/dashboard-nav-bar';
import styles from './styles.module.scss';

const MainLayout = ({ children, pageTitle }) => {
  const [themeDark, setThemeDark] = useState(false);
  const toggleTheme = (e) => {
    setThemeDark(e.target.checked);
  };

  return (
    <div className={`main-layout ${themeDark ? "theme-dark" : "theme-light"}`}>
      {(pageTitle === "Home" ||
        pageTitle === "404 Error" ||
        pageTitle === "403 Error" ||
        pageTitle === "CompanyDetails" ||
        pageTitle === "Create Account") && (
        <div>
          <Navbar />
        </div>
      )}
      {pageTitle === "sidebar" && <DashboardNavbar />}
      {(pageTitle === "Register" ||
        pageTitle === "Login" ||
        pageTitle === "Company Registration" ||
        pageTitle === "Forgot Password" ||
        pageTitle === "Rest Password" ||
        pageTitle === "Terms & Conditions") && (
        <div className={styles.background}>
          <Navbar />
          {pageTitle && <PageBanner title={pageTitle} />}
        </div>
      )}
      {(pageTitle === "How It Works" ||
        pageTitle === "Category list" ||
        pageTitle === "Company list") && (
        <div>
          <Navbar />
          {pageTitle && <PageBanner title={pageTitle} />}
        </div>
      )}
      <main>{children}</main>
      {pageTitle !== "sidebar" && <Footer />}
      <div className="switch-box">
        <label id="switch" className="switch">
          <input type="checkbox" id="slider" onChange={toggleTheme} /><span className="slider round"></span>
        </label>
      </div>
    </div>
  );
};

export default MainLayout;
