import React from 'react';

/**
 * @author      Nandani.V.Patel
 * @date        18th Oct 2024
 * @description UI Implementation of Dashboard Body which is handle all child component
 * @param
 * @response 
**/

const DashboardBody = (props) => {
  return (
    <>
      <div className="main-dashboard-content d-flex flex-column">
        <div className="responsive-content" style={{ marginTop: '70px' }}>
          {props?.children}
        </div>
      </div>
    </>
  );
};

export default DashboardBody;
