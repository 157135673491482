import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as UserDashboardCountActions from '../dashboard/dashboardcountActions';
import StatsBlock from '../dashboard/statsBlock';

const UserDashboard = () => {
  const usercounts = useSelector((state) => state?.userdashboardcount?.dashboardusercount);
  const [firstInit, setFirstInit] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (firstInit) {
      dispatch(UserDashboardCountActions.getUserdashboardcount());
      setFirstInit(false);
    }
  }, [firstInit]);

  useEffect(() => {
    setFirstInit(true);
  }, []);

  return (
    <>
      <div className="user-dashboard dashboard-fun-fact-area">
        <div className="row">
          <StatsBlock
            icon="fa-regular fa-hourglass-half"
            label="Pending"
            count={usercounts?.pending || 0}
          />
          <StatsBlock
            icon="fa-regular fa-thumbs-up"
            label="Approved"
            count={usercounts?.approved || 0}
          />
          <StatsBlock
            icon="fa fa-xmark"
            label="Rejected"
            count={usercounts?.rejected || 0}
          />
          <StatsBlock
            icon="flaticon-coin"
            label="Wallet Balance"
            count={usercounts?.totalAmount || 0}
            additionalClass="wallet-stats-box"
          />
        </div>
      </div>
    </>
  )
}
export default UserDashboard;
