/**
 * @author      Nandani.V.Patel
 * @date        22 Oct 2024
 * @description ActionTypes and ActionCreator Function for review-list Request.
 * @param
 * @response      
**/

export const ActionTypes = {
  REVIEW_LISTING_REQUEST: 'REVIEW_LISTING_REQUEST',
  REVIEW_LISTING_SUCCESS: 'REVIEW_LISTING_SUCCESS',
  REVIEW_LISTING_FAILURE: 'REVIEW_LISTING_FAILURE',
};

export const reviewlistingRequest = (reviewData) => {
  return {
    type: ActionTypes.REVIEW_LISTING_REQUEST,
    payload: reviewData,
  };
};

