/**
 * @author      Nandani.V.Patel
 * @date        25 Oct 2024
 * @description ActionTypes and ActionCreator Function for review-site-wise-user-review Request.
 * @param
 * @response      
**/

export const ActionTypes = {
  REVIEW_SITE_WISE_USER_REVIEW_REQUEST: 'REVIEW_SITE_WISE_USER_REVIEW_REQUEST',
  REVIEW_SITE_WISE_USER_REVIEW_SUCCESS: 'REVIEW_SITE_WISE_USER_REVIEW_SUCCESS',
  REVIEW_SITE_WISE_USER_REVIEW_FAILURE: 'REVIEW_SITE_WISE_USER_REVIEW_FAILURE',

  REVIEW_SITE_LISTING_REQUEST: 'REVIEW_SITE_LISTING_REQUEST',
  REVIEW_SITE_LISTING_SUCCESS: 'REVIEW_SITE_LISTING_SUCCESS',
  REVIEW_SITE_LISTING_FAILURE: 'REVIEW_SITE_LISTING_FAILURE',

  REVIEW_SITE_USER_LISTING_REQUEST: 'REVIEW_SITE_USER_LISTING_REQUEST',
  REVIEW_SITE_USER_LISTING_SUCCESS: 'REVIEW_SITE_USER_LISTING_SUCCESS',
  REVIEW_SITE_USER_LISTING_FAILURE: 'REVIEW_SITE_USER_LISTING_FAILURE',
};

export const reviewsitewisereviewRequest = (reviewsiteData) => {
  return {
    type: ActionTypes.REVIEW_SITE_WISE_USER_REVIEW_REQUEST,
    payload: reviewsiteData,
  };
};

export const reviewsitelistingRequest = (reviewsiteid) => {
  return {
    type: ActionTypes.REVIEW_SITE_LISTING_REQUEST,
    payload: reviewsiteid,
  };
};

export const reviewsiteuserlistingRequest = (reviewsiteuserid) => {
  return {
    type: ActionTypes.REVIEW_SITE_USER_LISTING_REQUEST,
    payload: reviewsiteuserid,
  };
};