import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CategoryListActions from './categorylistActions';
import Preloader from '../../component/Preloader';
import styles from './styles.module.scss';

/**
 * @author      Nandani.V.Patel
 * @date        10th Oct 2024
 * @description UI Implementation of Category-listing
 * @param
 * @response 
**/

const CategoryList = (props) => {
  const { categorylist, actions } = props;
  const [firstInit, setFirstInit] = useState(false);
  const [perPage, setPerPage] = useState(12);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // Fetch category list data on page/perPage change
  useEffect(() => {
    if (firstInit) {
      actions.getCategorylistRequest({ page, perPage });
      setFirstInit(false);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [firstInit, page, perPage]);

  useEffect(() => {
    setFirstInit(true);
  }, [page, perPage]);

  // Handle Next and Previous Pagination
  const handleNextPage = () => {
    if (categorylist?.data?.current_page < categorylist?.data?.last_page) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  // Generate the page numbers to display
  const renderPageNumbers = () => {
    if (!categorylist?.data?.last_page) return null;

    const totalPages = categorylist.data.last_page;
    const maxVisiblePages = 3; // Number of visible page numbers around the current page
    let pages = [];

    // Always show the first page
    pages.push(
      <li key={1}>
        <a
          className={`${styles.hoverStyle} ${page === 1 ? 'active' : ''}`}
          onClick={(e) => {
            e.preventDefault();
            setPage(1);
          }}
        >
          1
        </a>
      </li>
    );

    // Edge case: if total pages are 1
    if (totalPages <= 1) {
      return pages; // Only one page, return early
    }

    // If total pages are small, display all pages
    if (totalPages <= maxVisiblePages + 2) {
      for (let i = 2; i <= totalPages - 1; i++) {
        pages.push(
          <li key={i}>
            <a
              className={`${styles.hoverStyle} ${i === page ? 'active' : ''}`}
              onClick={(e) => {
                e.preventDefault();
                setPage(i);
              }}
            >
              {i}
            </a>
          </li>
        );
      }
    } else {
      // Show ellipsis if there are more pages
      if (page > 2) {
        pages.push(<li key="left-ellipsis">...</li>);
      }

      // Display pages around the current one
      const startPage = Math.max(2, page - 1);
      const endPage = Math.min(totalPages - 1, page + 1);

      for (let i = startPage; i <= endPage; i++) {
        pages.push(
          <li key={i}>
            <a
              className={`${styles.hoverStyle} ${i === page ? 'active' : ''}`}
              onClick={(e) => {
                e.preventDefault();
                setPage(i);
              }}
            >
              {i}
            </a>
          </li>
        );
      }
      // Show ellipsis if needed
      if (page < totalPages - 2) {
        pages.push(<li key="right-ellipsis">...</li>);
      }
    }

    // Always show the last page
    pages.push(
      <li key={totalPages}>
        <a
          className={`${styles.hoverStyle} ${page === totalPages ? 'active' : ''}`}
          onClick={(e) => {
            e.preventDefault();
            setPage(totalPages);
          }}
        >
          {totalPages}
        </a>
      </li>
    );

    return pages;
  };

  const cardStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px',
    height: '100%',
  };

  return (

    <>
      {loading ? (
        <Preloader />
      ) : (
        <div className="company-area ptb-100">
          <div className="container">
            <div className="row" style={{ display: 'flex', flexWrap: 'wrap' }}>
              {/* Check if categorylist and categorylist.data are available before rendering */}
              {categorylist?.data?.data && categorylist?.data?.data?.length > 0 ? (
                categorylist?.data?.data.map((category) => (
                  <div
                    key={category.id}
                    className="col-lg-4 col-sm-6 aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    style={{ marginBottom: '30px' }}
                  >
                    <div className="single-company-card" style={cardStyle}>
                      <div className="company-content">
                        <div className={`icon ${styles.icons}`}>
                          <img src={category?.category_image_url} alt={category?.category_name} className={styles.iconimg} />
                        </div>
                        <h3>{category.category_name}</h3>
                        <p>{category?.category_description}</p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No Categories Found</p>
              )}
            </div>

            {/* Custom Pagination UI */}
            <div className="paginations">
              <ul>
                {/* Previous Page */}
                <li>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      handlePreviousPage();
                    }}
                    className={page === 1 ? 'disabled' : ''}
                    // style={{ cursor: 'pointer' }}
                  >
                    <i className="fa-solid fa-angle-left"></i>
                  </a>
                </li>

                {/* Page Numbers with Ellipsis */}
                {renderPageNumbers()}

                {/* Next Page */}
                <li>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      handleNextPage();
                    }}
                    // style={{ cursor: 'pointer' }}
                    className={categorylist?.data?.current_page === categorylist?.data?.last_page ? 'disabled' : ''}
                  >
                    <i className="fa-solid fa-angle-right"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  categorylist: state.categorylist,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(CategoryListActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryList);

