import React from 'react';
import UserDashboard from '../userdashboard';
import TeamLeaderDashboard from '../teamleaderdashboard';
import CompanyDashboard from '../companyPanel/dashboard';
import SubTLDashboard from '../subTLPanel/dashboard';
import EmployeeDashboard from '../employeePanel/dashboard';
import { encryptStorage, roleId, isAuth, tokenkey } from '../../utils/auth';
import { SIGNIN } from '../../global/routes';

/**
 * @author      Nandani.V.Patel
 * @date        17th Oct 2024
 * @description UI Implementation of Dashboard
 * @param
 * @response 
**/

const Dashboard = () => {
  const userRoleId = encryptStorage.getItem(roleId);
  const auth = isAuth();

  if (!auth || typeof userRoleId === 'undefined' || userRoleId === null || userRoleId === "") {
    localStorage.removeItem(tokenkey);
    window.location.href = SIGNIN;
    return false;
  }

  return (
    <>
      {userRoleId === 1 && <UserDashboard />}
      {userRoleId === 2 && <TeamLeaderDashboard />}
      {userRoleId === 3 && <CompanyDashboard />}
      {userRoleId === 4 && <EmployeeDashboard />}
      {userRoleId === 5 && <SubTLDashboard />}
    </>
  )
}
export default Dashboard;
