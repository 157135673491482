import { ActionTypes } from './teamleaderCompanylistActions';

/**
 * @author      Nandani.V.Patel
 * @date        29 Oct 2024
 * @description Team Leader Company Reducer.
 * @param       takes default and previous state value
 * @response    update a state value and store Team leader Company api response  
**/

const defaultState = {
  isteamleadercompany: false,
  data: undefined,
};

const TeamleadercompanyReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.GET_TEAM_LEADER_COMPANY_REQUEST:
      return {
        ...state,
        isteamleadercompany: true,
      };
    case ActionTypes.GET_TEAM_LEADER_COMPANY_SUCCESS:
      return {
        ...state,
        data: action.data,
        isteamleadercompany: false,
      };
    case ActionTypes.GET_TEAM_LEADER_COMPANY_FAILURE:
      return {
        ...state,
        isteamleadercompany: false,
        error: action.error,
      };
    default:
      return state;
  }
}
export default TeamleadercompanyReducer;