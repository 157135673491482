/**
 * @author      Nandani.V.Patel
 * @date        30 Oct 2024
 * @description ActionTypes and ActionCreator Function for User Revenue.
 * @param
 * @response      
**/

export const ActionTypes = {
  GET_TEAMLEADER_REVENUE_REQUEST: 'GET_TEAMLEADER_REVENUE_REQUEST',
  GET_TEAMLEADER_REVENUE_SUCCESS: 'GET_TEAMLEADER_REVENUE_SUCCESS',
  GET_TEAMLEADER_REVENUE_FAILURE: 'GET_TEAMLEADER_REVENUE_FAILURE',
};

export const getTeamleaderRevenueRequest = (paginationData) => {
  return {
    type: ActionTypes.GET_TEAMLEADER_REVENUE_REQUEST,
    payload: paginationData,
  };
};