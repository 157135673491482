export const ActionTypes = {
  CREATE_SUB_TL: 'CREATE_SUB_TL',
  CREATE_SUB_TL_SUCCESS: 'CREATE_SUB_TL_SUCCESS',
  CREATE_SUB_TL_FAILURE: 'CREATE_SUB_TL_FAILURE',

  GET_SUB_TL: 'GET_SUB_TL',
  GET_SUB_TL_SUCCESS: 'GET_SUB_TL_SUCCESS',
  GET_SUB_TL_FAILURE: 'GET_SUB_TL_FAILURE',

  CLEAR_STATE: 'CLEAR_STATE',
};

export const createSubTLReq = (payload) => {
  return {
    type: ActionTypes.CREATE_SUB_TL,
    payload
  };
};

export const getSubTL = (payload) => {
  return {
    type: ActionTypes.GET_SUB_TL,
    payload
  };
};

export const clearSubTlListStates = () => {
  return {
    type: ActionTypes.CLEAR_STATE,
  };
};