import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import * as RegistrationActions from './registerActions';
import * as LoginActions from '../login/loginActions';
import { NotificationContainer } from '../../component/notificationContainer';
import { notification } from '../../component/notificationContainer/notification';
import { rememberMeKey, passKey, identifier, tokenkey, encryptStorage } from '../../utils/auth';
import { GoogleLoginButton, LinkedInLoginButton, FacebookLoginButton } from 'react-social-login-buttons';
import { LoginSocialGoogle, LoginSocialLinkedin, LoginSocialFacebook } from 'reactjs-social-login';
import { SIGNIN, CREATEACCOUNT, TERMSCONDITIONS } from '../../global/routes';
import { useAccountType } from '../../layouts/AccountTypeContext';
import { validationRegex } from '../../utils/conts';
import { pwdTooltipContent } from '../../utils/helpers';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { BACKEND_URL } from '../../global/url';
import RegistrationForm from '../../component/registrationForm';

/**
 * @author      Nandani.V.Patel
 * @date        8th Oct 2024
 * @description UI Implementation of Registration
 * @param
 * @response 
**/

const Registration = (props) => {
  const { register, handleSubmit, resetField, getValues, formState: { errors }, } = useForm({ mode: 'onChange' });
  const { signup, actions } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const [checked, setchecked] = useState(false);
  const signin = useSelector((state) => state?.signin);
  const [isredirect, setIsRedirect] = useState(false);
  const navigate = useNavigate();

  //another approch for get account type
  const queryParams = new URLSearchParams(location.search);
  const accountType = queryParams.get('accountType');

  // const { accountType } = useAccountType();

  const renderHeading = () => {
    switch (accountType) {
      case 'teamLeader':
        return <h3 style={{ marginTop: '15px' }}>Team Leader Registration</h3>;
      case 'user':
        return <h3 style={{ marginTop: '15px' }}>User Registration</h3>;
      default:
        return <h3 style={{ marginTop: '15px' }}>Registration</h3>;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleChange = () => {
    setchecked((Checked) => !Checked);
  }

  useEffect(() => {
    if (signup?.data !== undefined && signup?.data?.status === 'success' && signup?.data?.status !== undefined) {
      localStorage.removeItem(tokenkey);
      encryptStorage.removeItem(rememberMeKey);
      encryptStorage.removeItem(identifier);
      encryptStorage.removeItem(passKey);
      notification(signup?.data?.message, 'success');
      setTimeout(() => {
        setIsRedirect(true);
      }, 2000);
      dispatch({ type: RegistrationActions.ActionTypes.REGISTRATION_SUCCESS, data: undefined });
      resetField('user_first_name');
      resetField('user_last_name');
      resetField('user_email');
      resetField('password');
      resetField('user_phone_number');
      resetField('user_aadhar_card');
      resetField('user_pan_card');
      resetField('password_confirmation');
      setchecked(false);
    }
    if (signin?.facebookLogindata !== undefined && signin?.facebookLogindata?.status === 'success' && !signin.isfacebookLogin) {
      notification(signin?.facebookLogindata?.message, 'success');
      setTimeout(() => {
        setIsRedirect(true);
      }, 2000);
      dispatch({ type: LoginActions.ActionTypes.FACEBOOK_LOGIN_SUCCESS, facebookLogindata: undefined });
    }
    if (signin?.googleLogindata !== undefined && signin?.googleLogindata?.status === 'success' && !signin.isGoogleLogin) {
      notification(signin?.googleLogindata?.message, 'success');
      setTimeout(() => {
        setIsRedirect(true);
      }, 2000);
      dispatch({ type: LoginActions.ActionTypes.GOOGLE_LOGIN_SUCCESS, googleLogindata: undefined });
    }
    if (signin?.linkedinLogindata !== undefined && signin?.linkedinLogindata?.status === 'success' && !signin.isLinkedinLogin) {
      notification(signin?.linkedinLogindata?.message, 'success');
      setTimeout(() => {
        setIsRedirect(true);
      }, 2000);
      dispatch({ type: LoginActions.ActionTypes.LINKEDIN_LOGIN_SUCCESS, linkedinLogindata: undefined });
    }
    if ((signin?.data === undefined || signin?.facebookLogindata === undefined || signin?.googleLogindata === undefined || signin?.linkedinLogindata === undefined) && signin?.error?.message) {
      setIsRedirect(false);
      notification(signin?.error?.message, 'error');
    }
    if (signup?.error?.message && signup?.isRegistration === false) {
      setIsRedirect(false);
      notification(signup?.error?.message, 'error');
      dispatch({ type: RegistrationActions.ActionTypes.REGISTRATION_FAILURE, error: undefined });
    }
    dispatch({ type: LoginActions.ActionTypes.FACEBOOK_LOGIN_FAILURE, error: undefined });
    dispatch({ type: LoginActions.ActionTypes.GOOGLE_LOGIN_FAILURE, error: undefined });
    dispatch({ type: LoginActions.ActionTypes.LINKEDIN_LOGIN_FAILURE, error: undefined });
  }, [signin?.data, signin.isLogin, signin?.facebookLogindata, signin?.googleLogindata, signin?.linkedinLogindata, signin?.error, signup.data, signup.error, signup.isRegistration]);

  useEffect(() => {
    if (isredirect) {
      navigate(SIGNIN);
    }
  }, [isredirect])

  const onSubmit = (data) => {
    const formData = {
      ...data,
      user_role: accountType,
    };

    const password = getValues('password');
    const password_confirmation = getValues('password_confirmation');

    if (password !== password_confirmation) {
      notification('Password and Confirm-Password must be same.', 'error');
    } else if (!checked) {
      notification('Please accept terms and conditions.', 'warning');
    } else {
      actions.registrationRequest(formData);
    }
  }

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      handleSubmit(onSubmit)();
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', handleKeyPress);
    return () => document.removeEventListener('keyup', handleKeyPress);
  }, [])

  return (
    <>
      <div className="register-area ptb-50">
        <div className="container">
          <div className="register">
            <Link to={CREATEACCOUNT} className={styles.link}> {'<'} Back </Link>
            {renderHeading()}

            <RegistrationForm
              register={register}
              errors={errors}
              getValues={getValues}
              resetField={resetField}
            />

            <div>
              <div className={`${styles['form-check']} ${styles.condition}`}>
                <input
                  className={`${styles['form-check-input']} ${styles.formcheckbox}`}
                  id="flexCheckDefault"
                  type="checkbox"
                  checked={checked}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  I agree to the <Link to={TERMSCONDITIONS}>Terms & Conditions</Link> for registration.
                </label>
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="default-btn btn"
                onClick={handleSubmit(onSubmit)}
                disabled={signup.isRegistration && signup.data !== 'undefiend'}
              >
                {(!signup.isRegistration && signup.data !== 'undefiend')
                  ?
                  <>Register</>
                  :
                  <><div className="spinner-border " style={{ width: '1.2rem', height: '1.2rem' }} role="status" /></>
                }
              </button>
            </div>
            <Link to={SIGNIN} className={styles.link}>Already have an Account?</Link>
            <div className="single-footer-widget quick-link" />
            <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0', width: '100%' }}>
              <hr style={{ flex: 1, border: '1px solid #ccc' }} />
              <span style={{ margin: '0 10px', color: '#999', fontWeight: '500' }}>or Register with</span>
              <hr style={{ flex: 1, border: '1px solid #ccc' }} />
            </div>
            <div className="social-login-buttons" style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div style={{ width: '100%' }}>
                <LoginSocialGoogle
                  client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  scope='openid profile email'
                  discoveryDocs='claims_supported'
                  access_type='offline'
                  typeResponse='accessToken'
                  onResolve={(response) => {
                    console.log('google login', response);
                    if (response?.data?.code !== undefined && response?.data?.code !== null && response?.data?.code !== '') {
                      const data = {
                        code: response?.data?.code,
                        user_role: accountType
                      };
                      dispatch(LoginActions.googleLoginRequest(data));
                    }
                  }}
                  onReject={(err) => {
                    console.log('google login err:', err);
                    notification('Somthing want to wrong', 'error');
                  }}
                >
                  <GoogleLoginButton
                    style={{
                      boxShadow: 'rgba(0, 0, 0, 0.2) -1px -1px 1px, rgba(0, 0, 0, 0.2) 1px 1px 1px',
                    }}
                    className={`${styles.socialbtn}`}
                  >
                    Google
                  </GoogleLoginButton>
                </LoginSocialGoogle>
              </div>
              <div style={{ width: '100%' }}>
                <LoginSocialLinkedin
                  client_id={process.env.REACT_APP_LINLEDIN_CLIENT_ID}
                  client_secret={process.env.REACT_APP_LINKEDIN_CLIENT_SECRET_KEY}
                  onResolve={(response) => {
                    console.log('onResolve triggered', response);
                    if (response?.data?.access_token) {
                      const data = {
                        accessToken: response?.data?.access_token,
                        user_role: accountType
                      };
                      dispatch(LoginActions.linkedinLoginRequest(data));
                    } else {
                      console.warn('Access token not found in response');
                      notification('Somthing want to wrong', 'error');
                    }
                  }}
                  scope='profile%20email%20openid'
                  onReject={(err) => {
                    console.error('Login error:', err);
                  }}
                  redirect_uri={`${BACKEND_URL}login`}
                >
                  <LinkedInLoginButton className={styles.socialbtn}>LinkedIn</LinkedInLoginButton>
                </LoginSocialLinkedin>

              </div>
              <div style={{ width: '100%' }}>
                <LoginSocialFacebook
                  appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                  onResolve={(response) => {
                    console.log('facebook login', response);
                    if (response?.data?.accessToken !== undefined && response?.data?.accessToken !== null && response?.data?.accessToken !== '') {
                      const data = {
                        accessToken: response?.data?.accessToken,
                        user_role: accountType
                      };
                      dispatch(LoginActions.facebookLoginRequest(data));
                    }
                  }}
                  onReject={(err) => {
                    console.log('facebook login err', err);
                    notification('Somthing want to wrong', 'error');
                  }}
                  version='v21.0'
                  language='en_EN'
                >
                  <FacebookLoginButton className={`${styles.socialbtn}`}>Facebook</FacebookLoginButton>
                </LoginSocialFacebook>
              </div>
            </div>
          </div>
        </div>
      </div >
      <NotificationContainer />
    </>
  )
}
const mapStateToProps = (state) => ({
  signup: state.signup,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(RegistrationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
