import { ActionTypes } from './changepasswordActions';

/**
 * @author      Nandani.V.Patel
 * @date        18 Oct 2024
 * @description change-password Reducer.
 * @param       takes default and previous state value
 * @response    update a state value and store change-password api response  
**/

const defaultState = {
  isChangePassword: false,
  data: undefined
};

const changePasswordReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        data: undefined,
        isChangePassword: true,
      };
    case ActionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        data: action.data,
        isChangePassword: false,
      };
    case ActionTypes.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isChangePassword: false,
        data: undefined,
        error: action.error,
      };
    default:
      return state;
  }
}

export default changePasswordReducer;