import React from 'react';
import { Link } from 'react-router-dom';
import { DASHBOARD } from '../../global/routes';

/**
 * @author      Nandani.V.Patel
 * @date        23th Nov 2024
 * @description UI Implementation of Breadcrumb
**/

const Breadcrumb = ({ breadcrumbs }) => {
  return (
    <div className="breadcrumb-area">
      <ol className="breadcrumb" style={{ paddingLeft: '0px', alignItems: 'center' }}>
        <li className="item">
          <Link to={DASHBOARD} style={{ display: 'inline' }}>Dashboard</Link>
        </li>

        {breadcrumbs?.map((breadcrumb, index) => (
          <li className="item" key={index}>
            <i className="fa-solid fa-angle-right" />
            {breadcrumb?.link ? (
              <Link to={breadcrumb?.link} style={{ display: 'inline' }}>{breadcrumb?.name}</Link>
            ) : (
              breadcrumb?.name
            )}
          </li>
        ))}
      </ol>
    </div>
  );
};

export default Breadcrumb;

