/**
 * @author      Nandani.V.Patel
 * @date        08 Oct 2024
 * @description its defines isAuth() fn ,it check user token set in localstorage or not.
 * @param
 * @response 
**/

// this key are into the Encrypted format for store into localStorage
import { EncryptStorage } from 'encrypt-storage';

const secretKey = '6512bd43d9caa6e02c990b0a82652dca';

export const tokenkey = '/c20ad4d76fe97759aa27a0c99bff6710';
export const rememberMeKey = 'c51ce410c124a10e0db5e4b97fc2af39';
export const emailKey = 'aab3238922bcc25a6f606eb525ffdc56';
export const passKey = '9bf31c7ff062936a96d3c8bd1f8f2ff3';
export const identifier = 'c74d97b01eae257e44aa9d5bade97baf';
export const userId = '70efdf2ec9b086079795c442636b55fb';
export const roleId = '6f4922f45568161a8cdf4ad2299f6d23';
export const roleName = '1f0e3dad99908345f7439f8ffabdffc4';

export function isAuth() {
  const tokenExpires = localStorage.getItem(tokenkey);
  if (tokenExpires !== undefined && tokenExpires !== null && tokenExpires !== '') {
    return true;
  }
  return false;
}

export const encryptStorage = new EncryptStorage(secretKey);