import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { calcCheckoutPrice } from '../redux/actions';
import { getwebsiteRequest } from '../../companylist/companylistActions';
import { validationRegex } from '../../../utils/conts';
import { CHECKOUT_PAGE } from '../../../global/routes';
import { NotificationContainer } from '../../../component/notificationContainer';
import { notification } from '../../../component/notificationContainer/notification';

import styles from "../styles.module.scss";

const PurchaseReviews = () => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      reviewData: [],
    },
    mode: 'onChange',
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const propState = useSelector((state) => ({
    reviewSiteList: state?.companylist?.websites || [],
    siteLoading: state?.companylist?.siteLoading || false,
    checkoutPrices: state?.companyPanel?.checkoutPrices || "",
  }));

  useEffect(() => {
    dispatch(getwebsiteRequest());
  }, []);

  const customStyles = {
    headCells: {
      style: {
        verticalAlign: 'middle',
        whiteSpace: 'nowrap',
        padding: '18px 10px',
        fontSize: '18px',
        fontWeight: '600'
      }
    },
    cells: {
      style: {
        verticalAlign: "middle",
        padding: "25px 15px",
        fontWeight: "500",
        fontSize: "16px"
      },
    },
  };

  const columns = [
    {
      name: 'Review Site',
      selector: (row) => row.review_site_tbl_name,
      cell: (row) => (
        <>
          <div className="site-logo" style={{ width: "75px", height: "75px" }}>
            <img src={row.review_site_logo} alt={row.review_site_logo.split('/').pop()} width={70} height={70} />
          </div>
          <div className="site-name">
            <h5 className='mb-0'>{row.review_site_tbl_name}</h5>
          </div>
        </>
      ),
      sortable: true,
    },
    {
      name: 'Per Review Price',
      selector: (row) => `₹ ${row.review_site_tbl_review_price}`, 
      sortable: true,
      // width: "250px",
    },
    {
      name: 'Purchase Review Count',
      cell: (row) => (
        <div>
          <Controller
            control={control}
            name={`reviewData.${row.id}.count`}
            defaultValue={0}
            render={({ field }) => (
              <div>
                <input
                  type="number"
                  className="form-control"
                  maxLength={5}
                  max={9999}
                  min={0}
                  {...field}
                  placeholder="Purchase count"
                  onChange={(e) => setValue(`reviewData.${row.id}.count`, parseInt(e.target.value))}
                />
                {errors?.reviewData?.[row.id]?.count && (
                  <span className={`text-danger ${styles.errorMsg}`}>{errors.reviewData[row.id].count.message}</span>
                )}
              </div>
            )}
          />
        </div>
      ),
      sortable: false,
      width: "20%",
    },
    {
      name: 'Site URL',
      cell: (row) => (
        <div>
          <Controller
            control={control}
            name={`reviewData.${row.id}.site_url`} // Using row.id as the unique key for each site
            defaultValue="" // Default value for input
            rules={{
              pattern: {
                value: validationRegex.reviewLink,
                message: 'Please enter a valid URL starting with http://, https:// or www.'
              }
            }}
            render={({ field }) => (
              <div>
                <input
                  type="text"
                  {...field}
                  className="form-control"
                  placeholder="Review site URL"
                />
                {errors?.reviewData?.[row.id]?.site_url && (
                  <span className={`text-danger ${styles.errorMsg}`}>{errors.reviewData[row.id].site_url.message}</span>
                )}
              </div>
            )}
          />
        </div>
      ),
      sortable: false,
    },
  ];

  // handle purchased reviews on checkout
  const onSubmit = async (data) => {
    const reviewSiteData = Object.entries(data.reviewData)
      .map(([id, values]) => ({
        review_site_id: parseInt(id),
        purchased_reviews: parseInt(values.count),
        review_site_url: values.site_url,
      }))
      .filter((item) => item.purchased_reviews > 0 && item.review_site_url); // check if user has fill out all required details

    // If no valid data, show error and exit the function
    if (reviewSiteData.length === 0) {
      // console.log("No valid reviews to process");
      notification('No valid review data to process', 'warning');
      return;
    }

    // count total purchased reviews
    // const totalPurchasedReviews = reviewSiteData.reduce((total, item) => total + item.purchased_reviews, 0);
    // dispatch(calcCheckoutPrice({
    //   reviewSiteData,
    //   totalPurchasedReviews,
    // }));

    dispatch(calcCheckoutPrice(reviewSiteData));

    navigate(CHECKOUT_PAGE, { state: reviewSiteData });
  };

  return (
    <div>
      <div className={`p-3 mb-4 header-box`}>
        <h4 className="header-text">Reviews Packages</h4>
      </div>
      <div className="puchase-reviews-box">
        <div className="puchase-reviews-table table-responsive mb-3">
          <DataTable
            columns={columns}
            data={propState.reviewSiteList}
            theme="solarized"
            fixedHeader
            responsive
            customStyles={customStyles}
            progressPending={propState.siteLoading}
          />
        </div>
        <button className="blue-btn rounded mb-4 fw-medium" onClick={handleSubmit(onSubmit)}>Proceed Checkout</button>
      </div>
      <NotificationContainer />
    </div>
  );
}

export default PurchaseReviews;