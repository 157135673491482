import { ActionTypes } from './companylistActions';

/**
 * @author      Nandani.V.Patel
 * @date        15 Oct 2024
 * @description companylisting Reducer.
 * @param       takes default and previous state value
 * @response    update a state value and store companylisting api response  
**/

const defaultState = {
  pendingTargetCompanies: {},
  websites: [],
  isCompanyListing: false,
  siteLoading: false,
  loading: false
};

const comapnyListingReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.COMPANY_LISTING_REQUEST:
      return {
        ...state,
        isCompanyListing: true,
      };
    case ActionTypes.COMPANY_LISTING_SUCCESS:
      return {
        ...state,
        data: action.data,
        isCompanyListing: false,
      };
    case ActionTypes.COMPANY_LISTING_FAILURE:
      return {
        ...state,
        isCompanyListing: false,
        error: action.error,
      };
    case ActionTypes.GET_WEBSITE_REQUEST:
      return {
        ...state,
        siteLoading: true,
      };
    case ActionTypes.GET_WEBSITE_SUCCESS:
      return {
        ...state,
        websites: action.data,
        siteLoading: false,
      };
    case ActionTypes.GET_WEBSITE_FAILURE:
      return {
        ...state,
        siteLoading: false,
        error: action.error,
      };
    case ActionTypes.GET_PENDING_TARGET_COMPANIES:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_PENDING_TARGET_COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        pendingTargetCompanies: action.data
      };
    case ActionTypes.GET_PENDING_TARGET_COMPANIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}

export default comapnyListingReducer;