import { call, fork, put, takeLatest } from "redux-saga/effects";
import { postRequestAPI } from "../../../global/api";
import { ActionTypes } from "./actions";
import { defaultErrorMsg } from "../../../utils/conts";

function* createSubTLSaga(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'register', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.CREATE_SUB_TL_SUCCESS, payload: resData });
    } else {
      yield put({ type: ActionTypes.CREATE_SUB_TL_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.CREATE_SUB_TL_FAILURE, error: defaultErrorMsg });
  }
}

function* getSubTlReq(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'team-leader/sub-team-leader', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.GET_SUB_TL_SUCCESS, payload: resData });
    } else {
      yield put({ type: ActionTypes.GET_SUB_TL_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_SUB_TL_FAILURE, error: defaultErrorMsg });
  }
}

function* watchSubTLSaga() {
  yield takeLatest(ActionTypes.CREATE_SUB_TL, createSubTLSaga);
  yield takeLatest(ActionTypes.GET_SUB_TL, getSubTlReq);
}

function* SubTLSaga() {
  yield fork(watchSubTLSaga);
}

export default SubTLSaga;