import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { EMPCOMPANIES } from '../../../global/routes';
import { NotificationContainer } from '../../../component/notificationContainer';
import Breadcrumb from '../../../component/breadcrumbs';
import { notification } from '../../../component/notificationContainer/notification';
import CompanyRegisterForm from '../../../component/companyRegisterForm';
import { companyregistrationRequest } from '../../comapny-Register/comapnyRegisterActions';

const CreateCompany = () => {
  const { register, formState: { errors }, getValues, handleSubmit, watch, reset } = useForm({ mode: "onChange" });
  const dispatch = useDispatch();

  const breadcrumbs = [
    { name: 'Company List', link: EMPCOMPANIES },
    { name: 'Register Company', link: "" },
  ];

  const propState = useSelector((state) => ({
    data: state.companySignup.data,
    loading: state.companySignup.isCompanyRegistration,
    errorMsg: state.companySignup.error?.message || "",
    successMsg: state.companySignup.successMsg || ""
  }));

  useEffect(() => {
    if (propState.successMsg) {
      notification(propState.successMsg, 'success');
      reset();
    }
    if (propState.errorMsg) {
      notification(propState.errorMsg, 'error');
    }
  }, [propState.successMsg, propState.errorMsg]);

  const onSubmit = (data) => {
    const password = getValues('password');
    const password_confirmation = getValues('password_confirmation');

    if (password !== password_confirmation) {
      notification('Password and Confirm-Password must be same.', 'error');
    } else {
      const details = {
        ...data,
        company_logo: data.company_logo[0],
        user_role: "company"
      };

      const formData = new FormData();
      for (const key in details) {
        formData.append(key, details[key]);
      }

      dispatch(companyregistrationRequest(formData));
    }
  };

  // This function will be called when the user presses the Enter key
  const handleKeyPress = (event) => {
    // Check if the key pressed is the Enter key
    if (event.keyCode === 13) {
      handleSubmit(onSubmit)();
    }
  };

  // Add an event listener to the document that listens for the keyup event
  // This event is triggered when the user releases a key
  useEffect(() => {
    document.addEventListener('keyup', handleKeyPress);
    // When this component is unmounted, remove the event listener
    return () => document.removeEventListener('keyup', handleKeyPress);
  }, []);

  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div className={`d-flex justify-content-between align-items-center p-3 mb-4 header-box`}>
        <h4 className={`col-md-10 header-text`}>
          Register Company
        </h4>
      </div>
      <div className="my-profile-area">
        <div className="profile-form-content">
          <div className="profile-form">

            <CompanyRegisterForm
              register={register}
              errors={errors}
              watch={watch}
            />

            <button
              type="submit"
              className="blue-btn rounded"
              onClick={handleSubmit(onSubmit)}
              disabled={propState.loading}
            >
              {!propState.loading ? (
                <>Register</>
              ) : (
                <>
                  <div
                    className="spinner-border "
                    style={{ width: "1.2rem", height: "1.2rem" }}
                    role="status"
                  />
                </>
              )}
            </button>
          </div>
        </div>
      </div >
      <NotificationContainer />
    </>
  )
}

export default CreateCompany;