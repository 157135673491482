import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './resetpasswordActions';
import { postWithoutHeaderRequestAPI, getWithoutHeaderRequestAPI } from '../../global/api';
import { defaultErrorMsg } from '../../utils/conts';

/**
 * @author      Nandani.V.Patel
 * @date        14 Oct 2024
 * @description Reset-password Saga and handling API response.
 * @param       resetpassword-user Request paylod
 * @response    reset-password is success or fail.  
**/

function* resetPassSaga(action) {
  try {
    const response = yield call(postWithoutHeaderRequestAPI, { url: 'reset-password', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.RESET_PASSWORD_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.RESET_PASSWORD_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.RESET_PASSWORD_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* watchResetpassRequest() {
  yield takeLatest(ActionTypes.RESET_PASSWORD_REQUEST, resetPassSaga);
}

function* resetPasswordSaga() {
  yield fork(watchResetpassRequest);
}

export default resetPasswordSaga;