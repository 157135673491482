import React from "react"
import { useSelector } from "react-redux";

const LatestOrderBlock = ({ styles }) => {
  const latestOrder = useSelector(({ companyPanel }) => companyPanel.latestOrder);

  return (
    <>
      <div className='row'>
        <div className="col-md-12">
          <div className={`latest-order-box ${styles.tableheader}`}>
            <h3 className={styles.heading}>Latest Campaign</h3>
          </div>
        </div>
      </div>
      <div className={`latest-order-table ${styles.tableContainer}`}>
        <table className={`table table-bordered mb-0 ${styles.mainTable}`}>
          {latestOrder ? (
            <>
              {/* Main Table Header */}
              <thead>
                <tr>
                  <th>Campaign</th>
                  <th>Purchase Date</th>
                </tr>
              </thead>
              <tbody>
                {/* Main Order Row */}
                <tr>
                  <td>Campaign {latestOrder.id}</td>
                  <td>
                    {new Date(latestOrder.created_at).toLocaleDateString('en-GB', {
                      day: '2-digit',
                      month: 'short',
                      year: 'numeric'
                    })}
                  </td>
                </tr>
                {/* Expandable Row for Nested Table */}
                <tr className="expandable-row">
                  <td colSpan={2} className="py-0 px-4">
                    {/* Nested Table */}
                    <table className={`table nested-table ${styles.nestedTable}`}>
                      <thead>
                        <tr>
                          <th className={styles.title}>Review Site</th>
                          <th className={styles.title}>Purchased Count</th>
                          <th className={styles.title}>Pending Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {latestOrder.review_campaigns.map((campaign) => (
                          <tr key={campaign.id}>
                            <td className={styles.reviewSite}>
                              <img
                                src={campaign?.review_site_logo || ""}
                                alt={campaign?.review_site_tbl_name}
                                width="50px"
                                height="50px"
                              />
                              <span className=''>{campaign?.review_site_tbl_name || "-"}</span>
                            </td>
                            <td>{campaign.review_campaign_tbl_purchased_reviews || 0}</td>
                            <td>
                              {campaign.review_campaign_tbl_purchased_reviews -
                                campaign.review_campaign_tbl_completed_reviews || 0}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </>
          ) : (
            <tbody>
              <tr>
                <td className={styles.notfound} colSpan="2">No campaigns found</td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </>
  )
}

export default LatestOrderBlock;