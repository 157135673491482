/**
 * @author      Nandani.V.Patel
 * @date        18 Oct 2024
 * @description ActionTypes and ActionCreator Function for change-password Request.
 * @param
 * @response      
**/

export const ActionTypes = {
  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',
};

export const changepasswordRequest = (changepasswordData) => {
  return {
    type: ActionTypes.CHANGE_PASSWORD_REQUEST,
    payload: changepasswordData,
  };
};