import React, { useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '40px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const SubmitReviewModal = ({ onClose }) => {
  const { handleSubmit } = useForm();
  const [files, setFiles] = useState([]);
  const [error, setError] = useState('');

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: {
      'image/*': []
    },
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
      setError('');
    },
    onDropRejected: () => {
      setError("Invalid file format. Please upload an image file.");
    }
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  const thumbs = files.map(file => (
    <div key={file.name} className="thumb">
      <div className="thumb-inner">
        <img
          src={file.preview}
          alt={file.name}
          style={{ width: '50%', height: '50%' }}
        />
      </div>
    </div>
  ));

  const onSubmit = (data) => {
    if (files.length === 0) {
      setError('Please upload your review screenshot.');
    } else {
      const reviewdetails = {
        image: files[0]
      };
      console.log('reviewdetails', reviewdetails);
    }
  }

  return (
    <>
      <div
        className="modal fade show d-block"
        id="addReviewModal"
        tabIndex="-1"
        aria-labelledby="addReviewModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content add-review">
            <div className="modal-header">
              <h3 className='text-light'>Review Submission</h3>
              <button
                type="button"
                className="btn-close btn-close-white text-light"
                onClick={onClose}
                aria-label="Close"
              />
            </div>
            <div className="modal-body review-form">
              <div className="row d-block">
                {/* <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      {...register("reviewlink")}
                      className="form-control"
                      placeholder="Review Link"
                    />
                  </div>
                </div> */}
                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <div {...getRootProps({ style })}>
                      <input {...getInputProps()} />
                      {files?.length === 0 ? (
                        <p style={{ color: '#666', fontWeight: '500' }}>Upload your review screenshot</p>
                      ) : (
                        thumbs
                      )}
                    </div>
                  </div>
                  {error && (
                    <span className='text-danger'>{error}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="submit" className="default-btn btn" onClick={handleSubmit(onSubmit)}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" onClick={onClose}></div>
    </>
  )
}

export default SubmitReviewModal;