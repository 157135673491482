import React, { Fragment, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import * as ChangePasswordActions from './changepasswordActions';
import { NotificationContainer } from '../../component/notificationContainer';
import { notification } from '../../component/notificationContainer/notification';
import { validationRegex } from '../../utils/conts';
import { pwdTooltipContent } from '../../utils/helpers';

import styles from './styles.module.scss'

/**
 * @author      Nandani.V.Patel
 * @date        18th Oct 2024
 * @description UI Implementation of ChangePassword
 * @param
 * @response 
**/

const ChangePassword = (props) => {
  const { actions, changepassword } = props;
  const { register, handleSubmit, resetField, getValues, formState: { errors }, } = useForm();
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    const new_password = getValues('new_password');
    const password_confirmation = getValues('password_confirmation');

    if (new_password !== password_confirmation) {
      notification('new password and confirm password do not match.', 'error');
    } else {
      actions.changepasswordRequest(data);
    }
  }

  useEffect(() => {
    if (typeof changepassword?.data !== 'undefined' && !changepassword?.isChangePassword && changepassword?.data?.status === 'success') {
      notification(changepassword?.data?.message, 'success');
      dispatch({ type: ChangePasswordActions.ActionTypes.CHANGE_PASSWORD_SUCCESS, data: undefined });
      resetField('current_password');
      resetField('new_password');
      resetField('password_confirmation');
    }
    if (typeof changepassword?.data === 'undefined' && changepassword?.error?.message && !changepassword?.isChangePassword) {
      notification(changepassword?.error?.message, 'error');
      dispatch({ type: ChangePasswordActions.ActionTypes.CHANGE_PASSWORD_FAILURE, error: undefined });
    }
  }, [changepassword?.data, changepassword?.isChangePassword]);

  return (
    <Fragment>
      <div className="row" style={{ justifyContent: 'center' }}>
        <div className="col-lg-6">
          <div className="p-3 mb-4 header-box">
            <h4 className="header-text">Change Password</h4>
          </div>
          <div className="chainge-password-box">
            <div className="password-form">
              <div className="form-group">
                <label htmlFor='current_password'>Current Password</label>
                <input
                  type="password"
                  {...register("current_password", {
                    required: 'Current Password is required.',
                    validate: {
                      minLength: (value) => value.length >= 8 || 'Password should be at least 8 characters.',
                      maxLength: (value) => value.length <= 16 || 'Password should be at most 16 characters.',
                      complexity: (value) => validationRegex.password.test(value) || 'Password must meet complexity requirements.',
                    },
                  })}
                  className="form-control"
                  placeholder="Current Password"
                />
                {errors?.current_password?.type === 'required' && (<span className={styles.errormsg}>{errors.current_password.message}</span>)}
                {errors?.current_password?.type === 'minLength' && (<span className={styles.errormsg}>{errors.current_password.message}</span>)}
                {errors?.current_password?.type === 'maxLength' && (<span className={styles.errormsg}>{errors.current_password.message}</span>)}
                {errors?.current_password?.type === 'complexity' && (<span className={styles.errormsg}>{errors.current_password.message}</span>)}
              </div>
              <div className="form-group position-relative">
                <label htmlFor='new_password'>New Password</label>
                <input
                  type="password"
                  {...register("new_password", {
                    required: 'New Password is required.',
                    validate: {
                      minLength: (value) => value.length >= 8 || 'Password should be at least 8 characters.',
                      maxLength: (value) => value.length <= 16 || 'Password should be at most 16 characters.',
                      complexity: (value) => validationRegex.password.test(value) || 'Password must meet complexity requirements.',
                    },
                  })}
                  className="form-control"
                  placeholder="New Password"
                />
                {errors?.new_password?.type === 'required' && (<span className={styles.errormsg}>{errors.new_password.message}</span>)}
                {errors?.new_password?.type === 'minLength' && (<span className={styles.errormsg}>{errors.new_password.message}</span>)}
                {errors?.new_password?.type === 'maxLength' && (<span className={styles.errormsg}>{errors.new_password.message}</span>)}
                {errors?.new_password?.type === 'complexity' && (<span className={styles.errormsg}>{errors.new_password.message}</span>)}
                <i
                  className="fas fa-info-circle info-icon pwdInfoIcon"
                  style={{ top: (errors?.new_password ? "calc(50% + 5px)" : "calc(50% + 15px)") }}
                  data-tooltip-id="pwd-validate-tooltip"
                />
                <ReactTooltip id="pwd-validate-tooltip" place="right" content={pwdTooltipContent} />
              </div>
              <div className="form-group position-relative">
                <label htmlFor='password_confirmation'>Confirm New Password</label>
                <input
                  type="password"
                  {...register("password_confirmation", {
                    required: 'Confirm New Password is required.',
                    validate: {
                      minLength: (value) => value.length >= 8 || 'Password should be at least 8 characters.',
                      maxLength: (value) => value.length <= 16 || 'Password should be at most 16 characters.',
                      complexity: (value) => validationRegex.password.test(value) || 'Password must meet complexity requirements.',
                    },
                  })}
                  className="form-control"
                  placeholder=" Confirm New Password"
                />
                {errors?.password_confirmation?.type === 'required' && (<span className={styles.errormsg}>{errors.password_confirmation.message}</span>)}
                {errors?.password_confirmation?.type === 'minLength' && (<span className={styles.errormsg}>{errors.password_confirmation.message}</span>)}
                {errors?.password_confirmation?.type === 'maxLength' && (<span className={styles.errormsg}>{errors.password_confirmation.message}</span>)}
                {errors?.password_confirmation?.type === 'complexity' && (<span className={styles.errormsg}>{errors.password_confirmation.message}</span>)}
                <i
                  className="fas fa-info-circle info-icon pwdInfoIcon"
                  style={{ top: (errors?.password_confirmation ? "calc(50% + 5px)" : "calc(50% + 15px)") }}
                  data-tooltip-id="pwd-validate-tooltip"
                />
                <ReactTooltip id="pwd-validate-tooltip" place="right" content={pwdTooltipContent} />
              </div>
              <button
                type="submit"
                onClick={handleSubmit(onSubmit)}
                className="blue-btn rounded"
                disabled={changepassword.isChangePassword && changepassword.data !== 'undefiend'}
              >
                {(!changepassword.isChangePassword && changepassword.data !== 'undefiend')
                  ? "Update"
                  : <div className="spinner-border" style={{ width: '1.2rem', height: '1.2rem' }} role="status" />
                }
              </button>
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </Fragment>
  )
}
const mapStateToProps = (state) => ({
  changepassword: state.changepassword,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ChangePasswordActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);