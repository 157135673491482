import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './teamleaderAdduserActions';
import { postRequestAPI } from '../../global/api';
import { defaultErrorMsg } from '../../utils/conts';

/**
 * @author      Nandani.V.Patel
 * @date        24 Oct 2024
 * @description Add Team-leader User Saga and handling API response.
 * @param       
 * @response    list of user in system.  
**/

function* addTeamleaderUserSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'team-leader/create-team', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.ADD_TEAM_LEADER_USER_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.ADD_TEAM_LEADER_USER_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.ADD_TEAM_LEADER_USER_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* getunassignedUserSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'team-leader/unassigned-users', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.GET_UNASSIGNED_USER_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.GET_UNASSIGNED_USER_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_UNASSIGNED_USER_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* watchaddTeamleaderUserRequest() {
  yield takeLatest(ActionTypes.ADD_TEAM_LEADER_USER_REQUEST, addTeamleaderUserSaga);
  yield takeLatest(ActionTypes.GET_UNASSIGNED_USER_REQUEST, getunassignedUserSaga);
}

function* addTeamleaderuserSaga() {
  yield fork(watchaddTeamleaderUserRequest);
}

export default addTeamleaderuserSaga;