import React from 'react';
import { HOME, TERMSCONDITIONS } from '../../global/routes';
import { Link } from 'react-router-dom';

/**
 * @author      Nandani.V.Patel
 * @date        8th Oct 2024
 * @description UI Implementation of Dashboard Footer
 * @param
 * @response 
**/

const Footer = () => {
  return (
    <>
      <div className="footer-area bg-color pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="single-footer-widget logo-content">
                <div className="footer-logo">
                  <Link to={HOME}>
                    <img src="/assets/images/white-logo.png" alt="footer-logo" />
                  </Link>
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consec tetur adipiscing elit eiusmod
                  tempor incididunt labore dolore magna aliqua consec tetur
                  adipiscing elite sed do labor.
                </p>
                <div className="social-content">
                  <ul>
                    <li>
                      <span>Follow Us:</span>
                    </li>
                    <li>
                      <a href="https://www.facebook.com/" target="_blank">
                        <i className="fa-brands fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.twitter.com/" target="_blank">
                        <i className="fa-brands fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="https://instagram.com/?lang=en" target="_blank">
                        <i className="fa-brands fa-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-footer-widget quick-link">
                <h3>For Employer</h3>
                <ul>
                  <li>
                    <a href="candidates.html">Browse Candidates</a>
                  </li>
                  <li>
                    <a href="employers.html">Employers Dashboard</a>
                  </li>
                  <li>
                    <a href="packages.html">Job Packages</a>
                  </li>
                  <li>
                    <a href="manage-jobs.html">Jobs Featured</a>
                  </li>
                  <li>
                    <a href="post-job.html">Post A Job</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-sm-6">
              <div className="single-footer-widget quick-link">
                <h3>Company</h3>
                <ul>
                  <li>
                    <a href="about-us.html">About Us</a>
                  </li>
                  <li>
                    <a href="contact.html">Contact Us</a>
                  </li>
                  <li>
                    <Link to={TERMSCONDITIONS}>Terms &amp; Conditions</Link>
                  </li>
                  <li>
                    <a href="privacy-policy.html">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="candidates.html">Candidate Listing</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-footer-widget info">
                <h3>Official Info</h3>
                <ul>
                  <li>
                    <i className="fa-solid fa-location-dot" />
                    <h4>Location:</h4>
                    <span>2976 sunrise road las vegas</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-envelope" />
                    <h4>Email:</h4>
                    <a href="https://templates.hibootstrap.com/cdn-cgi/l/email-protection#9ff7faf3f3f0dff5fefdfeb1fcf0f2">
                      <span
                        className="__cf_email__"
                        data-cfemail="264e434a4a49664c4744470845494b"
                      >
                        [email&nbsp;protected]
                      </span>
                    </a>
                  </li>
                  <li>
                    <i className="fa-solid fa-phone" />
                    <h4>Phone:</h4>
                    <a href="tel:098765432150">098765432150</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="copy-right">
        <div className="container">
          <p>
            © <span>Jaba</span> is Proudly Owned by{" "}
            <a href="https://hibootstrap.com/" target="_blank">
              HiBoostrap
            </a>
          </p>
        </div>
      </div> */}
      <div className="go-top">
        <i className="fa-solid fa-arrow-up-long" />
        <i className="fa-solid fa-arrow-up-long" />
      </div>
    </>
  )
}
export default Footer;
