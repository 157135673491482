import { all, fork } from 'redux-saga/effects';
import signUpSaga from '../../pages/registration/registerSaga';
import signInSaga from '../../pages/login/loginSaga';
import userProfileSaga from '../../pages/profile/profileSaga';
import logoutSaga from '../../pages/logout/logoutSaga';
import companySignUpSaga from '../../pages/comapny-Register/comapnyRegisterSaga';
import forgotPasswordSaga from '../../pages/forgotpassword/forgotpasswordSaga';
import resetPasswordSaga from '../../pages/resetpassword/resetpasswordSaga';
import categorylistSaga from '../../pages/categorylist/categorylistSaga';
import companyListingSaga from '../../pages/companylist/companylistSaga';
import userReviewsSaga from '../../pages/userReview/userReviewSaga';
import purchaseReviewsSaga from '../../pages/purchaseReview/purchaseReviewSaga';
import dashboardCountSaga from '../../pages/dashboardCount/dashboardcountSaga';
import companyDetailsSaga from '../../pages/companyDetails/companyDetailsSaga';
import changePasswordSaga from '../../pages/changePassword/changepasswordSaga';
import dashboardUserCountSaga from '../../pages/dashboard/dashboardcountSaga';
import reviewSaga from '../../pages/reviews/reviewsSaga';
import teamleaderuserSaga from '../../pages/team-leader-users/teamleaderuserSaga';
import teamleadercompanySaga from '../../pages/team-leader-companylist/teamleaderCompanylistSaga';
import addTeamleaderuserSaga from '../../pages/team-leader-add-user/teamleaderAdduserSaga';
import teamleadercompanyreviewsiteSaga from '../../pages/team-leader-reviewsite/teamleaderReviewsiteSaga';
import TeamleaderRevenueSaga from '../../pages/teamleaderRevenue/teamleaderRevenueSaga';
import companyUserReviewSaga from '../../pages/companyUserReview/companyUserReviewSaga';
import CompanyPanelSaga from '../../pages/companyPanel/redux/saga';
import SubTLSaga from '../../pages/team-leader-subtl-list/redux/saga';
import EmployeePanelSaga from '../../pages/employeePanel/redux/saga';
import WithdrawalSaga from '../../pages/Withdrawal/redux/saga';
import TeamLeaderTargetsSaga from '../../pages/team-leader-targets/redux/saga';

/**
 * @author      Nandani.V.Patel
 * @date        04 Oct 2024
 * @description rootSaga function which contains all react-application saga.
 * @param       
 * @response      
**/

export function* rootSaga() {
  yield all([
    fork(signUpSaga),
    fork(signInSaga),
    fork(userProfileSaga),
    fork(logoutSaga),
    fork(companySignUpSaga),
    fork(forgotPasswordSaga),
    fork(resetPasswordSaga),
    fork(categorylistSaga),
    fork(companyListingSaga),
    fork(userReviewsSaga),
    fork(purchaseReviewsSaga),
    fork(dashboardCountSaga),
    fork(companyDetailsSaga),
    fork(changePasswordSaga),
    fork(dashboardUserCountSaga),
    fork(reviewSaga),
    fork(teamleaderuserSaga),
    fork(teamleadercompanySaga),
    fork(addTeamleaderuserSaga),
    fork(SubTLSaga),
    fork(teamleadercompanyreviewsiteSaga),
    fork(TeamLeaderTargetsSaga),
    fork(TeamleaderRevenueSaga),
    fork(companyUserReviewSaga),
    fork(CompanyPanelSaga),
    fork(EmployeePanelSaga),
    fork(WithdrawalSaga)
  ]);
}