import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { validationRegex } from '../../utils/conts';
import { pwdTooltipContent } from '../../utils/helpers';
import { getCountriesRequest } from '../../pages/comapny-Register/comapnyRegisterActions';
import { getCategorylistRequest } from '../../pages/categorylist/categorylistActions';

import styles from './styles.module.scss';

const CompanyRegisterForm = ({ register, errors, watch }) => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state?.categorylist?.data?.data || []);
  const countries = useSelector(({ companySignup }) => companySignup?.countries?.data || []);

  useEffect(() => {
    // API call for category and countries
    dispatch(getCategorylistRequest());
    dispatch(getCountriesRequest());
  }, []);

  // Watch password field to validate confirm password
  const password = watch("password");

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <select
              {...register("category", {
                required: 'Category is required.',
              })}
              className={`form-select ${styles.dropdown}`}
            >
              <option selected value="">Select Category</option>
              {categories?.map((category) => (
                <option key={category?.id} value={category?.id}>
                  {category?.category_name}
                </option>
              ))}
            </select>
            {errors?.category && <span className={`text-danger ${styles.errormsg}`}>{errors.category.message}</span>}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="text"
              {...register("company_name", {
                required: 'Company Name is required.',
                validate: {
                  hasNoNumbers: (value) => validationRegex.nameHasNoNum.test(value) || "Company Name cannot contain numbers.",
                  hasNoSpecialChars: (value) => validationRegex.nameHasNoSpclChar.test(value) || "Company Name cannot contain special characters.",
                },
                minLength: {
                  value: 2,
                  message: 'Company Name too short.',
                },
                maxLength: {
                  value: 50,
                  message: 'Company Name too long.',
                },
              })}
              className="form-control"
              placeholder="Company Name"
            />
            {errors?.company_name && (
              <span className={`text-danger ${styles.errormsg}`}>{errors.company_name.message}</span>
            )}
          </div>
        </div>
        <div className='col-md-6'>
          <div className='col-xl-12 col-md-12'>
            <label htmlFor='companyLogo'>Company Logo</label>
          </div>
          <div className="col-xl-12 col-md-12">
            <div className="form-group">
              <input
                type="file"
                accept=".png, .jpg, .jpeg, .webp"
                {...register("company_logo", {
                  required: "Company Logo is required.",
                  validate: {
                    isImage: (value) => {
                      if (value?.[0]) {
                        const file = value[0];
                        const validFormats = ['image/jpeg', 'image/png', 'image/jpg', 'image/webp'];
                        return validFormats.includes(file.type) || "Only JPEG, JPG, PNG, or WebP image files are allowed.";
                      }
                      return true;
                    },
                    isFileSizeValid: (value) => {
                      if (value?.[0]) {
                        const file = value[0];
                        return file.size < 2 * 1024 * 1024 || "File size must be less than 2MB.";
                      }
                      return true;
                    }
                  }
                })}
                id="companyLogo"
                className="form-control"
                placeholder="Company Logo"
              />
              {errors?.company_logo && (
                <span className={`text-danger ${styles.errormsg}`}>{errors.company_logo.message}</span>
              )}
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div className='col-xl-12 col-md-12'>
            <label htmlFor="companyEstablishedDate">Company Establish Date</label>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <input
                type="date"
                max={new Date().toISOString().split('T')[0]}
                min="1900-01-01"
                {...register("company_established_date", {
                  validate: (value) => {
                    if (value) {
                      const today = new Date().toISOString().split('T')[0];
                      return value <= today || 'Company Established Date cannot be in the future.';
                    }
                    return true;
                  },
                })}
                id="companyEstablishedDate"
                className="form-control"
                placeholder="Company Established Date"
              />
              {errors?.company_established_date && (
                <span className={`text-danger ${styles.errormsg}`}>{errors.company_established_date.message}</span>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="text"
              {...register("company_website_url", {
                required: 'Company Website URL is required.',
                pattern: {
                  // value: /^(https?:\/\/)([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
                  value: validationRegex.url,
                  message: 'Please enter a valid URL starting with http://, https:// or www.'
                }
              })}
              className="form-control"
              placeholder="Company Website URL"
            />
            {errors?.company_website_url && (
              <span className={`text-danger ${styles.errormsg}`}>{errors.company_website_url.message}</span>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="text"
              {...register("company_registration_number", {
                required: 'Company Registration Number is required.',
                pattern: {
                  value: /^[A-Z0-9]{10,15}$/,
                  message: 'Registration Number must be 10 to 15 characters long, containing only uppercase letters and numbers.',
                },
              })}
              className="form-control"
              placeholder="Company Registration Number"
            />
            {errors?.company_registration_number && (
              <span className={`text-danger ${styles.errormsg}`}>{errors.company_registration_number.message}</span>
            )}
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <textarea
              {...register("company_description")}
              className="form-control"
              placeholder="Company Description"
              rows="4"
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <textarea
              {...register("company_address", {
                required: 'Company Address is required.'
              })}
              className="form-control"
              placeholder="Company Address"
              rows="4"
            />
            {errors?.company_address && (
              <span className={`text-danger ${styles.errormsg}`}>{errors.company_address.message}</span>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <select
              {...register("company_country", {
                required: 'Company Country is required.',
              })}
              className={`form-select ${styles.dropdown}`}
            >
              <option selected value="">Select Country</option>
              {countries?.map((country) => (
                <option key={country?.id} value={country?.id}>{country?.nicename}</option>
              ))}
            </select>
            {errors?.company_country && (
              <span className={`text-danger ${styles.errormsg}`}>{errors.company_country.message}</span>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="text"
              {...register("pincode", {
                required: 'Pincode is required.',
                pattern: {
                  value: /^[1-9][0-9]{5}$/,
                  message: 'Pincode must be 6 digits.'
                }
              })}
              className="form-control"
              placeholder="Pincode"
            />
            {errors?.pincode && (
              <span className={`text-danger ${styles.errormsg}`}>{errors.pincode.message}</span>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="email"
              {...register("email", {
                required: 'Email Address is required.',
                pattern: {
                  value: validationRegex.email,
                  message: 'Please Enter a valid Email.',
                },
              })}
              className="form-control"
              placeholder="Email Address"
            />
            {errors?.email && (
              <span className={`text-danger ${styles.errormsg}`}>{errors.email.message}</span>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="text"
              {...register("phone_number", {
                required: 'Phone Number is required.',
                validate: {
                  hasTenDigits: (value) => value.length === 10 || "Phone Number must be 10 digits.",
                  isValidNumber: (value) => validationRegex.phoneNum.test(value) || "Invalid Phone Number.",
                },
              })}
              maxLength={10}
              className="form-control"
              placeholder="Phone Number"
            />
            {errors?.phone_number && (
              <span className={`text-danger ${styles.errormsg}`}>{errors.phone_number.message}</span>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="text"
              {...register("contact_person_name", {
                required: 'Contact Person Name is required.',
                validate: {
                  hasNoNumbers: (value) => validationRegex.nameHasNoNum.test(value) || "Name can not contain numbers.",
                  hasNoSpecialChars: (value) => validationRegex.nameHasNoSpclChar.test(value) || "Name can not contain special characters.",
                },
                minLength: {
                  value: 2,
                  message: "Contact Person Name too short."
                },
              })}
              className="form-control"
              placeholder="Contact Person Name"
            />
            {errors?.contact_person_name && (
              <span className={`text-danger ${styles.errormsg}`}>{errors.contact_person_name.message}</span>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="email"
              {...register("contact_person_email", {
                required: 'Contact Person Email is required.',
                pattern: {
                  value: validationRegex.email,
                  message: 'Please Enter a valid Email.',
                },
              })}
              className="form-control"
              placeholder="Contact Person Email"
            />
            {errors?.contact_person_email && (
              <span className={`text-danger ${styles.errormsg}`}>{errors.contact_person_email.message}</span>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <div className='position-relative'>
              <input
                type="password"
                {...register("password", {
                  required: 'Password is required.',
                  validate: {
                    minLength: (value) => value.length >= 8 || 'Password should be at least 8 characters.',
                    maxLength: (value) => value.length <= 16 || 'Password should be at most 16 characters.',
                    complexity: (value) =>
                      validationRegex.password.test(value) ||
                      'Password does not meet the required complexity. See info icon for details.',
                    noCommonPasswords: (value) =>
                      !["password", "12345", "12345678"].includes(value.toLowerCase()) ||
                      'Weak password, avoid common passwords like "password" or "12345".',
                  },
                })}
                className="form-control"
                placeholder="Password"
                autoComplete="new-password"
              />
              <i className="fas fa-info-circle info-icon pwdInfoIcon" data-tooltip-id="pwd-validate-tooltip"></i>
            </div>
            {errors?.password && (
              <span className={`text-danger ${styles.errormsg}`}>{errors.password.message}</span>
            )}
          </div>
          <ReactTooltip id="pwd-validate-tooltip" place="right" content={pwdTooltipContent} />
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <div className='position-relative'>
              <input
                type="password"
                {...register("password_confirmation", {
                  required: 'Confirmation Password is required.',
                  validate: {
                    minLength: (value) => value.length >= 8 || 'Confirmation Password should be at least 8 characters.',
                    maxLength: (value) => value.length <= 16 || 'Confirmation Password should be at most 16 characters.',
                    complexity: (value) =>
                      validationRegex.password.test(value) ||
                      'Confirmation Password does not meet the required complexity. See info for details.',
                    noCommonPasswords: (value) =>
                      !["password", "12345", "12345678"].includes(value.toLowerCase()) ||
                      'Weak password, avoid common passwords like "password" or "12345".',
                    // matchesPreviousPassword: (value) =>
                    //   value === password || 'Confirmation Password does not match with the Password.',
                  },
                })}
                className="form-control"
                placeholder="Confirmation Password"
                autoComplete="new-password"
              />
              <i className="fas fa-info-circle info-icon pwdInfoIcon" data-tooltip-id="pwd-validate-tooltip"></i>
            </div>
            {errors?.password_confirmation && (
              <span className={`text-danger ${styles.errormsg}`}>{errors.password_confirmation.message}</span>
            )}
          </div>
          <ReactTooltip id="pwd-validate-tooltip" place="right" content={pwdTooltipContent} />
        </div>
      </div>
    </>
  )
}

export default CompanyRegisterForm;