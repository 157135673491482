import { put, takeLatest, call, fork } from 'redux-saga/effects';
import { ActionTypes } from './actions';
import { getRequestAPI, postFileRequestAPI, postRequestAPI } from '../../../global/api';
import { defaultErrorMsg } from '../../../utils/conts';

function* getLatestOrderReq(action) {
  try {
    const { data: resData } = yield call(getRequestAPI, { url: "company/latest-order" });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.GET_LATEST_ORDER_SUCCESS, payload: resData.data });
    } else {
      yield put({ type: ActionTypes.GET_LATEST_ORDER_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_LATEST_ORDER_FAILURE, error: defaultErrorMsg });
  }
}

// calculate total & discounted price
function* calcCheckoutPriceReq(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'calculate-price', data: action.payload });

    if (resData?.success) {
      yield put({ type: ActionTypes.CALC_CHECKOUT_PRICE_SUCCESS, payload: resData.data });
    } else {
      yield put({ type: ActionTypes.CALC_CHECKOUT_PRICE_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.CALC_CHECKOUT_PRICE_FAILURE, error: defaultErrorMsg });

  }
}

// get all transaction history of company
function* getAllTransactionHistoryReq(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'company/get-transaction-history', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.GET_ALL_TRANSACTION_HISTORY_SUCCESS, payload: resData });
    } else {
      yield put({ type: ActionTypes.GET_ALL_TRANSACTION_HISTORY_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_ALL_TRANSACTION_HISTORY_FAILURE, error: defaultErrorMsg });
  }
}

function* getOrdersReq(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'company/get-order-history', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.GET_ORDERS_SUCCESS, payload: resData });
    } else {
      yield put({ type: ActionTypes.GET_ORDERS_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_ORDERS_FAILURE, error: defaultErrorMsg });
  }
}

function* getSitesByOrderReq(action) {
  try {
    const { data: resData } = yield call(getRequestAPI, {
      url: `company/get-order-details/${action.payload}`,
      data: action.payload
    });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.GET_SITES_BY_ORDER_SUCCESS, payload: resData.data });
    } else {
      yield put({ type: ActionTypes.GET_SITES_BY_ORDER_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_SITES_BY_ORDER_FAILURE, error: defaultErrorMsg });
  }
}

function* getPurchasedSiteUserReviewsReq(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, {
      url: `company/order-userreview-history`,
      data: action.payload
    });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.GET_PURCHASED_SITE_USER_REVIEWS_SUCCESS, payload: resData });
    } else {
      yield put({ type: ActionTypes.GET_PURCHASED_SITE_USER_REVIEWS_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_PURCHASED_SITE_USER_REVIEWS_FAILURE, error: defaultErrorMsg });
  }
}

function* UpdateCompanyProfileSaga(action) {
  try {
    const { data: resData } = yield call(postFileRequestAPI, {
      url: 'company/update-profile',
      data: action.payload
    });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.UPDATE_COMPANY_PROFILE_SUCCESS, data: resData });
    } else {
      yield put({ type: ActionTypes.UPDATE_COMPANY_PROFILE_FAILURE, error: resData.message });
    }
  } catch (err) {
    yield put({ type: ActionTypes.UPDATE_COMPANY_PROFILE_FAILURE, error: defaultErrorMsg });
  }
}

function* getCompanyDetailsSaga() {
  try {
    const { data: resData } = yield call(getRequestAPI, { url: 'company-profile' });

    if (resData.status === 'success') {
      yield put({ type: ActionTypes.GET_COMPANY_DETAILS_SUCCESS, data: resData });
    } else {
      yield put({ type: ActionTypes.GET_COMPANY_DETAILS_FAILURE, error: resData.message });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_COMPANY_DETAILS_FAILURE, error: defaultErrorMsg });
  }
}

function* paymentOrderReq(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'company/order', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.PAYMENT_ORDER_SUCCESS, payload: resData });
    } else {
      yield put({ type: ActionTypes.PAYMENT_ORDER_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.PAYMENT_ORDER_FAILURE, error: defaultErrorMsg });
  }
}

function* watchCompanyPanelSaga() {
  yield takeLatest(ActionTypes.GET_LATEST_ORDER, getLatestOrderReq);
  yield takeLatest(ActionTypes.CALC_CHECKOUT_PRICE, calcCheckoutPriceReq);
  yield takeLatest(ActionTypes.GET_ALL_TRANSACTION_HISTORY, getAllTransactionHistoryReq);
  yield takeLatest(ActionTypes.GET_ORDERS, getOrdersReq);
  yield takeLatest(ActionTypes.GET_SITES_BY_ORDER, getSitesByOrderReq);
  yield takeLatest(ActionTypes.GET_PURCHASED_SITE_USER_REVIEWS, getPurchasedSiteUserReviewsReq);
  yield takeLatest(ActionTypes.UPDATE_COMPANY_PROFILE_REQUEST, UpdateCompanyProfileSaga);
  yield takeLatest(ActionTypes.GET_COMPANY_DETAILS_REQUEST, getCompanyDetailsSaga);
  yield takeLatest(ActionTypes.PAYMENT_ORDER, paymentOrderReq);
}

function* CompanyPanelSaga() {
  yield fork(watchCompanyPanelSaga);
}

export default CompanyPanelSaga;