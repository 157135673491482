/**
 * @author      Nandani.V.Patel
 * @date        29 Oct 2024
 * @description ActionTypes and ActionCreator Function for Team-leader Company Reviewsite Request.
 * @param
 * @response      
**/

export const ActionTypes = {
  TEAM_LEADER_COMPANY_WISE_REVIEWSITE_REQUEST: 'TEAM_LEADER_COMPANY_WISE_REVIEWSITE_REQUEST',
  TEAM_LEADER_COMPANY_WISE_REVIEWSITE_SUCCESS: 'TEAM_LEADER_COMPANY_WISE_REVIEWSITE_SUCCESS',
  TEAM_LEADER_COMPANY_WISE_REVIEWSITE_FAILURE: 'TEAM_LEADER_COMPANY_WISE_REVIEWSITE_FAILURE',
};

export const getTeamleaderCompanyReviewsiteRequest = (paginationData) => {
  return {
    type: ActionTypes.TEAM_LEADER_COMPANY_WISE_REVIEWSITE_REQUEST,
    payload: paginationData,
  };
};