import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { validationRegex } from '../../utils/conts';
import { NotificationContainer } from '../notificationContainer';
import { notification } from '../notificationContainer/notification';
import { storeBankDetailsRequest } from '../../pages/Withdrawal/redux/actions';

const AddBankModal = ({ onClose, loader = false }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, resetField, formState: { errors }, } = useForm();
  const propState = useSelector((state) => ({
    successMsg: state.withdrawal.successMsg,
    errorMsg: state.withdrawal.errorMsg
  }));

  useEffect(() => {
    if (propState.successMsg) {
      notification(propState.successMsg, 'success');
      // dispatch({ type: WithdrawalActions.ActionTypes.STORE_TEAMLEADER_BANK_DETAILS_SUCCESS, bankdata: undefined });
      // dispatch(WithdrawalActions.getTeamleaderBankDetailsRequest({ page: 1, per_page: 10 }));
      resetField('bank_name');
      resetField('account_holder_name');
      resetField('account_number');
      resetField('ifsc');
      // onClose();
    }
    if (propState.errorMsg) {
      notification(propState.errorMsg, 'error');
    }
  }, [propState.errorMsg, propState.successMsg]);

  const onSubmit = (data) => {
    dispatch(storeBankDetailsRequest(data));
  };

  return (
    <>
      <div
        className="add-bank-modal d-block modal fade show modal-lg"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header" style={{ backgroundColor: 'var(--theme-blue-color)' }}>
              <h5 className="modal-title" id="exampleModalLabel" style={{ color: 'white' }}>
                Add New Bank
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                onClick={onClose}
                aria-label="Close"
              />
            </div>
            <div className="modal-body" style={{ paddingBottom: '0px' }}>
              <div className="details-form-content pt-0 pb-0 mb-0">
                <div className="form-content" style={{ padding: '20px' }}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label>Bank Name</label>
                        <input
                          type="text"
                          {...register("bank_name", {
                            required: 'Bank Name is required.',
                            validate: {
                              hasNoNumbers: (value) => validationRegex.nameHasNoNum.test(value) || "Bank Name cannot contain numbers.",
                              hasNoSpecialChars: (value) => validationRegex.nameHasNoSpclChar.test(value) || "Bank Name cannot contain special characters.",
                            },
                            minLength: {
                              value: 2,
                              message: 'Bank Name too short.',
                            },
                            maxLength: {
                              value: 50,
                              message: 'Bank Name too long.',
                            },
                          })}
                          className="form-control"
                          placeholder="Bank Name"
                        />
                        {errors?.bank_name && <span className="text-danger">{errors.bank_name.message}</span>}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label>Account Holder Name</label>
                        <input
                          type="text"
                          {...register("account_holder_name", {
                            required: 'Account Holder Name is required.',
                            validate: {
                              hasNoNumbers: (value) => validationRegex.nameHasNoNum.test(value) || "Account Holder Name cannot contain numbers.",
                              hasNoSpecialChars: (value) => validationRegex.nameHasNoSpclChar.test(value) || "Account Holder Name cannot contain special characters.",
                            },
                            minLength: {
                              value: 2,
                              message: 'Account Holder Name too short.',
                            },
                            maxLength: {
                              value: 50,
                              message: 'Account Holder Name too long.',
                            },
                          })}
                          className="form-control"
                          placeholder="Account Holder Name"
                        />
                        {errors?.account_holder_name && <span className="text-danger">{errors.account_holder_name.message}</span>}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label>Account Number</label>
                        <input
                          type="text"
                          {...register("account_number", {
                            required: 'Account Number is required.',
                            pattern: {
                              value: /^[0-9]{11,16}$/,
                              message: 'Account Number must be numeric and between 11 and 16 digits.'
                            },
                            minLength: {
                              value: 11,
                              message: 'Account Number must be at least 11 digits.'
                            },
                            maxLength: {
                              value: 16,
                              message: 'Account Number cannot exceed 16 digits.'
                            }
                          })}
                          className="form-control"
                          placeholder="Account Number"
                        />
                        {errors?.account_number && (<span className="text-danger">{errors.account_number.message}</span>)}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label>IFSC Code</label>
                        <input
                          type="text"
                          {...register("ifsc", {
                            required: 'IFSC Code is required.',
                            pattern: {
                              value: /^[A-Z]{4}[0-9]{7}$/,
                              message: 'IFSC Code must be 4 letters followed by 7 digits.'
                            }
                          })}
                          className="form-control"
                          placeholder="IFSC Code"
                        />
                        {errors?.ifsc && (<span className="text-danger">{errors.ifsc.message}</span>)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="rounded blue-btn"
                data-bs-dismiss="modal"
                onClick={handleSubmit(onSubmit)}
                disabled={loader}
              >
                {loader ? (<span className="spinner-border spinner-border-sm"></span>) : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" onClick={onClose}></div>
      <NotificationContainer />
    </>
  )
}
export default AddBankModal;