import React from 'react';

/**
 * @author      Nandani.V.Patel
 * @date        07 th Oct 2024
 * @description UI Implementation of Withdrawal Modal
 * @param
 * @response 
**/

const ImageModal = ({ onClose, imageSrc }) => {
  return (
    <>
      <div
        className="modal fade show d-block modal-lg"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header" style={{ backgroundColor: 'var(--theme-blue-color)' }}>
              <h5 className="modal-title" id="exampleModalLabel" style={{ color: 'white' }}>
                Reason
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                onClick={onClose}
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="details-form-content pt-0 pb-0 mb-0">
                <div className="form-content" style={{ padding: '10px' }}>
                  <div className="row">
                    <div className="col-lg-12">
                      <img src={imageSrc} alt="Reason" style={{ width: '100%' }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" onClick={onClose}></div>
    </>
  )
}
export default ImageModal;