export const ActionTypes = {
  GET_LATEST_ORDER: 'GET_LATEST_ORDER',
  GET_LATEST_ORDER_SUCCESS: 'GET_LATEST_ORDER_SUCCESS',
  GET_LATEST_ORDER_FAILURE: 'GET_LATEST_ORDER_FAILURE',

  CALC_CHECKOUT_PRICE: 'CALC_CHECKOUT_PRICE',
  CALC_CHECKOUT_PRICE_SUCCESS: 'CALC_CHECKOUT_PRICE_SUCCESS',
  CALC_CHECKOUT_PRICE_FAILURE: 'CALC_CHECKOUT_PRICE_FAILURE',

  GET_ALL_TRANSACTION_HISTORY: 'GET_ALL_TRANSACTION_HISTORY',
  GET_ALL_TRANSACTION_HISTORY_SUCCESS: 'GET_ALL_TRANSACTION_HISTORY_SUCCESS',
  GET_ALL_TRANSACTION_HISTORY_FAILURE: 'GET_ALL_TRANSACTION_HISTORY_FAILURE',

  GET_ORDERS: 'GET_ORDERS',
  GET_ORDERS_SUCCESS: 'GET_ORDERS_SUCCESS',
  GET_ORDERS_FAILURE: 'GET_ORDERS_FAILURE',

  GET_SITES_BY_ORDER: 'GET_SITES_BY_ORDER',
  GET_SITES_BY_ORDER_SUCCESS: 'GET_SITES_BY_ORDER_SUCCESS',
  GET_SITES_BY_ORDER_FAILURE: 'GET_SITES_BY_ORDER_FAILURE',
  
  GET_PURCHASED_SITE_USER_REVIEWS: 'GET_PURCHASED_SITE_USER_REVIEWS',
  GET_PURCHASED_SITE_USER_REVIEWS_SUCCESS: 'GET_PURCHASED_SITE_USER_REVIEWS_SUCCESS',
  GET_PURCHASED_SITE_USER_REVIEWS_FAILURE: 'GET_PURCHASED_SITE_USER_REVIEWS_FAILURE',

  UPDATE_COMPANY_PROFILE_REQUEST: 'UPDATE_COMPANY_PROFILE_REQUEST',
  UPDATE_COMPANY_PROFILE_SUCCESS: 'UPDATE_COMPANY_PROFILE_SUCCESS',
  UPDATE_COMPANY_PROFILE_FAILURE: 'UPDATE_COMPANY_PROFILE_FAILURE',

  GET_COMPANY_DETAILS_REQUEST: 'GET_COMPANY_DETAILS_REQUEST',
  GET_COMPANY_DETAILS_SUCCESS: 'GET_COMPANY_DETAILS_SUCCESS',
  GET_COMPANY_DETAILS_FAILURE: 'GET_COMPANY_DETAILS_FAILURE',
  
  PAYMENT_ORDER: 'PAYMENT_ORDER',
  PAYMENT_ORDER_SUCCESS: 'PAYMENT_ORDER_SUCCESS',
  PAYMENT_ORDER_FAILURE: 'PAYMENT_ORDER_FAILURE',
};

export const getLatestOrder = () => {
  return {
    type: ActionTypes.GET_LATEST_ORDER
  };
}

export const calcCheckoutPrice = (payload) => {
  return {
    type: ActionTypes.CALC_CHECKOUT_PRICE,
    payload
  };
}

export const getAllTransactionHistory = (payload) => {
  return {
    type: ActionTypes.GET_ALL_TRANSACTION_HISTORY,
    payload
  };
}

export const getOrders = (payload) => {
  return {
    type: ActionTypes.GET_ORDERS,
    payload
  };
}

export const getSitesByOrder = (payload) => {
  return {
    type: ActionTypes.GET_SITES_BY_ORDER,
    payload
  };
}

export const getPurchasedSiteUserReviews = (payload) => {
  return {
    type: ActionTypes.GET_PURCHASED_SITE_USER_REVIEWS,
    payload
  };
}

export const updateCompanyProfile = (companyProfileData) => {
  return {
    type: ActionTypes.UPDATE_COMPANY_PROFILE_REQUEST,
    payload: companyProfileData,
  };
};

export const getCompanyDetailsRequest = () => {
  return {
    type: ActionTypes.GET_COMPANY_DETAILS_REQUEST
  };
};

export const paymentOrder = (payload) => {
  return {
    type: ActionTypes.PAYMENT_ORDER,
    payload
  };
}