import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Breadcrumb from '../../../component/breadcrumbs';
import { EMPCOMPANIES } from '../../../global/routes';
import { clearStates, getCompanyTargetHistory } from '../redux/actions';

import styles from "../styles.module.scss";

const CompanyTargetsHistory = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const propState = useSelector(({ employeePanel }) => ({
    companyTargetHistory: employeePanel.companyTargetHistory,
    loading: employeePanel.loading
  }));
  const targetHistoryData = propState.companyTargetHistory?.data || [];

  const companyDetails = () => {
    const { company_tbl_name, created_at } = targetHistoryData?.[0] || {};
    const completedCount =
      targetHistoryData.filter(
        (item) => item.target_status_label.toLowerCase() === "completed"
      ).length || 0;

    return {
      name: company_tbl_name || state?.company_name,
      date: created_at,
      total_targets: targetHistoryData?.length || 0,
      target_completion: (completedCount / targetHistoryData?.length) * 100
    };
  };

  const breadcrumbs = [
    { name: 'Company List', link: EMPCOMPANIES },
    { name: 'Target History', link: "" },
  ];

  useEffect(() => {
    return () => {
      dispatch(clearStates());
    }
  }, []);

  useEffect(() => {
    const payload = {
      company_id: state?.company_id,
      per_page: rowsPerPage,
      page: currentPage
    };

    dispatch(getCompanyTargetHistory(payload));
  }, [dispatch, rowsPerPage, currentPage]);

  const customStyles = {
    headCells: {
      style: {
        paddingTop: '18px',
        paddingBottom: '18px',
      }
    },
    cells: {
      style: {
        paddingTop: '10px',
        paddingBottom: '10px',
      }
    }
  };

  const columns = [
    {
      name: "Target",
      selector: (row) => row.id,
      cell: (row) => `Target ${row.id}`,
      sortable: true,
    },
    {
      name: "Assigned Target",
      selector: (row) => row.assigned_target,
      cell: (row) => row.assigned_target || 0,
      sortable: true,
    },
    {
      name: "Completed Count",
      selector: (row) => row.completed_count,
      cell: (row) => row.completed_count || 0,
      sortable: true,
    },
    {
      name: "Completion Rate",
      selector: (row) => row.completion_rate,
      cell: (row) => `${row.completion_rate}%`,
      sortable: true,
    },
    {
      name: "Assigned Date",
      selector: (row) => row.created_at,
      cell: (row) => new Date(row.created_at).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
      }),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => (
        <div className="btn-area">
          <span
            className="status-btn"
            data-review-status={
              row?.target_status_label.toLowerCase() === "completed"
                ? "approved"
                : row?.target_status_label.toLowerCase()
            }
          >
            {row?.target_status_label}
          </span>
        </div>
      ),
      sortable: false,
      maxWidth: "150px",
      right: "yes"
    }
  ];

  // Pagination event handlers
  const handlePageChange = (page) => setCurrentPage(page);
  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); // Reset to first page when rows per page changes
  };

  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div className="card shadow-sm border-0 mb-3">
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col-md-4 mb-3 mb-md-0">
              <h3
                className="card-title mb-0 text-primary pe-3"
              >
                {companyDetails().name}
              </h3>
            </div>

            <div className="col-md-4 mb-3 mb-md-0">
              <h6 className="text-muted mb-1">Total Targets Assigned</h6>
              <p className="fw-bold">{companyDetails().total_targets} Targets</p>
              <div className="progress" style={{ height: "8px" }}>
                <div
                  className="progress-bar bg-success"
                  role="progressbar"
                  style={{ width: `${companyDetails().target_completion}%` }}
                  aria-valuenow={companyDetails().target_completion}
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <small className="text-muted">{companyDetails().target_completion || 0}% Completion</small>
            </div>
            {/* Circular Progress */}
            {/* <div className="col-md-4 mb-3 mb-md-0 text-center">
              <h6 class="text-muted mb-1">Total Targets Assigned</h6>
              <div class="position-relative d-inline-block">
                <div class="progress-circle position-relative" style={{width: "80px", height: "80px"}}>
                  <svg viewBox="0 0 36 36" className={styles.circularChart} xmlns="http://www.w3.org/2000/svg">
                    <path className={styles.circleBg} d="M18 2.0845
                      a 15.9155 15.9155 0 0 1 0 31.831
                      a 15.9155 15.9155 0 0 1 0 -31.831" />
                    <path className={styles.circle} strokeDasharray="80, 100" d="M18 2.0845
                      a 15.9155 15.9155 0 0 1 0 31.831
                      a 15.9155 15.9155 0 0 1 0 -31.831" />
                  </svg>
                  <span class="position-absolute top-50 start-50 translate-middle fw-bold text-success">80%</span>
                </div>
                <p class="mt-2 mb-0 fw-bold">50 Targets</p>
              </div>
            </div> */}

            <div className="col-md-4 text-md-end">
              <h6 className="text-muted mb-1">Last Target Assigned</h6>
              <p className="mb-0">
                <i className="fa fa-calendar-alt me-1"></i>
                {companyDetails().date
                  ? new Date(companyDetails().date).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                    })
                  : "No Date"
                }
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={`d-flex justify-content-between align-items-center p-3 mb-4 header-box`}>
        <h4 className={`header-text`}>
          Target History
        </h4>
      </div>
      <DataTable
        columns={columns}
        data={targetHistoryData || []}
        pagination
        paginationServer
        paginationTotalRows={propState.companyTargetHistory?.total || 0}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        paginationComponentOptions={{
          rowsPerPageText: "Orders per page:",
        }}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        customStyles={customStyles}
        progressPending={propState.loading}
        fixedHeader
        responsive
      />
    </>
  )
}

export default CompanyTargetsHistory;