/**
 * @author      Nandani.V.Patel
 * @date        17 Oct 2024
 * @description ActionTypes and ActionCreator Function for Coampny Details Request.
 * @param
 * @response      
**/

export const ActionTypes = {
  COMPANY_DETAILS_REQUEST: 'COMPANY_DETAILS_REQUEST',
  COMPANY_DETAILS_SUCCESS: 'COMPANY_DETAILS_SUCCESS',
  COMPANY_DETAILS_FAILURE: 'COMPANY_DETAILS_FAILURE',

  GET_COMPANY_PURCHASED_SITES: 'GET_COMPANY_PURCHASED_SITES',
  GET_COMPANY_PURCHASED_SITES_SUCCESS: 'GET_COMPANY_PURCHASED_SITES_SUCCESS',
  GET_COMPANY_PURCHASED_SITES_FAILURE: 'GET_COMPANY_PURCHASED_SITES_FAILURE',
};

export const companydetailsRequest = (company_id) => {
  return {
    type: ActionTypes.COMPANY_DETAILS_REQUEST,
    payload: company_id,
  };
};

export const getCompanyPurchasedSites = (company_id) => {
  return {
    type: ActionTypes.GET_COMPANY_PURCHASED_SITES,
    payload: company_id,
  };
};
