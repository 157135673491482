import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { getAllTransactionHistory } from '../redux/actions';

import styles from '../styles.module.scss';

const TransactionHistory = () => {
  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const dispatch = useDispatch();
  const propState = useSelector(({ companyPanel }) => ({
    transactionHistory: companyPanel.transactionHistory,
    loading: companyPanel.loading
  }));

  useEffect(() => {
    const payload = {
      per_page: rowsPerPage,
      page: currentPage
    };
    dispatch(getAllTransactionHistory(payload));
  }, [dispatch, rowsPerPage, currentPage]);

  const columns = [
    {
      name: 'Sr.',
      selector: (row, index) => (currentPage - 1) * rowsPerPage + index + 1,
      width: '60px'
    },
    {
      name: 'Transaction id',
      selector: (row) => row.payment_id,
      sortable: true
    },
    {
      name: "Reviews Count",
      sortable: true
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true
    },
    {
      name: "Date",
      selector: (row) => row.created_at,
      cell: (row) => new Date(row.created_at).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
      }),
      sortable: true
    },
    {
      name: "Status",
      // selector: (row) => row.status,
      sortable: true,
      right: true
    }
  ];

  const customStyles = {
    headCells: {
      style: {
        verticalAlign: 'middle',
        whiteSpace: 'nowrap',
        padding: '18px 10px',
        fontSize: '18px',
        fontWeight: '600'
      }
    },
    cells: {
      style: {
        verticalAlign: "middle",
        padding: "25px 15px",
        fontWeight: "500",
        fontSize: "16px"
      }
    }
  };

  // Pagination event handlers
  const handlePageChange = (page) => setCurrentPage(page);
  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); // Reset to first page when rows per page changes
  };

  return (
    <>
      <div className={`p-3 mb-4 header-box`}>
        <h4 className="header-text">Transaction History</h4>
      </div>
      <div className="package-box">
        <div className="manage-resum-table table-responsive">
          <DataTable
            columns={columns}
            data={propState.transactionHistory?.data || []}
            customStyles={customStyles}
            progressPending={propState.loading}
            fixedHeader
            responsive
            pagination
            paginationServer
            paginationTotalRows={propState.transactionHistory?.total || 0}
            paginationRowsPerPageOptions={[10, 25, 50, 100]}
            paginationComponentOptions={{
              rowsPerPageText: "Orders per page:",
            }}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
          />
        </div>
      </div>
    </>
  )
}
export default TransactionHistory;
