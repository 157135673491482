import { ActionTypes } from './profileActions';

/**
 * @author      Nandani.V.Patel
 * @date        10 Oct 2024
 * @description User Profile Reducer.
 * @param       takes default and previous state value
 * @response    update a state value and store user Profile api response  
**/

const defaultState = {
  isprofile: false,
  data: undefined,
  userdata: undefined,
  isUserDetails: false,
};

const userProfileReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_USER_PROFILE_REQUEST:
      return {
        ...state,
        data: undefined,
        isprofile: true,
      };
    case ActionTypes.UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        data: action.data,
        isprofile: false,
      };
    case ActionTypes.UPDATE_USER_PROFILE_FAILURE:
      return {
        ...state,
        isprofile: false,
        data: undefined,
        error: action.error,
      };
    case ActionTypes.GET_USER_DETAILS_REQUEST:
      return {
        ...state,
        isUserDetails: true,
      };
    case ActionTypes.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userdata: action.data,
        isUserDetails: false,
      };
    case ActionTypes.GET_USER_DETAILS_FAILURE:
      return {
        ...state,
        isUserDetails: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default userProfileReducer;
