import React, { useState, useEffect } from 'react';
import {
  SIGNIN,
  HOME,
  WORKFLOW,
  CATEGORYLIST,
  COMPANYREGISTER,
  PROFILE,
} from '../../global/routes';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as profileActions from '../../pages/profile/profileActions';
import * as companyprofileActions from "../../pages/companyPanel/redux/actions";
import { tokenkey, encryptStorage, roleId } from "../../utils/auth";
/**
 * @author      Nandani.V.Patel
 * @date        8th Oct 2024
 * @description UI Implementation of Dashboard Navbar
 * @param
 * @response 
**/

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [userprofile, setUserProfile] = useState('/assets/images/dashboard/dashboard-img-5.jpg');
  const [firstInit, setFirstInit] = useState(false);
  const [showImage, setshowImage] = useState(false);

  let token;
  if (localStorage.getItem(tokenkey) !== null && localStorage.getItem(tokenkey) !== '' && localStorage.getItem(tokenkey) !== 'undefined') {
    token = localStorage.getItem(tokenkey);
  }

  const userRoleId = encryptStorage.getItem(roleId);

  useEffect(() => {
    if (firstInit && (location.pathname !== PROFILE) && token) {
      dispatch(profileActions.getUserDetailsRequest());
      if (userRoleId === 3) {
        dispatch(companyprofileActions.getCompanyDetailsRequest());
      }
      setFirstInit(false);
    }
  }, [firstInit, userRoleId]);

  useEffect(() => {
    setFirstInit(true);
  }, []);

  const profile = useSelector((state) => state?.profile);
  const companyProfile = useSelector((state) => state?.companyPanel);

  useEffect(() => {
    if (typeof profile?.userdata !== 'undefined' && !profile?.isUserDetails && profile?.userdata?.status === 'success') {
      // setUserProfile(profile?.userdata?.data?.user_image);
      if (profile?.userdata?.data?.user_image) {
        setUserProfile(profile?.userdata?.data?.user_image);
        setshowImage(true);
      } else {
        setUserProfile('/assets/images/dashboard/dashboard-img-5.jpg'); // Reset if no user image
        setshowImage(true);
      }
      setshowImage(true);
    }
  }, [profile?.userdata, profile?.isUserDetails, showImage]);

  useEffect(() => {
    if (typeof companyProfile?.companydata !== 'undefined' && !companyProfile?.isCompanyDetails && companyProfile?.companydata?.status === 'success') {
      if (companyProfile?.companydata?.data?.company_tbl_logo) {
        setUserProfile(companyProfile?.companydata?.data?.company_tbl_logo);
        setshowImage(true);
      } else {
        setUserProfile('/assets/images/dashboard/dashboard-img-5.jpg'); // Reset if no user image
        setshowImage(true);
      }
      setshowImage(true);
    }
  }, [companyProfile?.companydata, companyProfile?.isCompanyDetails, showImage]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="navbar-container">
      <div className="bg-f0f4fc navbar-bg" style={{ position: 'fixed', top: '0', width: '100%', zIndex: '1000', padding: '1rem', }}>

        {/* Mobile Navigation */}
        <div className="mobile-responsive-nav">
          <div className="container">
            <div className="mobile-responsive-menu">
              <div className="logo">
                <Link to={HOME}>
                  <img src="/assets/images/logo.png" className="main-logo" alt="logo" />
                  <img src="/assets/images/white-logo.png" className="white-logo" alt="logo" />
                </Link>
              </div>
              <div className="menu-toggle" onClick={toggleMenu}>
                {/* <span className="menu-icon">...</span> */}
                <span className="menu-icon">
                  <div className="responsive-burger-menu d-block">
                    {!isMenuOpen ? (
                      <i className="fa-solid fa-bars-staggered" />
                    ) : (
                      <i className="fa-solid fa-xmark" />
                    )}
                  </div>
                </span>
              </div>
            </div>
          </div>

          <div className={`mobile-menu ${isMenuOpen ? 'open' : ''}`}>
            <ul className="navbar-nav">
              {!token && (
                <>
                  <li className="nav-item"><Link to={HOME}>Home</Link></li>
                  <li className="nav-item"><Link to={CATEGORYLIST}>Categories</Link></li>
                  <li className="nav-item"><Link to={WORKFLOW}>How it Works</Link></li>
                </>
              )}
              {/* Conditional Rendering based on Token and UserRoleId */}
              {!token ? (
                <>
                  <li className="nav-item"><Link to={SIGNIN}>Login</Link></li>
                  {userRoleId !== 3 && (
                    <li className="nav-item"><Link to={COMPANYREGISTER}>Company Register</Link></li>
                  )}
                </>
              ) : (
                <>
                  {userRoleId !== 3 && (
                    <li className="nav-item mb-2"><Link to={COMPANYREGISTER}>Company Register</Link></li>
                  )}
                  <li className="nav-item">
                    <div className="dropdown profile-nav-item">
                      <a className="dropdown-bs-toggle" role="button">
                        <div className="menu-profile" style={{ cursor: 'default' }}>
                          {userprofile && (
                            <img src={userprofile} alt="profile" className="rounded-circle" style={{ height: '50px', width: '50px' }} />
                          )}
                        </div>
                      </a>
                    </div>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>

        {/* Desktop Navigation */}
        <div className="desktop-nav">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-md navbar-light">
              <Link className="navbar-brand" to={HOME}>
                <img src="/assets/images/logo.png" className="main-logo" alt="logo" />
                <img src="/assets/images/white-logo.png" className="white-logo" alt="logo" />
              </Link>
              <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto">
                  {!token && (
                    <>
                      <li className="nav-item"><Link to={HOME} className="nav-link">Home</Link></li>
                      <li className="nav-item"><Link to={CATEGORYLIST} className="nav-link">Categories</Link></li>
                      <li className="nav-item"><Link to={WORKFLOW} className="nav-link">How it Works</Link></li>
                    </>
                  )}
                </ul>
                <div className="others-options" style={{ display: 'flex', alignItems: 'center' }}>
                  {!token ? (
                    <>
                      <div className="option-item">
                        <Link to={SIGNIN} className="default-btn style-2">Login</Link>
                      </div>
                      {userRoleId !== 3 &&
                        (<div className="option-item" style={{ marginRight: '20px' }}>
                          <Link to={COMPANYREGISTER} className="default-btn style-2">Company Registration</Link>
                        </div>
                        )}
                    </>
                  ) : (
                    <>
                      <div className="option-item" style={{ marginRight: '20px' }}>
                        <div className="dropdown profile-nav-item">
                          <a
                            className="dropdown-bs-toggle"
                            role="button"
                          >
                            <div className="menu-profile" style={{ cursor: 'default' }}>
                              {userprofile && (
                                <img
                                  src={userprofile}
                                  alt="image"
                                  className="rounded-circle"
                                />
                              )}
                            </div>
                          </a>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar;
