import { ActionTypes } from './companyDetailsActions';

/**
 * @author      Nandani.V.Patel
 * @date        17 Oct 2024
 * @description company details Reducer.
 * @param       takes default and previous state value
 * @response    update a state value and store company details api response  
**/

const defaultState = {
  purchasedSites: {},
  siteListLoader: false,
  isCompanyDetails: false,
};

const comapnyDetailsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.COMPANY_DETAILS_REQUEST:
      return {
        ...state,
        isCompanyDetails: true,
      };
    case ActionTypes.COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.data,
        isCompanyDetails: false,
      };
    case ActionTypes.COMPANY_DETAILS_FAILURE:
      return {
        ...state,
        isCompanyDetails: false,
        error: action.error,
      };
    case ActionTypes.GET_COMPANY_PURCHASED_SITES:
      return {
        ...state,
        siteListLoader: true,
      }
    case ActionTypes.GET_COMPANY_PURCHASED_SITES_SUCCESS:
      return {
        ...state,
        purchasedSites: action.data,
        siteListLoader: false,
      }
    case ActionTypes.GET_COMPANY_PURCHASED_SITES_FAILURE:
      return {
        ...state,
        siteListLoader: false,
        error: action.error,
      }
    default:
      return state;
  }
}

export default comapnyDetailsReducer;