import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as CompanylistingActions from './companylistActions';
import * as CategoryListActions from '../categorylist/categorylistActions';
import debounce from 'debounce';
import Preloader from '../../component/Preloader';
import { COMPANYDETAILS } from '../../global/routes';

import styles from './styles.module.scss';

/**
 * @author      Nandani.V.Patel
 * @date        10th Oct 2024
 * @description UI Implementation of Company-listing
 * @param
 * @response 
**/

const CompanyList = (props) => {
  const { companylist, pendingTargetCompanies, categories, websites, actions } = props;

  const dispatch = useDispatch();
  const location = useParams();
  const navigate = useNavigate();

  const [firstInit, setFirstInit] = useState(false);
  const [search, setSearch] = useState('');
  const [perPage, setPerPage] = useState(12);
  const [page, setPage] = useState(1);
  const [selectedWebsites, setSelectedWebsites] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [searchWebsiteInput, setSearchWebsiteInput] = useState('');
  const [loading, setLoading] = useState(companylist.isCompanyListing || companylist.loading);

  const website_id = location.id ? parseInt(location.id.split('=')[1], 10) : null;
  const category_id = location.id ? parseInt(location.id, 10) : null;

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (category_id) {
      setSelectedCategories([category_id]);
    } else {
      setSelectedCategories([]);
    }
  }, [category_id]);

  useEffect(() => {
    if (website_id) {
      setSelectedWebsites([website_id]);
    } else {
      setSelectedWebsites([]);
    }
  }, [website_id]);

  useEffect(() => {
    setFirstInit(true);
  }, [page, perPage, search, selectedCategories, selectedWebsites]);

  useEffect(() => {
    if (firstInit) {
      // actions.companylistingRequest({ page, perPage, search, selectedCategories, selectedWebsites });
      actions.getPendingTargetCompanies({ page, perPage, search, selectedCategories, selectedWebsites })
      actions.getwebsiteRequest();
      dispatch(CategoryListActions.getCategorylistRequest());

      setFirstInit(false);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [firstInit, page, perPage, search, selectedCategories, selectedWebsites]);

  // Calculate the display range
  const startResult = (page - 1) * perPage + 1;
  const endResult = Math.min(startResult + perPage - 1, pendingTargetCompanies?.to || 0);
  const totalResults = pendingTargetCompanies?.total || 0;
  const totalPages = Math.ceil(totalResults / perPage); // Default to 1 if undefined

  // Generate the page numbers to display
  const renderPageNumbers = () => {
    const pages = [];
    const currentPage = page; // Current active page

    // Edge case: if totalPages is less than or equal to 1
    if (totalPages <= 1) {
      pages.push(
        <li key={1}>
          <a
            // className={currentPage === 1 ? 'active' : ''}
            className={`${styles.hoverStyle} ${currentPage === 1 ? 'active' : ''}`}
          >
            1
          </a>
        </li>
      );
      return pages; // Only one page, return early
    }

    // Always show first page
    pages.push(
      <li key={1}>
        <a
          className={`${styles.hoverStyle} ${currentPage === 1 ? 'active' : ''}`}
          onClick={(e) => {
            e.preventDefault();
            setPage(1);
          }}
        >
          1
        </a>
      </li>
    );

    // Handle page numbers and ellipsis
    if (totalPages <= 5) {
      // Show all pages if totalPages is 5 or less
      for (let i = 2; i <= totalPages; i++) {
        pages.push(
          <li key={i}>
            <a
              className={`${styles.hoverStyle} ${i === currentPage ? 'active' : ''}`}
              onClick={(e) => {
                e.preventDefault();
                setPage(i);
              }}
            >
              {i}
            </a>
          </li>
        );
      }
    } else {
      // More than 5 pages
      const showEllipsisLeft = currentPage > 3; // Show ellipsis if current page is greater than 3
      const showEllipsisRight = currentPage < totalPages - 2; // Show ellipsis if current page is less than totalPages - 2

      if (showEllipsisLeft) {
        pages.push(<li key="left-ellipsis">...</li>);
      }

      // Display pages around the current one
      const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(totalPages - 1, currentPage + 1);

      for (let i = startPage; i <= endPage; i++) {
        pages.push(
          <li key={i}>
            <a
              className={`${styles.hoverStyle} ${i === currentPage ? 'active' : ''}`}
              onClick={(e) => {
                e.preventDefault();
                setPage(i);
              }}
            >
              {i}
            </a>
          </li>
        );
      }

      if (showEllipsisRight) {
        pages.push(<li key="right-ellipsis">...</li>);
      }

      // Last page
      pages.push(
        <li key={totalPages}>
          <a
            className={`${styles.hoverStyle} ${currentPage === totalPages ? 'active' : ''}`}
            onClick={(e) => {
              e.preventDefault();
              setPage(totalPages);
            }}
          >
            {totalPages}
          </a>
        </li>
      );
    }
    return pages;
  };

  const handleNextPage = () => {
    if (pendingTargetCompanies?.to < pendingTargetCompanies?.total) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  // set selected categories for company listing
  const handleCategoryCheckboxChange = (categoryId) => {
    setSelectedCategories((prevSelected) => {
      if (prevSelected.includes(categoryId)) {
        return prevSelected.filter((id) => id !== categoryId);
      } else {
        return [...prevSelected, categoryId];
      }
    });
  };

  // filter categories according to input text
  const filteredCategories = searchInput
    ? categories?.data?.filter(category =>
      category?.category_name?.toLowerCase()?.includes(searchInput?.toLowerCase())
    )
    : categories?.data;

  // set selected websites for company listing
  const handleCheckboxChange = (websiteId) => {
    setSelectedWebsites((prevSelected) => {
      if (prevSelected.includes(websiteId)) {
        return prevSelected.filter((id) => id !== websiteId);
      } else {
        return [...prevSelected, websiteId];
      }
    });
  };

  // filter review sites according to input text
  const filteredWebsites = searchWebsiteInput
    ? websites.filter((website) =>
      website?.review_site_tbl_name?.toLowerCase()?.includes(searchWebsiteInput?.toLowerCase())
    )
    : websites;

  const onSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const debouncedSearchChange = debounce(onSearchChange, 1000);

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div className="candidate-listing-area pt-100 pb-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <div className="sidebar">
                  <div className="single-sidebar-widget keyword">
                    <h3>Search By Keyword</h3>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Company name"
                        onChange={debouncedSearchChange}
                      />
                    </div>
                  </div>
                  <div className={`single-sidebar-widget location-style2 ${styles.companysidebar}`}>
                    <h3>Categories</h3>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Search by categories"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e?.target?.value)}
                      />
                    </div>
                    <div style={{ maxHeight: "150px", overflowY: "auto" }}>
                      {filteredCategories && filteredCategories?.length !== 0 ? (
                        filteredCategories?.map((category) => (
                          <div key={`${category?.category_name}-${category?.id}`} className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              checked={selectedCategories?.includes(category?.id)}
                              onChange={() => handleCategoryCheckboxChange(category?.id)}
                            />
                            <label className="form-check-label">
                              {category?.category_name}
                            </label>
                          </div>
                        ))
                      ) : (
                        <p>No categories available.</p>
                      )}
                    </div>
                  </div>
                  <div className={`single-sidebar-widget location-style2 ${styles.companysidebar}`}>
                    <h3>Review Website</h3>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Search by review site"
                        value={searchWebsiteInput}
                        onChange={(e) => setSearchWebsiteInput(e.target.value)}
                      />
                    </div>
                    <div style={{ maxHeight: "150px", overflowY: "auto" }}>
                      {filteredWebsites && filteredWebsites?.length !== 0 ? (
                        filteredWebsites?.map((website) => (
                          <div key={`${website?.review_site_tbl_name}-${website?.id}`} className="form-check">
                            <input
                              type="checkbox"
                              checked={selectedWebsites?.includes(website?.id)}
                              onChange={() => handleCheckboxChange(website?.id)}
                              className="form-check-input"
                            />
                            <label className="form-check-label">
                              {website?.review_site_tbl_name}
                            </label>
                          </div>
                        ))
                      ) : (
                        <p>No Website available.</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="search-job-top-content">
                  <div className="row align-items-center">
                    <div className="col-lg-6 col-md-4">
                      <div className="shoing-content">
                        <span>
                          Showing {startResult} – {endResult} of {totalResults} results
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {pendingTargetCompanies?.data?.length > 0 && pendingTargetCompanies.data.map((company) => (
                    <div
                      key={`company-${company?.id}`}
                      className="col-lg-4 col-md-6 col-sm-12 mb-4"
                    >
                      <div
                        className="single-company-card"
                        onClick={() => navigate(`${COMPANYDETAILS}/${company?.id}`)}
                      >
                        <div className="company-content">
                          <div className={`icon ${styles.icons}`}>
                            <img src={company?.company_tbl_logo} alt="company-logo" />
                          </div>
                          <h3>{company?.company_tbl_name}</h3>
                          <span><i className="flaticon-location"></i>{company?.country_name}</span>
                          <h4>{company?.category_name}</h4>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {/* Custom Pagination UI */}
                {totalResults > 0 && (
                  <div className="paginations">
                    <ul>
                      {/* Previous Page */}
                      <li>
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            handlePreviousPage();
                          }}
                          className={(page === 1) ? 'disabled' : ''}
                        >
                          <i className="fa-solid fa-angle-left"></i>
                        </a>
                      </li>
                      {/* Page Numbers with Ellipsis */}
                      {renderPageNumbers()}
                      {/* Next Page */}
                      <li>
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            handleNextPage();
                          }}
                          className={page === totalPages ? 'disabled' : ''}
                        >
                          <i className="fa-solid fa-angle-right"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
const mapStateToProps = (state) => ({
  companylist: state.companylist,
  pendingTargetCompanies: state.companylist.pendingTargetCompanies,
  websites: state.companylist.websites,
  isCategory: state.categorylist.isCategory,
  categories: state.categorylist?.data,
  siteLoading: state.companylist.siteLoading,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(CompanylistingActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyList);
