import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import StatsBlock from '../../dashboard/statsBlock';

const EarningsOverview = () => {
  const dispatch = useDispatch();
  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const propState = useSelector(({ employeePanel }) => ({
    // empTargets: employeePanel.empTargets,
    loading: employeePanel.loading
  }));

  // Pagination event handlers
  const handlePageChange = (page) => setCurrentPage(page);
  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); // Reset to first page when rows per page changes
  };

  useEffect(() => {
    const payload = {
      per_page: rowsPerPage,
      page: currentPage
    };

    // dispatch(getEmpTargetHistory(payload));
  }, [dispatch, rowsPerPage, currentPage]);

  const columns = [
    {
      name: "Comapny Name",
      selector: (row) => row?.company_name,
      cell: (row) => row?.company_name,
      sortable: true,
    },
    {
      name: "Per Review Earning",
      selector: (row) => row?.total_assigned_targets,
      cell: (row) => row?.total_assigned_targets,
      sortable: false,
    },
    {
      name: "Completed Targets",
      selector: (row) => row?.total_completed_targets,
      cell: (row) => row?.total_completed_targets,
      sortable: false,
    },
    {
      name: "Earned Amount",
      selector: (row) => row?.overall_completion_rate,
      cell: (row) => row?.overall_completion_rate,
      sortable: true,
    },
    {
      name: "Target Status",
      selector: (row) => row?.target_status_label,
      cell: (row) => row?.target_status_label,
      sortable: false,
    }
  ];

  return (
    <>
      <div className="stats-fun-fact-box-wrapper">
        <div className="row justify-content-between">
          <StatsBlock
            icon="flaticon-coin"
            label="Total Earnings"
            count={/*propState.empTargets?.total_assigned_targets ||*/ 0}
            additionalClass='wallet-stats-box'
          />
          <StatsBlock
            icon="fa fa-check-circle"
            label="Completed Targets"
            count={/*propState.empTargets?.total_completed_targets ||*/ 0}
            additionalClass='stats-box-green'
          />
          <StatsBlock
            icon="fa fa-hourglass-half"
            label="Pending Count of Ongoing Target"
            count={/*propState.empTargets?.overall_completion_rate ||*/ 0}
            additionalClass='stats-box-red'
          />
          <StatsBlock
            icon="fa fa-money-bill-transfer"
            label="Wthdrawable Amount"
            count={/*propState.empTargets?.overall_completion_rate ||*/ 0}
            additionalClass='stats-box-purple'
          />
        </div>
      </div>
      <div className={`d-flex justify-content-between align-items-center p-3 mb-4 header-box`}>
        <h4 className={`header-text`}>
          Earnings
        </h4>
      </div>
      <DataTable
        columns={columns}
        data={[]}
        pagination
        paginationServer
        paginationPerPage={rowsPerPage}
        // paginationTotalRows={propState.empTargets?.total || 0}
        paginationRowsPerPageOptions={[5, 15, 25, 50, 100]}
        paginationComponentOptions={{
          rowsPerPageText: "Orders per page:",
        }}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        // customStyles={customStyles}
        progressPending={propState.loading}
        fixedHeader
        responsive
      />
    </>
  )
}

export default EarningsOverview;