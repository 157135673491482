import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as TeamLeaderDashboardActions from '../dashboard/dashboardcountActions';
import StatsBlock from '../dashboard/statsBlock';

/**
 * @author      Nandani.V.Patel
 * @date        24 th Oct 2024
 * @description UI Implementation of Team Leader Dashboard
 * @param
 * @response 
**/

const TeamLeaderDashboard = () => {
  const teamleaderCommission = useSelector((state) => state?.userdashboardcount?.dashboardCommission);
  const teamleaderscounts = useSelector((state) => state?.userdashboardcount?.dashboardteamleadercount);
  const [firstInit, setFirstInit] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (firstInit) {
      // dispatch(TeamLeaderDashboardActions.getdashboardCommission());
      dispatch(TeamLeaderDashboardActions.getTeamleaderashboardcount());
      setFirstInit(false);
    }
  }, [firstInit]);

  useEffect(() => {
    setFirstInit(true);
  }, []);

  return (
    <>
      <div className="teamleader-dashboard dashboard-fun-fact-area">
        <div className="row">
          <StatsBlock
            icon="fa-regular fa-hourglass-half"
            label="Pending"
            count={teamleaderscounts?.pending || 0}
          />
          <StatsBlock
            icon="fa-regular fa-thumbs-up"
            label="Active Targets"
            count={teamleaderscounts?.approved || 0}
          />
          <StatsBlock
            icon="fa fa-user-tie"
            label="Sub-Team Leaders"
            count={teamleaderscounts?.rejected || 0}
          />
          <StatsBlock
            icon="fa fa-users"
            label="Team Members"
            count={teamleaderCommission?.data || 0}
            // additionalClass="wallet-stats-box"
          />
        </div>
      </div>
    </>
  );
}
export default TeamLeaderDashboard;
