import React from 'react'

const WarningModal = ({ header="Note", warnText="", buttonText="Close", onClose }) => {
  return (
    <>
      <div
        className="modal fade show d-block"
        id="warningModal"
        tabIndex="-1"
        aria-labelledby="warningModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header bg-warning">
              <h4 className='mb-0'>{header}</h4>
              <button
                type="button"
                className="btn-close"
                onClick={onClose}
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <p>{warnText}</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-dark fw-medium" onClick={onClose}>
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" onClick={onClose}></div>
    </>
  )
}

export default WarningModal;