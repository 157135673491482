import React from "react";
import styles from "../styles.module.scss";

const PaginationComponent = ({ totalPages, currentPage, onPageChange }) => {
  // Generate the page numbers to display
  const renderPageNumbers = () => {
    const pages = [];

    // Edge case: if totalPages is less than or equal to 1
    if (totalPages <= 1) {
      pages.push(
        <li key={1}>
          <a
            // className={currentPage === 1 ? 'active' : ''}
            className={`${styles.hoverStyle} ${currentPage === 1 ? 'active' : ''}`}
          >
            1
          </a>
        </li>
      );
      return pages; // Only one page, return early
    }

    // Always show first page
    pages.push(
      <li key={1}>
        <a
          className={`${styles.hoverStyle} ${currentPage === 1 ? 'active' : ''}`}
          onClick={(e) => {
            e.preventDefault();
            onPageChange(1);
          }}
        >
          1
        </a>
      </li>
    );

    // Handle page numbers and ellipsis
    if (totalPages <= 5) {
      // Show all pages if totalPages is 5 or less
      for (let i = 2; i <= totalPages; i++) {
        pages.push(
          <li key={i}>
            <a
              className={`${styles.hoverStyle} ${i === currentPage ? 'active' : ''}`}
              onClick={(e) => {
                e.preventDefault();
                onPageChange(i);
              }}
            >
              {i}
            </a>
          </li>
        );
      }
    } else {
      // More than 5 pages
      const showEllipsisLeft = currentPage > 3; // Show ellipsis if current page is greater than 3
      const showEllipsisRight = currentPage < totalPages - 2; // Show ellipsis if current page is less than totalPages - 2

      if (showEllipsisLeft) {
        pages.push(<li key="left-ellipsis">...</li>);
      }

      // Display pages around the current one
      const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(totalPages - 1, currentPage + 1);

      for (let i = startPage; i <= endPage; i++) {
        pages.push(
          <li key={i}>
            <a
              className={`${styles.hoverStyle} ${i === currentPage ? 'active' : ''}`}
              onClick={(e) => {
                e.preventDefault();
                onPageChange(i);
              }}
            >
              {i}
            </a>
          </li>
        );
      }

      if (showEllipsisRight) {
        pages.push(<li key="right-ellipsis">...</li>);
      }

      // Last page
      pages.push(
        <li key={totalPages}>
          <a
            className={`${styles.hoverStyle} ${currentPage === totalPages ? 'active' : ''}`}
            onClick={(e) => {
              e.preventDefault();
              onPageChange(totalPages);
            }}
          >
            {totalPages}
          </a>
        </li>
      );
    }
    return pages;
  };

  const handlePreviousPage = (e) => {
    e.preventDefault();
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = (e) => {
    e.preventDefault();
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div className="paginations">
      <ul>
        {/* Previous Page */}
        <li>
          <a
            onClick={handlePreviousPage}
            className={currentPage === 1 ? 'disabled' : ''}
            style={{ cursor: 'pointer' }}
          >
            <i className="fa-solid fa-angle-left"></i>
          </a>
        </li>

        {/* Page Numbers with Ellipsis */}
        {renderPageNumbers()}

        {/* Next Page */}
        <li>
          <a
            onClick={handleNextPage}
            className={(totalPages === 0 || currentPage === totalPages) ? 'disabled' : ''}
            style={{ cursor: 'pointer' }}
          >
            <i className="fa-solid fa-angle-right"></i>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default PaginationComponent;