import React, { Fragment } from 'react';
import Sidebar from '../pages/sidebar';
import MainLayout from './mainLayout';
import DashboardBody from '../pages/dashboardbody';
import { Outlet } from 'react-router-dom';
import { encryptStorage, roleId, isAuth, tokenkey } from '../utils/auth';
import { SIGNIN, NOTFOUND } from '../global/routes';
import { useLocation } from 'react-router-dom';
import { userNavigationData, teamleaderNavigationData, companiesNavigationData, employeeNavigationData } from '../pages/sidebar/menu';

/**
 * @author      Nandani.V.Patel
 * @date        17th Oct 2024
 * @description UI Implementation of LayoutWrapper
 * @param
 * @response 
**/

const LayoutWrapper = () => {
  const userRoleId = encryptStorage.getItem(roleId);
  const location = useLocation();
  const auth = isAuth();

  if (!auth || typeof userRoleId === 'undefined' || userRoleId === null || userRoleId === "") {
    localStorage.removeItem(tokenkey);
    window.location.href = SIGNIN;
    return false;
  }

  // Map role IDs to their respective navigation data
  const navigationMap = {
    1: userNavigationData, // User
    2: teamleaderNavigationData, // Team leader
    3: companiesNavigationData, // Company
    4: employeeNavigationData, // Employee
    5: teamleaderNavigationData.filter((item) => !item.hideInSubTL), // Sub-TL
  };

  // Extract allowed URLs from any user NavigationData
  const allowedUrls = navigationMap?.[userRoleId]?.map(item => item?.href) || [];

  // Redirect to NOTFOUND if the current URL is not allowed for the role
  const isAllowedUrl = allowedUrls.some(url => location?.pathname.includes(url));
  if (!isAllowedUrl) {
    window.location.href = NOTFOUND;
    return null;
  }

  return (
    <Fragment>
      <MainLayout pageTitle="sidebar" >
        <Sidebar />
        <DashboardBody> <Outlet /></DashboardBody>
      </MainLayout>
    </Fragment>
  )
}

export default LayoutWrapper;