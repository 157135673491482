import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './profileActions';
import { postFileRequestAPI, getRequestAPI } from '../../global/api';
import { defaultErrorMsg } from '../../utils/conts';

/**
 * @author      Nandani.V.Patel
 * @date        10 Oct 2024
 * @description user-profile Saga and handling API response.
 * @param       user-profile Request paylod
 * @response    user-profile is success or fail.  
**/

function* UpdateProfileSaga(action) {
  try {
    const response = yield call(postFileRequestAPI, { url: 'profile', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.UPDATE_USER_PROFILE_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.UPDATE_USER_PROFILE_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.UPDATE_USER_PROFILE_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* getUserDetailsSaga() {
  try {
    const response = yield call(getRequestAPI, { url: 'profile' });
    if (response.data.status === 'success') {
      yield put({ type: ActionTypes.GET_USER_DETAILS_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.GET_USER_DETAILS_FAILURE, error: response });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_USER_DETAILS_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* watchUserProfileRequest() {
  yield takeLatest(ActionTypes.UPDATE_USER_PROFILE_REQUEST, UpdateProfileSaga);
  yield takeLatest(ActionTypes.GET_USER_DETAILS_REQUEST, getUserDetailsSaga);
}

function* userProfileSaga() {
  yield fork(watchUserProfileRequest);
}

export default userProfileSaga;