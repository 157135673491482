import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import Breadcrumb from '../../component/breadcrumbs';
import * as TeamLeaderReviewSiteActions from './teamleaderReviewsiteActions';
import { TEAMLEADERUSERS } from '../../global/routes';
import styles from './styles.module.scss'

/**
 * @author      Nandani.V.Patel
 * @date        29th Oct 2024
 * @description UI Implementation of Team-leader-reviewsite listing
 * @param
 * @response 
**/

const TeamLeaderReviewsite = (props) => {
  const { teamleaderReviewsite, actions } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const [firstInit, setFirstInit] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [companyreviewsite, setcompanyreviewsite] = useState('');

  const queryParams = new URLSearchParams(location.search);
  const team_member_id = queryParams.get('userid'); // For query parameter
  const company_id = queryParams.get('companyid');

  const breadcrumbs = [
    { name: 'Team Members', link: TEAMLEADERUSERS },
    { name: 'Company List', link: `${TEAMLEADERUSERS}?userid=${team_member_id}` },
    { name: 'Reviews', link: "" }
  ];

  useEffect(() => {
    if (firstInit) {
      actions.getTeamleaderCompanyReviewsiteRequest({ company_id: company_id, user_id: team_member_id, page, per_page: perPage });
      setFirstInit(false);
    }
  }, [firstInit, dispatch, team_member_id, page, perPage]);

  useEffect(() => {
    setFirstInit(true);
  }, [page, perPage]);

  useEffect(() => {
    if (typeof teamleaderReviewsite?.data !== 'undefined' && !teamleaderReviewsite?.isteamleaderreviewsite) {
      setcompanyreviewsite(teamleaderReviewsite?.data?.data);
    }
  }, [teamleaderReviewsite?.data, teamleaderReviewsite?.isteamleaderreviewsite]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setPage(1); // Reset to first page when rows per page changes
  };

  const columns = [
    {
      name: 'Site Name',
      selector: row => row?.review_site_tbl_name || '-',
      sortable: true,
      cell: (row) => <div className="custom-cell">{row.review_site_tbl_name || '-'}</div>,
      width: '20%',
    },
    {
      name: 'Review Content',
      selector: row => row?.reviewContent || "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
      cell: (row) => <div style={{ paddingBottom: '10px', paddingTop: '10px' }} className="custom-cell">{row?.reviewContent || "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."}</div>,
      width: '60%',
    },
    {
      name: 'Status',
      selector: row => row?.review_site_tbl_status_text || '-',
      width: '20%',
      cell: row => {
        const renderStatusBadge = (status) => {
          switch (status) {
            case 'Active':
              return <div className="btn-area">
                <span
                  className={`${'status - btn'} ${styles.btnactive}`}
                  data-review-status="approved"
                >
                  Active
                </span>
              </div>
            case 'Inactive':
              return (
                <span
                  className={`${'status - btn'} ${styles.btnInactive}`}
                  data-review-status="rejected"
                >
                  In Active
                </span>
              );
            default:
              return <span
                className={`${'status - btn'} ${styles.btnunknow}`}
                data-review-status="rejected"
              >
                Unknown
              </span>
          }
        };
        return renderStatusBadge(row?.review_site_tbl_status_text);
      },
    },
  ];

  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div className={`d-flex mt-2 p-3 mb-4 header-box`}>
        <h4 className={`col-md-12 header-text`}>
          Reviews
        </h4>
      </div>
      <div style={{ width: '100%' }}>
        <DataTable
          columns={columns}
          data={companyreviewsite}
          theme="solarized"
          fixedHeader
          pagination
          paginationServer
          paginationTotalRows={teamleaderReviewsite?.data?.total}
          paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerPageChange}
          responsive
        />
      </div>
    </>
  )
}
const mapStateToProps = (state) => ({
  teamleaderReviewsite: state.teamleaderReviewsite,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(TeamLeaderReviewSiteActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamLeaderReviewsite);
