import React from "react";
import { Link } from "react-router-dom";
import { SIGNIN } from "../../global/routes";

const UnauthorizedPage = () => {
  return (
    <div className="error-area ptb-100">
      <div className="container">
        <div className="top-content">
          <ul>
            <li>4</li>
            <li>0</li>
            <li>3</li>
          </ul>
        </div>
        <h2>Error 403: Unauthorized Access</h2>
        <p>You do not have permission to view this page. Please contact your administrator or login with the appropriate credentials.</p>
        {/* <a href="/login" className="btn default-btn">Go to Login</a> */}
        <Link to={SIGNIN} className="btn default-btn">
          Go Back
        </Link>
        {/* <a href="/" className="btn default-btn style-2">Back to Home</a> */}
      </div>
    </div>
  );
};

export default UnauthorizedPage;
