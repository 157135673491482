import React from 'react'
import StatsBlock from '../dashboard/statsBlock';

const EmployeeDashboard = () => {
  return (
    <div className="teamleader-dashboard dashboard-fun-fact-area">
      <div className="row justify-content-between">
        {/* <StatsBlock
          icon="fa-regular fa-hourglass-half"
          label=""
          count={0}
        /> */}
        <StatsBlock
          icon="fa fa-bullseye"
          label="Active Targets"
          count={0}
        />
        <StatsBlock
          icon="flaticon-company"
          label="Total Companies"
          count={0}
          additionalClass="stats-box-yellow"
        />
        <StatsBlock
          icon="flaticon-coin"
          label="Wallet balance"
          count={0}
          additionalClass="wallet-stats-box"
        />
      </div>
    </div>
  )
}

export default EmployeeDashboard;