import React, { useState } from 'react'
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { ASSIGNTARGET_SUBTL } from '../../global/routes';

const demoSiteData = [
  {
    review_site_data: {
      site_name: "Trustpilot",
      site_logo: "https://example.com/logos/trustpilot.png"
    },
    review_campaign_tbl_purchased_reviews: 50,
    remaining_reviews_count: 10,
    completion_rate: 80,
    status: "Active",
    target_status: "Approved",
    created_at: "2024-11-01T12:00:00Z"
  },
  {
    review_site_data: {
      site_name: "Google Reviews",
      site_logo: "https://example.com/logos/google.png"
    },
    review_campaign_tbl_purchased_reviews: 30,
    remaining_reviews_count: 5,
    completion_rate: 83.33,
    status: "Active",
    target_status: "Pending",
    created_at: "2024-11-05T14:30:00Z"
  },
  {
    review_site_data: {
      site_name: "Yelp",
      site_logo: "https://example.com/logos/yelp.png"
    },
    review_campaign_tbl_purchased_reviews: 20,
    remaining_reviews_count: 15,
    completion_rate: 25,
    status: "Inactive",
    target_status: "Rejected",
    created_at: "2024-11-10T09:15:00Z"
  },
  {
    review_site_data: {
      site_name: "Facebook Reviews",
      site_logo: "https://example.com/logos/facebook.png"
    },
    review_campaign_tbl_purchased_reviews: 40,
    remaining_reviews_count: 0,
    completion_rate: 100,
    status: "Active",
    target_status: "Approved",
    created_at: "2024-11-15T11:45:00Z"
  },
  {
    review_site_data: {
      site_name: "TripAdvisor",
      site_logo: "https://example.com/logos/tripadvisor.png"
    },
    review_campaign_tbl_purchased_reviews: 25,
    remaining_reviews_count: 10,
    completion_rate: 60,
    status: "Inactive",
    target_status: "Pending",
    created_at: "2024-11-20T08:00:00Z"
  }
];

const TargetSitesTable = ({ data, propState, role_id }) => {
  const navigate = useNavigate();
  // State for pagination
  const [sitePage, setSitePage] = useState(1);
  const [sitesPerPage, setSitesPerPage] = useState(3);

  // custom style for review site list
  const siteTableStyles = {
    table: {
      style: {
        padding: '0 30px',
      }
    },
    headRow: {
      style: {
        borderLeft: "solid 1px rgba(0,0,0,.12)",
        borderRight: "solid 1px rgba(0,0,0,.12)",
        fontWeight: "500",
        fontSize: "14px"
      }
    },
    rows: {
      style: {
        borderLeft: "solid 1px rgba(0,0,0,.12)",
        borderRight: "solid 1px rgba(0,0,0,.12)",
        borderBottom: "solid 1px rgba(0,0,0,.12)",
        fontWeight: "400",
        fontSize: "14px",
        // paddingTop: "5px",
        // paddingBottom: "5px"
      }
    },
    pagination: {
      style: {
        padding: "0 30px",
        borderBottom: "solid 1px rgba(0,0,0,.12)",
        borderTop: "none"
      }
    }
  };

  const assignTarget = (row) => {
    console.log("row:", row);
    // const details = {
    //   transaction_id: data?.id,
    //   review_site_id: row?.review_campaign_tbl_review_site_id,
    //   siteName: row?.review_site_data?.site_name || ""
    // };

    navigate(ASSIGNTARGET_SUBTL);
  }

  // Pagination event handlers
  const handlePageChange = (page) => setSitePage(page);
  const handleRowsPerPageChange = (newRowsPerPage) => {
    setSitesPerPage(newRowsPerPage);
    setSitePage(1); // Reset to first page when rows per page changes
  };

  // company purchase based review site listing table columns
  const siteColumns = [
    {
      name: 'Review Site',
      selector: (row) => row?.review_site_data?.site_name || "-",
      cell: (row) => (
        <>
          {/* <img
            src={row?.review_site_data?.site_logo || ""}
            alt={row?.review_site_data?.site_name}
            width="50px"
            height="50px"
          /> */}
          <span className='ps-3'>{row?.review_site_data?.site_name || "-"}</span>
        </>
      ),
      sortable: true
    },
    {
      name: 'Assign Reviews',
      selector: (row) => row?.review_campaign_tbl_purchased_reviews || 0,
      sortable: true
    },
    {
      name: 'Pending Count',
      selector: (row) => row?.remaining_reviews_count || 0,
      sortable: true
    },
    {
      name: 'Completion rate',
      selector: (row) => row?.remaining_reviews_count || 0,
      cell: (row) => `${row?.completion_rate || 0}%`,
      sortable: true
    },
    {
      id: 'col3',
      name: 'Status',
      selector: (row) => row?.status || "",
      cell: (row) => {
        return (
          <div className="btn-area">
            <span
              className="status-btn"
              data-review-status={row?.target_status.toLowerCase()}
            >
              {row?.target_status}
            </span>
          </div>
        )
      },
      sortable: true
    },
    {
      name: 'Assigned Date',
      selector: (row) => row.created_at,
      cell: (row) => new Date(row.created_at).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
      }),
      sortable: true
    },
    {
      name: 'Action',
      cell: (row) => (
        <div className="action-btn option-list d-flex gap-2">
          {role_id === 2 && (
            <button
              className="option-btn purple-action-btn d-inline-block"
              type="button"
              title='Assign Target'
              onClick={() => assignTarget(row)}
            >
              <i className="fa-solid fa-bullseye"></i>
            </button>
          )}
          <button
            className="option-btn blue-action-btn d-inline-block"
            type="button"
            title='Send Email to Users'
          >
            <i className="fa-solid fa-paper-plane"></i>
          </button>
        </div>
      ),
      width: '100px',
      right: true
    }
  ];

  return (
    <DataTable
      columns={siteColumns}
      data={demoSiteData || propState.targetSiteList?.data || []}
      customStyles={siteTableStyles}
      progressPending={propState.siteLoad}
      responsive
      pagination
      // paginationServer
      paginationPerPage={sitesPerPage}
      paginationTotalRows={propState.targetSiteList?.total || 0}
      paginationRowsPerPageOptions={[3, 5, 10, 15]}
      paginationComponentOptions={{
        rowsPerPageText: "Orders per page:",
        style: {
          padding: '0 30px',
        }
      }}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handleRowsPerPageChange}
    />
  )
}

export default TargetSitesTable;