import { ActionTypes } from './companyUserReviewActions';

/**
 * @author      Nandani.V.Patel
 * @date        25 Oct 2024
 * @description company user review Reducer.
 * @param       takes default and previous state value
 * @response    update a state value and store company user review api response  
**/

const defaultState = {
  isCompanyUserReview: false,
  isCompanyreviewsites: false,
  isReviewsiteUser: false
};

const companyUserReviewReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.REVIEW_SITE_WISE_USER_REVIEW_REQUEST:
      return {
        ...state,
        isCompanyUserReview: true,
      };
    case ActionTypes.REVIEW_SITE_WISE_USER_REVIEW_SUCCESS:
      return {
        ...state,
        data: action.data,
        isCompanyUserReview: false,
      };
    case ActionTypes.REVIEW_SITE_WISE_USER_REVIEW_FAILURE:
      return {
        ...state,
        isCompanyUserReview: false,
        error: action.error,
      };
    case ActionTypes.REVIEW_SITE_LISTING_REQUEST:
      return {
        ...state,
        isCompanyreviewsites: true,
      };
    case ActionTypes.REVIEW_SITE_LISTING_SUCCESS:
      return {
        ...state,
        reviewsite: action.data,
        isCompanyreviewsites: false,
      };
    case ActionTypes.REVIEW_SITE_LISTING_FAILURE:
      return {
        ...state,
        isCompanyreviewsites: false,
        error: action.error,
      };
    case ActionTypes.REVIEW_SITE_USER_LISTING_REQUEST:
      return {
        ...state,
        isReviewsiteUser: true,
      };
    case ActionTypes.REVIEW_SITE_USER_LISTING_SUCCESS:
      return {
        ...state,
        userdata: action.data,
        isReviewsiteUser: false,
      };
    case ActionTypes.REVIEW_SITE_USER_LISTING_FAILURE:
      return {
        ...state,
        isReviewsiteUser: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default companyUserReviewReducer;