import { ActionTypes } from "./actions";

const defaultState = {
  subTlList: {},
  subTlListArr: [],
  loading: false,
  successMsg: "",
  errorMsg: "",
};

const subTLReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.CREATE_SUB_TL:
    case ActionTypes.GET_SUB_TL:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.CREATE_SUB_TL_FAILURE:
    case ActionTypes.GET_SUB_TL_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
      }
    case ActionTypes.CREATE_SUB_TL_SUCCESS:
      return {
        ...state,
        successMsg: action.payload.message,
        loading: false,
      };
    case ActionTypes.GET_SUB_TL_SUCCESS:
      return {
        ...state,
        subTlList: action.payload,
        subTlListArr: [...state.subTlListArr, ...action.payload.data],
        loading: false,
      };
    case ActionTypes.CLEAR_STATE:
      return {
        ...state,
        subTlList: {},
        subTlListArr: [],
        loading: false,
        successMsg: "",
        errorMsg: "",
      }
    default:
      return state;
  }
}

export default subTLReducer;