import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './comapnyRegisterActions';
import { postFileRequestAPI, getRequestAPI } from '../../global/api';
import { defaultErrorMsg } from '../../utils/conts';

/**
 * @author      Nandani.V.Patel
 * @date        14 Oct 2024
 * @description Registration  Saga and handling API response.
 * @param       register-company Request paylod
 * @response    company registration  is success or fail.  
**/

function* registerCompanySaga(action) {
  try {
    const response = yield call(postFileRequestAPI, { url: 'company/store', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.COMPANY_REGISTRATION_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.COMPANY_REGISTRATION_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.COMPANY_REGISTRATION_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* countrySaga() {
  try {
    const response = yield call(getRequestAPI, { url: 'get-countries' });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.GET_COUNTRIES_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.GET_COUNTRIES_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_COUNTRIES_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* watchCompanyRegistrationRequest() {
  yield takeLatest(ActionTypes.COMPANY_REGISTRATION_REQUEST, registerCompanySaga);
  yield takeLatest(ActionTypes.GET_COUNTRIES_REQUEST, countrySaga);
}

function* companySignUpSaga() {
  yield fork(watchCompanyRegistrationRequest);
}

export default companySignUpSaga;