import { ActionTypes } from "./actions";

const defaultState = {
  isStoreBankdata: false,
  isBankList: false,
  bankDetails: [],
  iswithdrawal: false,
  // withdrawaldata: undefined,
  withdrawalHistory: {},
  isHistoryData: false,
  successMsg: "",
  errorMsg: "",
};

const withdrawalReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.STORE_BANK_DETAILS_REQUEST:
      return {
        ...state,
        isStoreBankdata: true,
      };
    case ActionTypes.STORE_BANK_DETAILS_SUCCESS:
      return {
        ...state,
        isStoreBankdata: false,
        successMsg: action.payload.message
      };
    case ActionTypes.STORE_BANK_DETAILS_FAILURE:
      return {
        ...state,
        isStoreBankdata: false,
        errorMsg: action.error,
      };
    case ActionTypes.GET_USER_BANK_DETAILS_REQUEST:
      return {
        ...state,
        isBankList: true,
      };
    case ActionTypes.GET_USER_BANK_DETAILS_SUCCESS:
      return {
        ...state,
        bankDetails: action.payload,
        isBankList: false,
      };
    case ActionTypes.GET_USER_BANK_DETAILS_FAILURE:
      return {
        ...state,
        isBankList: false,
        errorMsg: action.error,
      };
    case ActionTypes.WITHDRAWAL_REQUEST:
      return {
        ...state,
        iswithdrawal: true,
      };
    case ActionTypes.WITHDRAWAL_REQUEST_SUCCESS:
      return {
        ...state,
        iswithdrawal: false,
        successMsg: action.payload.message
      };
    case ActionTypes.WITHDRAWAL_REQUEST_FAILURE:
      return {
        ...state,
        iswithdrawal: false,
        errorMsg: action.error,
      };
    case ActionTypes.WITHDRAWAL_HISTORY_REQUEST:
      return {
        ...state,
        isHistoryData: true,
      };
    case ActionTypes.WITHDRAWAL_HISTORY_SUCCESS:
      return {
        ...state,
        withdrawalHistory: action.data,
        isHistoryData: false,
      };
    case ActionTypes.WITHDRAWAL_HISTORY_FAILURE:
      return {
        ...state,
        isHistoryData: false,
        errorMsg: action.error,
      };
    default:
      return state;
  }
}

export default withdrawalReducer;