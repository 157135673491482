import React from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/**
 * @author      Nandani.V.Patel
 * @date        8 Oct 2024
 * @description ToastContainer Design to display Notification.
 * @param
 * @response 
**/

export const NotificationContainer = () => {
    return (
        <ToastContainer
            transition={Slide}
            position="top-center"
            autoClose={4000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover={false}
            theme="light"
            style={{ fontSize: '13px', fontWeight: '500' }}
        />
    )
}