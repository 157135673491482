/**
 * @author      Nandani.V.Patel
 * @date        10 Oct 2024
 * @description ActionTypes and ActionCreator Function for user-profile Request.
 * @param
 * @response      
**/

export const ActionTypes = {
  UPDATE_USER_PROFILE_REQUEST: 'UPDATE_USER_PROFILE_REQUEST',
  UPDATE_USER_PROFILE_SUCCESS: 'UPDATE_USER_PROFILE_SUCCESS',
  UPDATE_USER_PROFILE_FAILURE: 'UPDATE_USER_PROFILE_FAILURE',

  GET_USER_DETAILS_REQUEST: 'GET_USER_DETAILS_REQUEST',
  GET_USER_DETAILS_SUCCESS: 'GET_USER_DETAILS_SUCCESS',
  GET_USER_DETAILS_FAILURE: 'GET_USER_DETAILS_FAILURE',
};

export const userProfileRequest = (userProfileData) => {
  return {
    type: ActionTypes.UPDATE_USER_PROFILE_REQUEST,
    payload: userProfileData,
  };
};

export const getUserDetailsRequest = () => {
  return {
    type: ActionTypes.GET_USER_DETAILS_REQUEST
  };
};