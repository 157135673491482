/**
 * @author      Nandani.V.Patel
 * @date        24 Oct 2024
 * @description ActionTypes and ActionCreator Function for Team-leader User Request.
 * @param
 * @response      
**/

export const ActionTypes = {
  GET_TEAM_LEADER_USER_REQUEST: 'GET_TEAM_LEADER_USER_REQUEST',
  GET_TEAM_LEADER_USER_SUCCESS: 'GET_TEAM_LEADER_USER_SUCCESS',
  GET_TEAM_LEADER_USER_FAILURE: 'GET_TEAM_LEADER_USER_FAILURE',
};

export const getTeamleaderuserRequest = (paginationData) => {
  return {
    type: ActionTypes.GET_TEAM_LEADER_USER_REQUEST,
    payload: paginationData,
  };
};