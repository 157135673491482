import { ActionTypes } from "./actions";

const defaultState = {
  empCompanyList: {},
  companyTargetHistory: {},
  empTargets: {},
  loading: false,
  assignTargetLoader: false,
  successMsg: "",
  errorMsg: "",
};

const employeePanelReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.GET_EMP_COMPANIES:
    case ActionTypes.GET_COMPANY_TARGET_HISTORY:
    case ActionTypes.GET_EMP_TARGET_HISTORY:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.GET_EMP_COMPANIES_FAILURE:
    case ActionTypes.GET_COMPANY_TARGET_HISTORY_FAILURE:
    case ActionTypes.GET_EMP_TARGET_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error
      };
    case ActionTypes.GET_EMP_COMPANIES_SUCCESS:
      return {
        ...state,
        empCompanyList: action.payload,
        loading: false
      };
    case ActionTypes.UPDATE_COMPANY_STATUS:
      return {
        ...state,
        // loading: true
      };
    case ActionTypes.UPDATE_COMPANY_STATUS_SUCCESS:
      return {
        ...state,
        successMsg: action.payload.message,
        // loading: false
      };
    case ActionTypes.UPDATE_COMPANY_STATUS_FAILURE:
      return {
        ...state,
        // loading: false,
        errorMsg: action.error
      };
    case ActionTypes.ASSIGN_COMPANY_TARGETS:
      return {
        ...state,
        assignTargetLoader: true
      };
    case ActionTypes.ASSIGN_COMPANY_TARGETS_SUCCESS:
      return {
        ...state,
        assignTargetLoader: false,
        successMsg: action.payload.message
      };
    case ActionTypes.ASSIGN_COMPANY_TARGETS_FAILURE:
      return {
        ...state,
        assignTargetLoader: false,
        errorMsg: action.error
      };
    case ActionTypes.GET_COMPANY_TARGET_HISTORY_SUCCESS:
      return {
        ...state,
        companyTargetHistory: action.payload,
        loading: false
      }
    case ActionTypes.GET_EMP_TARGET_HISTORY_SUCCESS:
      return {
        ...state,
        empTargets: action.payload,
        loading: false
      }
    case ActionTypes.CLEAR_STATE:
      return {
        ...state,
        successMsg: "",
        errorMsg: "",
      };
    default:
      return state;
  }
};

export default employeePanelReducer;