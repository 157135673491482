import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './logoutActions';
import { postRequestAPI } from '../../global/api';
import { defaultErrorMsg } from '../../utils/conts';

/**
 * @author      Nandani.V.Patel
 * @date        10 Oct 2024
 * @description Logout Saga and handling API response.
 * @param       Logout-user Request paylod
 * @response    user Logout  is success or fail.  
**/

function* userLogoutSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'logout', data: action.payload });
    if (response.data.status === 'success') {
      yield put({ type: ActionTypes.LOGOUT_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.LOGOUT_SUCCESS, error: response });
    }
  } catch (err) {
    yield put({ type: ActionTypes.LOGOUT_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* watchLogoutRequest() {
  yield takeLatest(ActionTypes.LOGOUT_REQUEST, userLogoutSaga);
}

function* logoutSaga() {
  yield fork(watchLogoutRequest);
}

export default logoutSaga;