import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './companyDetailsActions';
import { postRequestAPI } from '../../global/api';
import { defaultErrorMsg } from '../../utils/conts';

/**
 * @author      Nandani.V.Patel
 * @date        17 Oct 2024
 * @description company details Saga and handling API response.
 * @param       company details Request paylod
 * @response    company details  is success or fail.  
**/

function* CompanydetailsSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'company-details', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.COMPANY_DETAILS_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.COMPANY_DETAILS_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.COMPANY_DETAILS_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* getCompanyPurchasedSitesReq(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'reviewsite-list', data: action.payload });
    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.GET_COMPANY_PURCHASED_SITES_SUCCESS, data: resData.data });
    } else {
      yield put({ type: ActionTypes.GET_COMPANY_PURCHASED_SITES_FAILURE, error: resData });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_COMPANY_PURCHASED_SITES_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* watchCompanyDetailsSagaRequest() {
  yield takeLatest(ActionTypes.COMPANY_DETAILS_REQUEST, CompanydetailsSaga);
  yield takeLatest(ActionTypes.GET_COMPANY_PURCHASED_SITES, getCompanyPurchasedSitesReq);
}

function* companyDetailsSaga() {
  yield fork(watchCompanyDetailsSagaRequest);
}

export default companyDetailsSaga;