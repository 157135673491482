import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { ADD_SUBTL } from '../../global/routes';
import { getSubTL } from './redux/actions';

import styles from './styles.module.scss';

const SubTLList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const propState = useSelector((state) => ({
    subTlList: state.teamleaderSubTL.subTlList,
    loading: state.teamleaderSubTL.loading
  }));

  useEffect(() => {
    const payload = {
      per_page: rowsPerPage,
      page: currentPage
    };
    dispatch(getSubTL(payload));
  }, [dispatch, rowsPerPage, currentPage]);

  const customStyles = {
    headCells: {
      style: {
        verticalAlign: 'middle',
        whiteSpace: 'nowrap',
        padding: '18px 10px',
        fontSize: '18px',
        fontWeight: '600'
      }
    },
    cells: {
      style: {
        verticalAlign: "middle",
        padding: "20px 10px",
        fontWeight: "500",
        fontSize: "16px"
      }
    }
  };

  const columns = [
    {
      name: 'Name',
      sortable: true,
      selector: (row) => row?.user_name || "-",
      cell: (row) => (
        <>
          {row?.user_image ? (
            <img
              src={row.user_image}
              alt="subTL"
              width="50px"
              height="50px"
            />
          ) : (
            <i
              className="fa-solid fa-user-tie"
              style={{ fontSize: '50px', height: '50px', width: '50px', textAlign: 'center' }}
            />
          )}
          <span className='ps-3'>{row?.user_first_name || "-"} {row?.user_last_name || ""}</span>
        </>
      ),
      minWidth: '170px',
    },
    {
      name: 'Email',
      selector: (row) => row?.user_email,
      cell: (row) => row?.user_email || "-",
      sortable: false,
      minWidth: "130px"
    },
    {
      name: 'Phone No.',
      selector: (row) => row.user_phone_number,
      cell: (row) => row?.user_phone_number || "-",
      sortable: false,
      minWidth: "110px"
    },
    {
      name: "Assigned Reviews",
      selector: (row) => row?.assigned_reviews,
      cell: (row) => row?.assigned_reviews || 0,
      sortable: true,
    },
    {
      name: "Action",
      // minWidth: '50px',
      // maxWidth: '140px',
      center: 'yes',
      cell: (row) => (
        <>
          <div className="form-check text-primary form-switch">
            <input
              className={`form-check-input`}
              type="checkbox"
              title='Update Status'
              id={`flexSwitchCheckDefault-${row.id}`}
              // checked={activeStatus[row.id] !== undefined ? activeStatus[row.id] : row.social_platform_tbl_status}
              // onChange={() => handleUpdateStatus(row, activeStatus[row.id])}
              style={{ cursor: 'pointer', height: '20px', width: '40px' }}
            />
          </div>
        </>
      ),
    },
  ];

  // Pagination event handlers
  const handlePageChange = (page) => setCurrentPage(page);
  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); // Reset to first page when rows per page changes
  };

  return (
    <>
      <div className={`d-flex justify-content-between align-items-center p-3 mb-4 header-box ${styles.tableheader}`}>
        <h4 className={`col-md-10 header-text`}>
          Sub-Team Leaders
        </h4>
        <button
          className={`btn btn-primary col-md-2 add-btn ${styles.addbtn}`}
          onClick={() => navigate(ADD_SUBTL)}
        >
          Add Sub-TL
        </button>
      </div>
      <DataTable
        columns={columns}
        data={propState.subTlList?.data || []}
        customStyles={customStyles}
        progressPending={propState.loading}
        fixedHeader
        responsive
        pagination
        paginationServer
        paginationTotalRows={propState.subTlList?.total || 0}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        paginationComponentOptions={{
          rowsPerPageText: "Orders per page:",
        }}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
      />
    </>
  );
}

export default SubTLList;