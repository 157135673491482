import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import DataTable from 'react-data-table-component';
import { CREATETEAMLEADERUSERS, TEAMLEADERUSERS } from '../../global/routes';
import * as AddTeamLeaderUsersActions from './teamleaderAdduserActions';
import { NotificationContainer } from '../../component/notificationContainer';
import { notification } from '../../component/notificationContainer/notification';
import Breadcrumb from '../../component/breadcrumbs';

import styles from './styles.module.scss';

const TeamleaderAddUser = (props) => {
  const { addteamleaderusers, actions } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [firstInit, setFirstInit] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [users, setusers] = useState('');

  const breadcrumbs = [
    { name: 'Team Members', link: TEAMLEADERUSERS },
    { name: 'Add User', link: "" },
  ];

  useEffect(() => {
    if (firstInit) {
      actions.getunassigneduserRequest({ page, per_page: perPage });
      setFirstInit(false);
    }
  }, [firstInit, page, perPage]);

  useEffect(() => {
    setFirstInit(true);
  }, [page, perPage]);

  useEffect(() => {
    if (typeof addteamleaderusers?.unassigneduserData !== 'undefined' && !addteamleaderusers?.isunassigneduser) {
      setusers(addteamleaderusers?.unassigneduserData?.data);
    }
  }, [addteamleaderusers?.unassigneduserData, addteamleaderusers?.isunassigneduser]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setPage(1); // Reset to first page when rows per page changes
  };

  useEffect(() => {
    if (
      typeof addteamleaderusers?.data !== 'undefined' &&
      !addteamleaderusers?.isadduser &&
      addteamleaderusers?.data?.status === 'success'
    ) {
      notification(addteamleaderusers?.data?.message, 'success');
      dispatch({ type: AddTeamLeaderUsersActions.ActionTypes.ADD_TEAM_LEADER_USER_SUCCESS, data: undefined });
      actions.getunassigneduserRequest({ page, per_page: perPage });
    }
    if (
      addteamleaderusers?.error?.message &&
      !addteamleaderusers?.isadduser
    ) {
      notification(addteamleaderusers?.error?.message, 'error');
      dispatch({ type: AddTeamLeaderUsersActions.ActionTypes.ADD_TEAM_LEADER_USER_FAILURE, error: undefined });
    }
  }, [addteamleaderusers?.data, addteamleaderusers?.isadduser, addteamleaderusers?.error]);

  const columns = [
    {
      name: 'User',
      cell: row => (
        <>
          <div className="title-logo">
            {row.user_image ? (
              <img
                src={row.user_image}
                alt="Profile"
                className="user-image"
                height={50}
                width={50}
                style={{ margin: '10px' }}
              />
            ) : (
              <i className="fa-solid fa-user-tie" style={{ margin: '10px', fontSize: '50px' }} />
            )}
          </div>
          <p className='ms-3'>{row?.user_first_name || '-'} {row?.user_last_name || '-'}</p>
        </>
      ),
      sortable: false,
      minWidth: '160px',
    },
    // {
    //   name: 'Full Name',
    //   selector: row => `${row?.user_first_name || '-'} ${row?.user_last_name || '-'}`,
    //   sortable: true,
    // },
    {
      name: 'Email',
      selector: row => row?.user_email || '-',
      sortable: true,
    },
    {
      name: 'Phone Number',
      minWidth: '50px',
      maxWidth: '134px',
      selector: row => row?.user_phone_number || '-',
    },
    {
      name: "Action",
      center: 'yes',
      cell: (row) => (
        <button
          className={`${styles.iconStyle} option-btn d-inline-block`}
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Add User"
          type="button"
          onClick={() => {
            if (!row.is_saved) {
              actions.addTeamleaderuserRequest({ user_id: row.id });
            }
          }}
        >
          <i className="fa-solid fa-plus"></i>
        </button>
      ),
    },
  ];

  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div className={`d-flex justify-content-between align-items-center p-3 mb-4 header-box`}>
        <h4 className={`col-md-10 header-text`}>
          Add User
        </h4>
        <button
          className={`btn btn-primary col-md-2 add-btn`}
          onClick={() => navigate(CREATETEAMLEADERUSERS)}
        >
          Create User
        </button>
      </div>
      <DataTable
        columns={columns}
        data={users}
        theme="solarized"
        fixedHeader
        pagination
        paginationServer
        paginationTotalRows={addteamleaderusers?.unassigneduserData?.total}
        paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerPageChange}
        responsive
        progressPending={addteamleaderusers?.isunassigneduser}
      />
      <NotificationContainer />
    </>
  )
}
const mapStateToProps = (state) => ({
  addteamleaderusers: state.addteamleaderusers,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(AddTeamLeaderUsersActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamleaderAddUser);