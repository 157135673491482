// AccountTypeContext.js
import React, { createContext, useContext, useState } from 'react';

/**
 * @author      Nandani.V.Patel
 * @date        8th Oct 2024
 * @description AccountTypeContext is layout for get user account type like team-leader or user
 * @param
 * @response 
**/

const AccountTypeContext = createContext();

export const AccountTypeProvider = ({ children }) => {
  const [accountType, setAccountType] = useState(null);

  return (
    <AccountTypeContext.Provider value={{ accountType, setAccountType }}>
      {children}
    </AccountTypeContext.Provider>
  );
};

export const useAccountType = () => useContext(AccountTypeContext);
