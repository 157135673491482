/**
 * @author      Nandani.V.Patel
 * @date        29 Oct 2024
 * @description ActionTypes and ActionCreator Function for Team-leader Company Request.
 * @param
 * @response      
**/

export const ActionTypes = {
  GET_TEAM_LEADER_COMPANY_REQUEST: 'GET_TEAM_LEADER_COMPANY_REQUEST',
  GET_TEAM_LEADER_COMPANY_SUCCESS: 'GET_TEAM_LEADER_COMPANY_SUCCESS',
  GET_TEAM_LEADER_COMPANY_FAILURE: 'GET_TEAM_LEADER_COMPANY_FAILURE',
};

export const getTeamleaderCompanyRequest = (paginationData) => {
  return {
    type: ActionTypes.GET_TEAM_LEADER_COMPANY_REQUEST,
    payload: paginationData,
  };
};