/**
 * @author      Nandani.V.Patel
 * @date        14 Oct 2024
 * @description ActionTypes and ActionCreator Function for Coampny Registration Request.
 * @param
 * @response      
**/

export const ActionTypes = {
  COMPANY_REGISTRATION_REQUEST: 'COMPANY_REGISTRATION_REQUEST',
  COMPANY_REGISTRATION_SUCCESS: 'COMPANY_REGISTRATION_SUCCESS',
  COMPANY_REGISTRATION_FAILURE: 'COMPANY_REGISTRATION_FAILURE',

  GET_COUNTRIES_REQUEST: 'GET_COUNTRIES_REQUEST',
  GET_COUNTRIES_SUCCESS: 'GET_COUNTRIES_SUCCESS',
  GET_COUNTRIES_FAILURE: 'GET_COUNTRIES_FAILURE',
};

export const companyregistrationRequest = (companyregistrationData) => {
  return {
    type: ActionTypes.COMPANY_REGISTRATION_REQUEST,
    payload: companyregistrationData,
  };
};

export const getCountriesRequest = () => {
  return {
    type: ActionTypes.GET_COUNTRIES_REQUEST,
  };
};