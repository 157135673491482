import { ActionTypes } from './dashboardcountActions';

/**
 * @author      Nandani.V.Patel
 * @date        22 Oct 2024
 * @description dashboard count Reducer.
 * @param       takes default and previous state value
 * @response    update a state value and store dashboard count api response  
**/

const defaultState = {
  isdashboardusercount: false,
  dashboardusercount: undefined,
  isdashboardCommission: false,
  dashboardCommission: undefined,
  isdashboardreviewcount: false,
  dashboardreviewcount: undefined,
  isdashboardteamleadercount: false,
  dashboardteamleadercount: undefined,
  isdashboardcompanycount: false,
  dashboardcompanycount: undefined
};

const dashboardusercountReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.GET_DASHBOARD_USER_COUNT_REQUEST:
      return {
        ...state,
        isdashboardusercount: true,
      };
    case ActionTypes.GET_DASHBOARD_USER_COUNT_SUCCESS:
      return {
        ...state,
        dashboardusercount: action.data,
        isdashboardusercount: false,
      };
    case ActionTypes.GET_DASHBOARD_USER_COUNT_FAILURE:
      return {
        ...state,
        isdashboardusercount: false,
        error: action.error,
      };
    case ActionTypes.GET_TEAM_LEADER_DASHBOARD_COMMISSION_REQUEST:
      return {
        ...state,
        isdashboardCommission: true,
      };
    case ActionTypes.GET_TEAM_LEADER_DASHBOARD_COMMISSION_SUCCESS:
      return {
        ...state,
        dashboardCommission: action.data,
        isdashboardCommission: false,
      };
    case ActionTypes.GET_TEAM_LEADER_DASHBOARD_COMMISSION_FAILURE:
      return {
        ...state,
        isdashboardCommission: false,
        error: action.error,
      };
    case ActionTypes.GET_COMPANY_DASHBOARD_USER_REVIEW_COUNT_REQUEST:
      return {
        ...state,
        isdashboardreviewcount: true,
      };
    case ActionTypes.GET_COMPANY_DASHBOARD_USER_REVIEW_COUNT_SUCCESS:
      return {
        ...state,
        dashboardreviewcount: action.data,
        isdashboardreviewcount: false,
      };
    case ActionTypes.GET_COMPANY_DASHBOARD_USER_REVIEW_COUNT_FAILURE:
      return {
        ...state,
        isdashboardreviewcount: false,
        error: action.error,
      };
    case ActionTypes.GET_TEAM_LEADER_DASHBOARD_COUNT_REQUEST:
      return {
        ...state,
        isdashboardteamleadercount: true,
      };
    case ActionTypes.GET_TEAM_LEADER_DASHBOARD_COUNT_SUCCESS:
      return {
        ...state,
        dashboardteamleadercount: action.data,
        isdashboardteamleadercount: false,
      };
    case ActionTypes.GET_TEAM_LEADER_DASHBOARD_COUNT_FAILURE:
      return {
        ...state,
        isdashboardteamleadercount: false,
        error: action.error,
      };
    case ActionTypes.GET_COMPANY_DASHBOARD_COUNT_REQUEST:
      return {
        ...state,
        isdashboardcompanycount: true,
      };
    case ActionTypes.GET_COMPANY_DASHBOARD_COUNT_SUCCESS:
      return {
        ...state,
        dashboardcompanycount: action.data,
        isdashboardcompanycount: false,
      };
    case ActionTypes.GET_COMPANY_DASHBOARD_COUNT_FAILURE:
      return {
        ...state,
        isdashboardcompanycount: false,
        error: action.error,
      };
    default:
      return state;
  }
}
export default dashboardusercountReducer;