import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import * as CompanyRegistrationActions from './comapnyRegisterActions';
import * as LoginActions from '../login/loginActions';
import * as CategoryListActions from '../categorylist/categorylistActions';
import { NotificationContainer } from '../../component/notificationContainer';
import { notification } from '../../component/notificationContainer/notification';
import { rememberMeKey, passKey, identifier, tokenkey, encryptStorage } from '../../utils/auth';
import { GoogleLoginButton, LinkedInLoginButton, FacebookLoginButton } from 'react-social-login-buttons';
import { LoginSocialGoogle, LoginSocialLinkedin, LoginSocialFacebook } from 'reactjs-social-login';
import { SIGNIN, TERMSCONDITIONS } from '../../global/routes';
import { validationRegex } from '../../utils/conts';
import { pwdTooltipContent } from '../../utils/helpers';
import { BACKEND_URL } from '../../global/url';
import styles from './styles.module.scss';
import CompanyRegisterForm from '../../component/companyRegisterForm';

/**
 * @author      Nandani.V.Patel
 * @date        14th Oct 2024
 * @description UI Implementation of Company Register
 * @param
 * @response 
**/

const CompanyRegister = (props) => {
  const { register, handleSubmit, resetField, getValues, formState: { errors }, watch } = useForm();
  const { companySignup, actions } = props;
  const dispatch = useDispatch();
  const signin = useSelector((state) => state?.signin);
  const categories = useSelector((state) => state?.categorylist);
  const [checked, setchecked] = useState(false);
  const [firstInit, setFirstInit] = useState(false);
  const [countries, setCountries] = useState([]);
  const [category, setCategories] = useState([]);
  const [isredirect, setIsRedirect] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (firstInit) {
      actions.getCountriesRequest();
      dispatch(CategoryListActions.getCategorylistRequest());
      setFirstInit(false);
    }
  }, [firstInit]);

  useEffect(() => {
    setFirstInit(true);
  }, []);

  useEffect(() => {
    if (typeof companySignup?.countries?.data !== 'undefined') {
      setCountries(companySignup.countries.data);
    }
  }, [companySignup]);

  useEffect(() => {
    if (typeof categories?.data?.data !== 'undefined') {
      setCategories(categories?.data?.data);
    }
  }, [categories]);

  const handleChange = () => {
    setchecked((Checked) => !Checked);
  }

  useEffect(() => {
    if (companySignup?.data !== undefined && companySignup?.successMsg) {
      localStorage.removeItem(tokenkey);
      encryptStorage.removeItem(rememberMeKey);
      encryptStorage.removeItem(identifier);
      encryptStorage.removeItem(passKey);
      notification(companySignup?.successMsg, 'success');
      setTimeout(() => {
        setIsRedirect(true);
      }, 2000);
      dispatch({ type: CompanyRegistrationActions.ActionTypes.COMPANY_REGISTRATION_SUCCESS, data: undefined });
      resetField('category');
      resetField('company_name');
      resetField('company_logo');
      resetField('company_established_date');
      resetField('company_website_url');
      resetField('company_country');
      resetField('company_description');
      resetField('company_address');
      resetField('pincode');
      resetField('contact_person_name');
      resetField('contact_person_email');
      resetField('email');
      resetField('phone_number');
      resetField('password');
      resetField('company_registration_number');
      resetField('password_confirmation');
      setchecked(false);
    }
    if (signin?.facebookLogindata !== undefined && signin?.facebookLogindata?.status === 'success' && !signin.isfacebookLogin) {
      notification(signin?.facebookLogindata?.message, 'success');
      setTimeout(() => {
        setIsRedirect(true);
      }, 2000);
      dispatch({ type: LoginActions.ActionTypes.FACEBOOK_LOGIN_SUCCESS, facebookLogindata: undefined });
    }
    if (signin?.googleLogindata !== undefined && signin?.googleLogindata?.status === 'success' && !signin.isGoogleLogin) {
      notification(signin?.googleLogindata?.message, 'success');
      setTimeout(() => {
        setIsRedirect(true);
      }, 2000);
      dispatch({ type: LoginActions.ActionTypes.GOOGLE_LOGIN_SUCCESS, googleLogindata: undefined });
    }
    if (signin?.linkedinLogindata !== undefined && signin?.linkedinLogindata?.status === 'success' && !signin.isLinkedinLogin) {
      notification(signin?.linkedinLogindata?.message, 'success');
      setTimeout(() => {
        setIsRedirect(true);
      }, 2000);
      dispatch({ type: LoginActions.ActionTypes.LINKEDIN_LOGIN_SUCCESS, linkedinLogindata: undefined });
    }
    if ((signin?.data === undefined || signin?.facebookLogindata === undefined || signin?.googleLogindata === undefined || signin?.linkedinLogindata === undefined) && signin.error !== undefined && signin?.error?.message) {
      notification(signin?.error?.message, 'error');
      setIsRedirect(false);
    }
    if (companySignup?.error?.message && !companySignup.isCompanyRegistration) {
      notification(companySignup?.error?.message, 'error');
      setIsRedirect(false);
      dispatch({ type: CompanyRegistrationActions.ActionTypes.COMPANY_REGISTRATION_FAILURE, error: "" });
    }
    dispatch({ type: LoginActions.ActionTypes.FACEBOOK_LOGIN_FAILURE, error: undefined });
    dispatch({ type: LoginActions.ActionTypes.GOOGLE_LOGIN_FAILURE, error: undefined });
    dispatch({ type: LoginActions.ActionTypes.LINKEDIN_LOGIN_FAILURE, error: undefined });
  }, [signin?.data, signin.isLogin, signin?.facebookLogindata, signin?.googleLogindata, signin?.linkedinLogindata, signin?.error, companySignup.data, companySignup.errorMsg, companySignup.isCompanyRegistration]);

  useEffect(() => {
    if (isredirect) {
      navigate(SIGNIN);
    }
  }, [isredirect])

  const onSubmit = (data) => {
    const file = data.company_logo[0];
    const password = getValues('password');
    const password_confirmation = getValues('password_confirmation');

    if (password !== password_confirmation) {
      notification('Password and Confirm-Password must be same.', 'error');
    } else if (!checked) {
      notification('Please accept terms and conditions.', 'warning');
    } else {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }
      formData.append('company_logo', file);
      formData.append('user_role', "company");

      actions.companyregistrationRequest(formData);
    }
  }

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      handleSubmit(onSubmit)();
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', handleKeyPress);
    return () => document.removeEventListener('keyup', handleKeyPress);
  }, [])

  return (
    <>
      <div className="register-area ptb-50">
        <div className="container">
          <div className="register">
            <h3>Company Registration</h3>

            <CompanyRegisterForm
              register={register}
              errors={errors}
              watch={watch}
            />

            <div>
              <div className={`${styles['form-check']} ${styles.condition}`}>
                <input
                  className={`${styles['form-check-input']} ${styles.formcheckbox}`}
                  id="flexCheckDefault"
                  type="checkbox"
                  onChange={handleChange}
                  checked={checked}
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  I agree to the <Link to={TERMSCONDITIONS}>Terms & Conditions</Link> for registration.
                </label>
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="default-btn btn"
                onClick={handleSubmit(onSubmit)}
                disabled={companySignup.isCompanyRegistration}
              >
                {!companySignup.isCompanyRegistration
                  ? <>Register</>
                  : <div className="spinner-border " style={{ width: '1.2rem', height: '1.2rem' }} role="status" />
                }
              </button>
            </div>
            <Link to={SIGNIN} className={styles.link}>Already have an Account?</Link>

            <div className="single-footer-widget quick-link" />
            <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0', width: '100%' }}>
              <hr style={{ flex: 1, border: '1px solid #ccc' }} />
              <span style={{ margin: '0 10px', color: '#999', fontWeight: '500' }}>or Register with</span>
              <hr style={{ flex: 1, border: '1px solid #ccc' }} />
            </div>

            <div className="social-login-buttons" style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div style={{ width: '100%' }}>
                <LoginSocialGoogle
                  client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  scope='openid profile email'
                  discoveryDocs='claims_supported'
                  access_type='offline'
                  typeResponse='accessToken'
                  onResolve={(response) => {
                    console.log('google login', response);
                    if (response?.data?.code !== undefined && response?.data?.code !== null && response?.data?.code !== '') {
                      const data = {
                        code: response?.data?.code,
                        user_role: "company"
                      };
                      dispatch(LoginActions.googleLoginRequest(data));
                    }
                  }}
                  onReject={(err) => {
                    console.log('google login err:', err);
                    notification('Somthing want to wrong', 'error');
                  }}
                >
                  <GoogleLoginButton
                    style={{
                      boxShadow: 'rgba(0, 0, 0, 0.2) -1px -1px 1px, rgba(0, 0, 0, 0.2) 1px 1px 1px',
                    }}
                    className={`${styles.socialbtn}`}
                  >
                    Google
                  </GoogleLoginButton>
                </LoginSocialGoogle>
              </div>
              <div style={{ width: '100%' }}>
                <LoginSocialLinkedin
                  client_id={process.env.REACT_APP_LINLEDIN_CLIENT_ID}
                  client_secret={process.env.REACT_APP_LINKEDIN_CLIENT_SECRET_KEY}
                  onResolve={(response) => {
                    console.log('onResolve triggered', response); // Added log
                    if (response?.data?.access_token) {
                      const data = {
                        accessToken: response?.data?.access_token,
                        user_role: "company"
                      };
                      dispatch(LoginActions.linkedinLoginRequest(data));
                    } else {
                      console.warn('Access token not found in response');
                      notification('Somthing want to wrong', 'error');
                    }
                  }}
                  scope='profile%20email%20openid'
                  onReject={(err) => {
                    console.error('Login error:', err);
                  }}
                  redirect_uri={`${BACKEND_URL}login`}
                >
                  <LinkedInLoginButton className={styles.socialbtn}>LinkedIn</LinkedInLoginButton>
                </LoginSocialLinkedin>
              </div>
              <div style={{ width: '100%' }}>
                <LoginSocialFacebook
                  appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                  onResolve={(response) => {
                    console.log('facebook login', response);
                    if (response?.data?.accessToken !== undefined && response?.data?.accessToken !== null && response?.data?.accessToken !== '') {
                      const data = {
                        accessToken: response?.data?.accessToken,
                        user_role: "company"
                      };
                      dispatch(LoginActions.facebookLoginRequest(data));
                    }
                  }}
                  onReject={(err) => {
                    console.log('facebook login err', err);
                    notification('Somthing want to wrong', 'error');
                  }}
                  version='v21.0'
                  language='en_EN'
                >
                  <FacebookLoginButton className={`${styles.socialbtn}`}>Facebook</FacebookLoginButton>
                </LoginSocialFacebook>
              </div>
            </div>
          </div>
        </div>
      </div >
      <NotificationContainer />
    </>
  )
}
const mapStateToProps = (state) => ({
  companySignup: state.companySignup,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(CompanyRegistrationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyRegister);
