/**
 * @author      Nandani.V.Patel
 * @date        16 Oct 2024
 * @description ActionTypes and ActionCreator Function for Company Purchase Review.
 * @param
 * @response      
**/

export const ActionTypes = {
  GET_PURCHASE_REVIEW_REQUEST: 'GET_PURCHASE_REVIEW_REQUEST',
  GET_PURCHASE_REVIEW_SUCCESS: 'GET_PURCHASE_REVIEW_SUCCESS',
  GET_PURCHASE_REVIEW_FAILURE: 'GET_PURCHASE_REVIEW_FAILURE',
};

export const getpurchaseReviewRequest = () => {
  return {
    type: ActionTypes.GET_PURCHASE_REVIEW_REQUEST,
  };
};