import { ActionTypes } from './categorylistActions';

/**
 * @author      Nandani.V.Patel
 * @date        14 Oct 2024
 * @description Category Reducer.
 * @param       takes default and previous state value
 * @response    update a state value and store Category api response  
**/

const defaultState = {
  isCategory: false,
  data: undefined,
};

const categorylistReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CATEGORY_LIST_REQUEST:
      return {
        ...state,
        isCategory: true,
      };
    case ActionTypes.GET_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        data: action.data,
        isCategory: false,
      };
    case ActionTypes.GET_CATEGORY_LIST_FAILURE:
      return {
        ...state,
        isCategory: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default categorylistReducer;
