/**
 * @author      Nandani.V.Patel
 * @date        14 Oct 2024
 * @description ActionTypes and ActionCreator Function for reset-password Request.
 * @param
 * @response      
**/

export const ActionTypes = {
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

};

export const resetpasswordRequest = (resetpasswordData) => {
  return {
    type: ActionTypes.RESET_PASSWORD_REQUEST,
    payload: resetpasswordData,
  };
};
