import React from 'react';
import styles from './styles.module.scss'
import classNames from 'classnames';

/**
 * @author      Nandani.V.Patel
 * @date        8th Oct 2024
 * @description UI Implementation of Dashboard PageBanner
 * @param
 * @response 
**/

const PageBanner = ({ title }) => {
  return (
    <>
      <div
        className={classNames([`page-banner-area bg-f0f4fc ${styles.bgcolors}`])}
        style={{ paddingTop: '150px' }}
      >
        <div className="container">
          <div className="page-banner-content">
            <h1
              className={classNames([styles.title])}
            >
              {title}
            </h1>
          </div>
        </div>
      </div>
    </>
  )
}
export default PageBanner;
