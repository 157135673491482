import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './companylistActions';
import { getRequestAPI, postRequestAPI } from '../../global/api';
import { defaultErrorMsg } from '../../utils/conts';

/**
 * @author      Nandani.V.Patel
 * @date        15 Oct 2024
 * @description Registration  Saga and handling API response.
 * @param       company-listing Request paylod
 * @response    company listing  is success or fail.  
**/

function* companylistSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'get-companies', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.COMPANY_LISTING_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.COMPANY_LISTING_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.COMPANY_LISTING_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* websiteSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'get-websites' });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.GET_WEBSITE_SUCCESS, data: response.data.data });
    } else {
      yield put({ type: ActionTypes.GET_WEBSITE_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_WEBSITE_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* getPendingTargetCompaniesReq(action) {
  try {
    const response = yield call(getRequestAPI, { url: 'company-list', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.GET_PENDING_TARGET_COMPANIES_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.GET_PENDING_TARGET_COMPANIES_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_PENDING_TARGET_COMPANIES_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* watchCompanyListingRequest() {
  yield takeLatest(ActionTypes.COMPANY_LISTING_REQUEST, companylistSaga);
  yield takeLatest(ActionTypes.GET_WEBSITE_REQUEST, websiteSaga);
  yield takeLatest(ActionTypes.GET_PENDING_TARGET_COMPANIES, getPendingTargetCompaniesReq);
}

function* companyListingSaga() {
  yield fork(watchCompanyListingRequest);
}

export default companyListingSaga;