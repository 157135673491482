import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DataTable from 'react-data-table-component';
import * as TeamLeaderRevenueActions from './teamleaderRevenueActions';
import * as TeamLeaderDashboardActions from '../dashboard/dashboardcountActions';
import StatsBlock from '../dashboard/statsBlock';

import styles from './styles.module.scss';

/**
 * @author      Nandani.V.Patel
 * @date        30th Oct 2024
 * @description UI Implementation of Team-leader Revenue
 * @param
 * @response 
**/

const TeamLeaderRevenue = (props) => {
  const { teamleaderRevenue, dashboardCount, TLdashboardActions, actions } = props;
  const [firstInit, setFirstInit] = useState(false);
  const [perPage, setPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [teamleaderrevenue, setteamleaderrevenue] = useState('');

  useEffect(() => {
    if (firstInit) {
      // TLdashboardActions.getdashboardCommission();
      TLdashboardActions.getTeamleaderashboardcount();
      actions.getTeamleaderRevenueRequest({ page, per_page: perPage });
      setFirstInit(false);
    }
  }, [firstInit, page, perPage]);

  useEffect(() => {
    setFirstInit(true);
  }, [page, perPage]);

  useEffect(() => {
    if (typeof teamleaderRevenue?.data !== 'undefined' && !teamleaderRevenue?.isuserRevenue) {
      setteamleaderrevenue(teamleaderRevenue?.data?.data);
    }
  }, [teamleaderRevenue?.data, teamleaderRevenue?.isuserRevenue]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setPage(1); // Reset to first page when rows per page changes
  };

  const columns = [
    {
      name: 'User',
      selector: row => `${row?.user_first_name || '-'} ${row?.user_last_name || '-'}`,
      cell: (row) => (
        <p>{row?.user_first_name || '-'} {row?.user_last_name || '-'}</p>
      ),
      sortable: true,
      width: '150px',
    },
    {
      name: 'Company',
      selector: row => row?.review_site_tbl_name || '-',
      cell: (row) => (
        <p>{row?.company || '-'}</p>
      ),
      width: '150px',
    },
    {
      name: 'Review Site',
      selector: row => row?.review_site_tbl_name || '-',
      cell: (row) => (
        <p>{row?.review_site_tbl_name || '-'}</p>
      ),
      width: '150px',
    },
    {
      name: 'Review Content',
      selector: (row) => row?.review_content || "",
      cell: (row) => (
        <div
          style={{ paddingBottom: '10px', paddingTop: '10px' }}
          className={styles["custom-cell"]}
        >
          {/* {row?.review_content || ""} */}
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
        </div>
      ),
      minWidth: '330px',
      maxWidth: '600px',
    },
    {
      name: 'User Earning',
      width: '130px',
      selector: (row) => (row?.review_site_tbl_user_review_earning || 0),
      cell: (row) => (
        <p>Rs. {row?.review_site_tbl_user_review_earning || 0}</p>
      )
    },
    {
      name: 'Commision',
      width: '130px',
      selector: (row) => (row?.commission_amount || 0),
      cell: (row) => (
        <p>Rs. {row?.commission_amount || 0}</p>
      )
    }
  ];

  return (
    <>
      <div className='teamleader-dashboard dashboard-fun-fact-area'>
        <div className="row">
          <StatsBlock
            icon="fa-regular fa-hourglass-half"
            label="Pending Reviews"
            count={dashboardCount?.dashboardteamleadercount?.pending || 0}
          />
          <StatsBlock
            icon="fa-regular fa-thumbs-up"
            label="Approved Reviews"
            count={dashboardCount?.dashboardteamleadercount?.approved || 0}
          />
          <StatsBlock
            icon="fa fa-xmark"
            label="Rejected Reviews"
            count={dashboardCount?.dashboardteamleadercount?.rejected || 0}
          />
          <StatsBlock
            icon="flaticon-coin"
            label="Wallet Balance"
            count={dashboardCount?.dashboardCommission?.data || 0}
            additionalClass="wallet-stats-box"
          />
        </div>
      </div>
      <div className={`d-flex p-3 mb-4 header-box`}>
        <h4 className={`col-md-12 header-text`}>
          Revenue
        </h4>
      </div>
      <DataTable
        columns={columns}
        data={teamleaderrevenue}
        theme="solarized"
        fixedHeader
        pagination
        paginationServer
        paginationPerPage={perPage}
        paginationTotalRows={teamleaderRevenue?.data?.total}
        paginationRowsPerPageOptions={[5, 15, 25, 50, 100]}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerPageChange}
        responsive
      />
    </>
  )
}
const mapStateToProps = (state) => ({
  teamleaderRevenue: state.teamleaderRevenue,
  dashboardCount: state.userdashboardcount
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(TeamLeaderRevenueActions, dispatch),
  TLdashboardActions: bindActionCreators(TeamLeaderDashboardActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamLeaderRevenue);