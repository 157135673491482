import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './categorylistActions';
import { postRequestAPI } from '../../global/api';
import { defaultErrorMsg } from '../../utils/conts';

/**
 * @author      Nandani.V.Patel
 * @date        14 Oct 2024
 * @description Category Saga and handling API response.
 * @param       
 * @response    Category is successfully add or not.  
**/

function* getCategorieslistSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'get-category', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.GET_CATEGORY_LIST_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.GET_CATEGORY_LIST_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_CATEGORY_LIST_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* watchCategorylistRequest() {
  yield takeLatest(ActionTypes.GET_CATEGORY_LIST_REQUEST, getCategorieslistSaga);
}

function* categorylistSaga() {
  yield fork(watchCategorylistRequest);
}

export default categorylistSaga;
