import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAccountType } from '../../layouts/AccountTypeContext';
import styles from './styles.module.scss';

/**
 * @author      Nandani.V.Patel
 * @date        8th Oct 2024
 * @description UI Implementation of Create Account Type for team-leader, user
 * @param
 * @response 
**/

const CreateAccount = () => {
  // const { setAccountType } = useAccountType();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <div className="text-center emp-res login-area ptb-50" style={{ paddingTop: '190px' }}>
        <h1>Registration</h1>
        <p>Join as a User or Team Leader and unlock access to powerful tools for managing your review collection process.</p>
      </div>
      <div className={`row ${styles.rows} justify-content-center`}>
        <div className="col-lg-6 col-md-8 col-sm-10" style={{ marginTop: '12px', marginBottom: '12px' }}>
          <div className="chainge-password-box d-flex flex-column" style={{ backgroundColor: '#f0f4fc', height: '100%', width: '100%' }}>
            <div className="password-form flex-grow-1 d-flex flex-column align-items-start justify-content-between">
              <div className="m-b30 d-flex flex-column align-items-start" style={{ width: '100%' }}>
                <img src="/assets/images/users.png" width={80} alt="" className={styles.imgs} style={{ marginBottom: '10px' }} />
                <h3 style={{ margin: 0, paddingLeft: '0px' }}>Team Leader</h3>
              </div>
              <div className="form-group">
                <label>
                  Lead a team of reviewers, monitor members' reviews, and earn commissions based on your team's performance while helping companies gather authentic feedback.
                </label>
              </div>
              <div>
                {/* another approch for get account type */}
                <Link to={{ pathname: '/registration', search: '?accountType=teamLeader' }} className="default-btn btn">
                  {/* <Link to="/registration" onClick={() => setAccountType('teamLeader')} className="default-btn btn"> */}
                  Register
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-8 col-sm-10" style={{ marginTop: '12px', marginBottom: '12px' }}>
          <div className="chainge-password-box d-flex flex-column" style={{ backgroundColor: '#f0f4fc', height: '100%', width: '100%' }}>
            <div className="password-form flex-grow-1 d-flex flex-column align-items-start justify-content-between">
              <div className="m-b30 d-flex flex-column align-items-start" style={{ width: '100%' }}>
                <img src="/assets/images/team-leader.png" width={80} alt="" className={styles.imgs} style={{ marginBottom: '10px' }} />
                <h3 style={{ margin: 0, paddingLeft: '0px' }}>User</h3>
              </div>
              <div className="form-group">
                <label>
                  Write reviews and earn rewards while helping companies gather genuine feedback from various platforms.
                </label>
              </div>
              <div>
                {/* another approch for get account type */}
                <Link to={{ pathname: '/registration', search: '?accountType=user' }} className="default-btn btn">
                  {/* <Link to="/registration" onClick={() => setAccountType('user')} className="default-btn btn"> */}
                  Register
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default CreateAccount;
