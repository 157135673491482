import {
  DASHBOARD,
  PROFILE,
  CHANGEPASSWORD,
  REVIEWS,
  TEAMLEADERUSERS,
  PURCHASE_REVIEWS,
  TRANSACTION_HISTORY,
  ORDER_DETAILS,
  REVENUE,
  WITHDRAWAL,
  SUBTL_LIST,
  CHECKOUT_PAGE,
  EMPCOMPANIES,
  MYTARGETS,
  EMPEARNINGS
} from '../../global/routes';

const dashboardNavigation = [
  {
    title: 'Dashboard',
    href: DASHBOARD,
    icon: <img src="/assets/images/svg-icon/icon-1.svg" alt="DASHBOARD" />,
  },
];

const commonNavigations = [
  {
    title: 'My Profile',
    href: PROFILE,
    icon: <img src="/assets/images/svg-icon/icon-8.svg" alt="PROFILE" />,
  },
  {
    title: 'Change Password',
    href: CHANGEPASSWORD,
    icon: <img src="/assets/images/svg-icon/icon-9.svg" alt="Change Password" />,
  },
  {
    title: 'Logout',
    href: null,
    icon: <img src="/assets/images/svg-icon/icon-11.svg" alt="Logout" />,
    action: 'logout',
  }
];

export const userNavigationData = [
  ...dashboardNavigation,
  {
    title: 'My Reviews',
    href: REVIEWS,
    icon: <img src="/assets/images/svg-icon/icon-7.svg" alt="REVIEWS" />,
  },
  {
    title: 'Withdrawal',
    href: WITHDRAWAL,
    icon: <i className="fa-regular fa-credit-card"></i>,
  },
  ...commonNavigations
];

export const teamleaderNavigationData = [
  ...dashboardNavigation,
  {
    title: 'Team Members',
    href: TEAMLEADERUSERS,
    icon: <i className="fa-solid fa-users"></i>,
  },
  {
    title: 'Sub-Team Leaders',
    href: SUBTL_LIST,
    icon: <i className="fa-solid fa-user-group"></i>,
    hideInSubTL: true
  },
  {
    title: 'My Targets',
    href: MYTARGETS,
    icon: <i className="fa fa-bullseye"></i>,
  },
  {
    title: 'Revenue',
    href: REVENUE,
    icon: <i className="fa fa-chart-simple"></i>,
  },
  {
    title: 'Withdrawal',
    href: WITHDRAWAL,
    icon: <i className="fa-regular fa-credit-card"></i>,
  },
  ...commonNavigations,
];

export const companiesNavigationData = [
  ...dashboardNavigation,
  {
    title: 'Purchase Reviews',
    href: PURCHASE_REVIEWS,
    icon: <img src="/assets/images/svg-icon/icon-4.svg" alt="PURCHASE_REVIEWS" />,
  },
  {
    title: 'Checkout',
    href: CHECKOUT_PAGE,
    icon: "",
    hideSection: true
  },
  {
    title: 'Transaction History',
    href: TRANSACTION_HISTORY,
    icon: <img src="/assets/images/svg-icon/icon-3.svg" alt="TRANSACTION_HISTORY" />,
  },
  {
    title: 'Campaign Details',
    href: ORDER_DETAILS,
    icon: <i className="flaticon-list"></i>,
  },
  {
    title: 'Users',
    href: null,
    icon: "",
    hideSection: true
  },
  ...commonNavigations
];

export const employeeNavigationData = [
  ...dashboardNavigation,
  {
    title: 'Company List',
    href: EMPCOMPANIES,
    icon: <i className="flaticon-list"></i>,
  },
  {
    title: 'My Targets',
    href: MYTARGETS,
    icon: <i className="fa fa-bullseye"></i>,
  },
  {
    title: 'Earnings Overview',
    href: EMPEARNINGS,
    icon: <i className="fa fa-sack-dollar"></i>,
  },
  {
    title: 'Withdrawal',
    href: WITHDRAWAL,
    icon: <i className="fa-regular fa-credit-card"></i>,
  },
  ...commonNavigations
];