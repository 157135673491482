/**
 * @author      Nandani.V.Patel
 * @date        14 Oct 2024
 * @description ActionTypes and ActionCreator Function for Category-list Request.
 * @param
 * @response      
**/

export const ActionTypes = {
  GET_CATEGORY_LIST_REQUEST: 'GET_CATEGORY_LIST_REQUEST',
  GET_CATEGORY_LIST_SUCCESS: 'GET_CATEGORY_LIST_SUCCESS',
  GET_CATEGORY_LIST_FAILURE: 'GET_CATEGORY_LIST_FAILURE',
};

export const getCategorylistRequest = (paginationData) => {
  return {
    type: ActionTypes.GET_CATEGORY_LIST_REQUEST,
    payload: paginationData,
  };
};
