import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { assignCompanyTargets } from '../redux/actions';

import styles from "../styles.module.scss";

const AssignTargetModal = ({ onClose, companyData }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    mode: 'onChange'
  });

  const propState = useSelector((state) => ({
    loading: state.employeePanel.assignTargetLoader,
    errorMsg: state.employeePanel.errorMsg,
    successMsg: state.employeePanel.successMsg || ""
  }));

  const onSubmit = (data) => {
    const payloadData = {
      company_id: companyData.id,
      employee_target_id: companyData.employee_target_id,
      review_target: parseInt(data.assign_target)
    };

    dispatch(assignCompanyTargets(payloadData));
  };

  useEffect(() => {
    if (propState.successMsg) {
      reset();
    }
  }, [propState.successMsg]);

  return (
    <>
      <div
        className="modal fade show d-block"
        id="assignTargetModal"
        tabIndex="-1"
        aria-labelledby="assignTargetModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className='text-light'>Assign Target</h3>
              <button
                type="button"
                className="btn-close btn-close-white text-light"
                onClick={onClose}
                aria-label="Close"
              />
            </div>
            <div className="modal-body review-form">
              <div className="row d-block">
                <div className="form-group">
                    <input
                      id="assign_target"
                      type="number"
                      {...register("assign_target", {
                        required: "Target Count is required",
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "Only numbers are allowed"
                        },
                      })}
                      min={0}
                      className="form-control mt-2"
                      placeholder="Target Count"
                    />
                    {errors.assign_target && (
                      <span className={`text-danger ${styles.errorMsg}`}>{errors.assign_target.message}</span>
                    )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn blue-btn"
                onClick={handleSubmit(onSubmit)}
                disabled={propState.loading}
              >
                {propState.loading ? <span className="spinner-border spinner-border-sm"></span> : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" onClick={onClose}></div>
    </>
  )
}

export default AssignTargetModal;