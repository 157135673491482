import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { SUBTL_LIST } from '../../global/routes';
import { createSubTLReq } from './redux/actions';
import RegistrationForm from '../../component/registrationForm';
import Breadcrumb from '../../component/breadcrumbs';
import { notification } from '../../component/notificationContainer/notification';
import { NotificationContainer } from '../../component/notificationContainer';

const CreateSubTL = () => {
  const {
    register,
    formState: { errors },
    getValues,
    resetField,
    handleSubmit
  } = useForm({
    mode: 'onChange'
  });

  const dispatch = useDispatch();
  const subTLState = useSelector(({ teamleaderSubTL }) => ({
    successMsg: teamleaderSubTL.successMsg,
    errorMsg: teamleaderSubTL.errorMsg,
    loading: teamleaderSubTL.loading
  }));

  const breadcrumbs = [
    { name: 'Sub-Team Leaders', link: SUBTL_LIST },
    { name: 'Sub-TL Registration', link: "" },
  ];

  useEffect(() => {
    if (subTLState.successMsg) {
      notification(subTLState.successMsg, 'success');
    }
    if (subTLState.errorMsg) {
      notification(subTLState.errorMsg, 'error');
    }
  }, [subTLState.successMsg, subTLState.errorMsg]);

  const onSubmit = (data) => {
    const payloadData = {
      ...data,
      user_role: 'SubTeamLeader'
    };

    dispatch(createSubTLReq(payloadData));
  };

  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div className={`d-flex p-3 mb-4 header-box`}>
        <h4 className={`col-md-12 header-text`}>
          Sub-TL Registration
        </h4>
      </div>
      <div className="my-profile-area">
        <div className="profile-form-content">
          <div className="profile-form">
            <RegistrationForm
              register={register}
              errors={errors}
              getValues={getValues}
              resetField={resetField}
            />

            <button
              type="submit"
              className="blue-btn rounded"
              onClick={handleSubmit(onSubmit)}
              disabled={subTLState.loading}
            >
              {!subTLState.loading ? (
                "Register"
              ) : (
                <>
                  <div
                    className="spinner-border"
                    style={{ width: "1.2rem", height: "1.2rem" }}
                    role="status"
                  />
                </>
              )}
            </button>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </>
  );
}

export default CreateSubTL;