import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './registerActions';
import { postRequestAPI, postWithoutHeaderRequestAPI } from '../../global/api';
import { userId, encryptStorage } from '../../utils/auth';
import { defaultErrorMsg } from '../../utils/conts';

/**
 * @author      Nandani.V.Patel
 * @date        09 Oct 2024
 * @description Registration  Saga and handling API response.
 * @param       register-user Request paylod
 * @response    user registration  is success or fail.  
**/

function* registerUserSaga(action) {
  try {
    const response = yield call(postWithoutHeaderRequestAPI, { url: 'register', data: action.payload });
    if (response?.data?.status === 'success') {
      encryptStorage.setItem(userId, response?.data?.user?.id);
      yield put({ type: ActionTypes.REGISTRATION_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.REGISTRATION_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.REGISTRATION_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* watchRegistrationRequest() {
  yield takeLatest(ActionTypes.REGISTRATION_REQUEST, registerUserSaga);
}

function* signUpSaga() {
  yield fork(watchRegistrationRequest);
}

export default signUpSaga;