import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './reviewsActions';
import { postRequestAPI } from '../../global/api';
import { defaultErrorMsg } from '../../utils/conts';

/**
 * @author      Nandani.V.Patel
 * @date        22 Oct 2024
 * @description review  Saga and handling API response.
 * @param       review-listing Request paylod
 * @response    review listing  is success or fail.  
**/

function* reviewsSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'reviews', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.REVIEW_LISTING_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.REVIEW_LISTING_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.REVIEW_LISTING_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* watchReviewRequest() {
  yield takeLatest(ActionTypes.REVIEW_LISTING_REQUEST, reviewsSaga);
}

function* reviewSaga() {
  yield fork(watchReviewRequest);
}

export default reviewSaga;