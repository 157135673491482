import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { CREATEEMPCOMPANY, EMPCOMPANYTARGETHISTORY } from '../../../global/routes';
import AssignTargetModal from './assignTargetModal';
import { getEmpCompanies, clearStates } from '../redux/actions';
import WarningModal from '../../../component/modals/warningModal';
import { notification } from '../../../component/notificationContainer/notification';
import { NotificationContainer } from '../../../component/notificationContainer';

const EmployeeCompanyList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [showWarnModal, setShowWarnModal] = useState(false);
  const [rowData, setRowData] = useState({});
  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const propState = useSelector(({ employeePanel }) => ({
    empCompanyList: employeePanel.empCompanyList,
    loading: employeePanel.loading,
    errorMsg: employeePanel.errorMsg,
    successMsg: employeePanel.successMsg
  }));

  useEffect(() => {
    return () => {
      dispatch(clearStates());
    }
  }, []);

  useEffect(() => {
    const payload = {
      per_page: rowsPerPage,
      page: currentPage
    };

    dispatch(getEmpCompanies(payload));
  }, [dispatch, rowsPerPage, currentPage]);

  const customStyles = {
    headCells: {
      style: {
        paddingTop: '18px',
        paddingBottom: '18px',
      }
    },
    cells: {
      style: {
        paddingTop: '10px',
        paddingBottom: '10px',
      }
    }
  };

  const columns = [
    {
      name: "Company Name",
      selector: (row) => row?.company_tbl_name,
      cell: (row) => (
        <>
          {/* <div className="title-logo">
            {row.user_image ? (
              <img
                src={row.user_image}
                alt="Profile"
                className="user-image"
                width={50}
                height={50}
                style={{ margin: '10px' }}
              />
            ) : (
              <i
                className="flaticon-company"
                style={{ margin: '10px', fontSize: '50px', height: '50px', width: '50px', textAlign: 'center' }}
              />
            )}
          </div> */}
          <div>{row?.company_tbl_name || '-'}</div>
        </>
      ),
      sortable: true,
    },
    {
      name: "Category",
      selector: (row) => row?.category_name,
      cell: (row) => row?.category_name || '-',
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row?.company_email,
      cell: (row) => row?.company_email || '-',
      sortable: true,
    },
    {
      name: "Latest Assigned Target",
      selector: (row) => row.assigned_target,
      cell: (row) => row?.assigned_target || 0,
      sortable: true,
    },
    {
      name: "Completion Rate",
      selector: (row) => row.completion_rate,
      cell: (row) => `${row?.completion_rate || 0}%`,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <div className="action-btn option-list d-flex gap-2">
            <button
              className="option-btn blue-action-btn d-inline-block"
              type="button"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              aria-label="Assign Target"
              data-bs-original-title="Assign Target"
              title="Assign Target"
              onClick={() =>
                row?.target_status_label.toLowerCase() === "pending"
                  ? setShowWarnModal(true)
                  : handleAssignTarget(row)
              }
            >
              <i className="fa-solid fa-pencil"></i>
            </button>
            {/* <button
              className="option-btn green-action-btn d-inline-block"
              type="button"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              aria-label="View"
              data-bs-original-title="View"
              title="View"
            >
              <i className="fa-solid fa-eye"></i>
            </button> */}
            <button
              className="option-btn purple-action-btn d-inline-block"
              type="button"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              aria-label="Target History"
              data-bs-original-title="Target History"
              title="Target History"
              onClick={() => navigate(EMPCOMPANYTARGETHISTORY, {
                state: { company_id: row?.id, company_name: row?.company_tbl_name }
              })}
            >
              <i className="fa-solid fa-bullseye"></i>
            </button>
          </div>
        </>
      ),
      right: "yes"
    }
  ];

  // store selected row data and handle show modal
  const handleAssignTarget = (row) => {
    setRowData(row);
    setShowModal(true);
  };

  // Pagination event handlers
  const handlePageChange = (page) => setCurrentPage(page);
  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); // Reset to first page when rows per page changes
  };

  useEffect(() => {
    if (propState.successMsg) {
      notification(propState.successMsg, 'success');
    }
    if (propState.errorMsg) {
      notification(propState.errorMsg, 'error');
    }
  }, [propState.successMsg, propState.errorMsg]);

  return (
    <>
      <div className={`d-flex justify-content-between align-items-center p-3 mb-4 header-box`}>
        <h4 className={`col-md-9 header-text`}>
          Company List
        </h4>
        <button
          className={`btn btn-primary col-md-3 add-btn`}
          onClick={() => navigate(CREATEEMPCOMPANY)}
        >
          Register Company
        </button>
      </div>
      <DataTable
        columns={columns}
        data={propState.empCompanyList?.data || []}
        pagination
        paginationServer
        paginationTotalRows={propState.empCompanyList?.total || 0}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        paginationComponentOptions={{
          rowsPerPageText: "Orders per page:",
        }}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        customStyles={customStyles}
        progressPending={propState.loading}
        fixedHeader
        responsive
      />
      <NotificationContainer />
      {showModal && <AssignTargetModal onClose={() => setShowModal(false)} companyData={rowData} />}
      {showWarnModal && (
        <WarningModal
          header="Note"
          warnText="Company already has an ongoing target. Company has to complete the current target before assigning a new one."
          buttonText="Close"
          onClose={() => setShowWarnModal(false)}
        />
      )}
    </>
  )
}

export default EmployeeCompanyList;