import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { getOrders, getSitesByOrder } from '../redux/actions';

import styles from '../styles.module.scss';

const OrderDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const propState = useSelector(({ companyPanel }) => ({
    orderList: companyPanel.orderList?.data || [],
    ordersPagination: companyPanel.orderList?.pagination || {},
    orderedSiteList: companyPanel.orderedSiteList,
    loading: companyPanel.loading,
    orderSitesLoader: companyPanel.orderSitesLoader
  }));  

  // Fetch data on load and whenever pagination state changes
  useEffect(() => {
    dispatch(getOrders({ per_page: rowsPerPage, page: currentPage }));
  }, [dispatch, rowsPerPage, currentPage]);

  // custom style for order table
  const customStyles = {
    headCells: {
      style: {
        verticalAlign: 'middle',
        whiteSpace: 'nowrap',
        padding: '18px 10px',
        fontSize: '18px',
        fontWeight: '600'
      }
    },
    rows: {
      style: {
        borderTop: "solid 1px rgba(0,0,0,.12)",
        borderBottom: "solid 1px rgba(0,0,0,.12)"
      }
    },
    cells: {
      style: {
        verticalAlign: "middle",
        padding: "25px 15px",
        fontWeight: "500",
        fontSize: "16px"
      }
    }
  };

  // order listing table columns
  const orderColumns = [
    {
      id: 'col1',
      name: 'Campaigns',
      selector: (row) => `Campaign ${row.id}`,
      sortable: true
    },
    {
      id: 'col2',
      name: 'Purchased Count',
      selector: (row) => row?.purchased_review_count || 0,
      sortable: true
    },
    {
      id: 'col3',
      name: 'Pending Count',
      selector: (row) => row?.pending_reviews_count || 0,
      sortable: true
    },
    {
      id: 'col4',
      name: 'Purchase Date',
      selector: (row) => row.created_at,
      cell: (row) => new Date(row.created_at).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
      }),
      sortable: true
    }
  ];

  // get purchased sites for each order row which is expanded
  const getPurchasedSites = (expanded, row) => {
    if (expanded) {
      dispatch(getSitesByOrder(row.id));
    }
  };

  // expandable row component to list review sites for each order
  const ExpandableRow = ({ data }) => {
    // custom style for review site list
    const siteTableStyles = {
      table: {
        style: {
          padding: '0 30px',
        }
      },
      headRow: {
        style: {
          borderLeft: "solid 1px rgba(0,0,0,.12)",
          borderRight: "solid 1px rgba(0,0,0,.12)",
          fontWeight: "500",
          fontSize: "14px"
        }
      },
      rows: {
        style: {
          borderLeft: "solid 1px rgba(0,0,0,.12)",
          borderRight: "solid 1px rgba(0,0,0,.12)",
          borderBottom: "solid 1px rgba(0,0,0,.12)",
          fontWeight: "400",
          fontSize: "14px",
          paddingTop: "5px",
          paddingBottom: "5px"
        }
      },
    };

    const viewUsers = (row) => {
      const details = {
        transaction_id: data?.id,
        review_site_id: row?.review_campaign_tbl_review_site_id,
        siteName: row?.review_site_data?.site_name || ""
      };

      navigate(
        `${(row?.review_site_data?.site_name || "").replaceAll(" ", "_")}/users`,
        { state: details }
      );
    }

    // order based review site listing table columns
    const siteColumns = [
      {
        // name: 'Logo',
        name: 'Review Site',
        selector: (row) => row?.review_site_data?.site_name || "-",
        cell: (row) => (
          <>
            <img
              src={row?.review_site_data?.site_logo || ""}
              alt={row?.review_site_data?.site_name}
              width="50px"
              height="50px"
            />
            <span className='ps-3'>{row?.review_site_data?.site_name || "-"}</span>
          </>
        ),
        sortable: true
      },
      {
        name: 'Purchased Count',
        selector: (row) => row?.review_campaign_tbl_purchased_reviews || 0,
        sortable: true
      },
      {
        name: 'Pending Count',
        selector: (row) => row?.remaining_reviews_count || 0,
        sortable: true
      },
      {
        name: 'Action',
        cell: (row) => (
          <div className="action-btn">
            <button
              className="option-btn d-inline-block"
              type="button"
              title='View Users'
              onClick={() => viewUsers(row)}
            >
              <i className="fa-solid fa-eye"></i>
            </button>
          </div>
        ),
        width: '100px',
        right: true
      }
    ];

    return (
      <DataTable
        columns={siteColumns}
        data={propState.orderedSiteList?.[data?.id] || []}
        customStyles={siteTableStyles}
        progressPending={propState.orderSitesLoader}
        responsive
      />
    )
  };

  // Pagination event handlers
  const handlePageChange = (page) => setCurrentPage(page);
  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); // Reset to first page when rows per page changes
  };

  return (
    <div>
      <div className={`p-3 mb-4 header-box`}>
        <h4 className="header-text">Campaign Details</h4>
      </div>
      <div className="order-details-box">
        <div className="order-details-table table-responsive">
          <DataTable
            columns={orderColumns}
            data={propState.orderList}
            customStyles={customStyles}
            defaultSortFieldId="col4"
            defaultSortAsc={false}
            fixedHeader
            expandableRows
            expandOnRowClicked
            onRowExpandToggled={getPurchasedSites}
            expandableRowsComponent={({ data }) => <ExpandableRow data={data} />}
            responsive
            pagination
            paginationServer
            paginationTotalRows={propState.ordersPagination?.total || 0}
            paginationRowsPerPageOptions={[10, 25, 50, 100]}
            paginationComponentOptions={{
              rowsPerPageText: "Orders per page:",
            }}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
          />
        </div>
      </div>
    </div>
  )
}
export default OrderDetails;
