import React, { useEffect, useState } from 'react';
import Preloader from '../Preloader';

/**
 * @author      Nandani.V.Patel
 * @date        8th Oct 2024
 * @description UI Implementation of How To Work Page
 * @param
 * @response 
**/

const WorkFlow = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div className="cv-area ptb-100">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="cv-content style2 pl-15">
                  <h2>Employee</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Feugiat
                    enim a erat sit vulputate elementum orci. Risus nec viverra ornare
                    venenatis proin ac varius tristique ut. Vitae egestas tellus amet
                    nulla cursus.ands Pellentesque placerat maecenas egestas ullamcorper
                    sed nunc. Vitae egestas tellus amet nulla something loss
                    Pellentesque placerat maecenas egestas.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="cv-img-area-style2">
                  <img src="/assets/images/cv/cv-img-3.png" alt="Image" />
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="cv-img-area-style2">
                  <img src="/assets/images/cv/cv-img-3.png" alt="Image" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="cv-content style2 pl-15">
                  <h2>Company</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Feugiat
                    enim a erat sit vulputate elementum orci. Risus nec viverra ornare
                    venenatis proin ac varius tristique ut. Vitae egestas tellus amet
                    nulla cursus.ands Pellentesque placerat maecenas egestas ullamcorper
                    sed nunc. Vitae egestas tellus amet nulla something loss
                    Pellentesque placerat maecenas egestas.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="cv-content style2 pl-15">
                  <h2>Team Leader</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Feugiat
                    enim a erat sit vulputate elementum orci. Risus nec viverra ornare
                    venenatis proin ac varius tristique ut. Vitae egestas tellus amet
                    nulla cursus.ands Pellentesque placerat maecenas egestas ullamcorper
                    sed nunc. Vitae egestas tellus amet nulla something loss
                    Pellentesque placerat maecenas egestas.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="cv-img-area-style2">
                  <img src="/assets/images/cv/cv-img-3.png" alt="Image" />
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="cv-img-area-style2">
                  <img src="/assets/images/cv/cv-img-3.png" alt="Image" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="cv-content style2 pl-15">
                  <h2>Users</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Feugiat
                    enim a erat sit vulputate elementum orci. Risus nec viverra ornare
                    venenatis proin ac varius tristique ut. Vitae egestas tellus amet
                    nulla cursus.ands Pellentesque placerat maecenas egestas ullamcorper
                    sed nunc. Vitae egestas tellus amet nulla something loss
                    Pellentesque placerat maecenas egestas.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default WorkFlow;
