import React, { Fragment, useState, useEffect } from 'react';
import {
  PROFILE,
} from '../../global/routes';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as profileActions from '../../pages/profile/profileActions';
import * as companyprofileActions from '../../pages/companyPanel/redux/actions';
import { tokenkey, encryptStorage, roleId } from "../../utils/auth";

/**
 * @author      Nandani.V.Patel
 * @date        8th Oct 2024
 * @description UI Implementation of Dashboard Navbar
**/

const DashboardNavbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // For mobile menu
  const location = useLocation();
  const dispatch = useDispatch();
  const [userprofile, setUserProfile] = useState('/assets/images/dashboard/dashboard-img-5.jpg');
  const [firstInit, setFirstInit] = useState(false);
  const [showImage, setshowImage] = useState(false);

  let token;
  if (localStorage.getItem(tokenkey) !== null && localStorage.getItem(tokenkey) !== '' && localStorage.getItem(tokenkey) !== 'undefined') {
    token = localStorage.getItem(tokenkey);
  }

  const userRoleId = encryptStorage.getItem(roleId);

  useEffect(() => {
    if (firstInit && (location.pathname !== PROFILE) && token) {
      dispatch(profileActions.getUserDetailsRequest());
      if (userRoleId === 3) {
        dispatch(companyprofileActions.getCompanyDetailsRequest());
      }
      setFirstInit(false);
    }
  }, [firstInit, userRoleId]);

  useEffect(() => {
    setFirstInit(true);
  }, []);

  const profile = useSelector((state) => state?.profile);
  const companyProfile = useSelector((state) => state?.companyPanel);

  useEffect(() => {
    if (typeof profile?.userdata !== 'undefined' && !profile?.isprofile && profile?.userdata?.status === 'success') {
      // setUserProfile(profile?.userdata?.data?.user_image);
      if (profile?.userdata?.data?.user_image) {
        setUserProfile(profile?.userdata?.data?.user_image);
        setshowImage(true);
      } else {
        // setUserProfile('/assets/images/dashboard/dashboard-img-5.jpg');
        setUserProfile('/assets/images/team-leader.png');
        setshowImage(true); // Reset if no user image
      }
      setshowImage(true);
    }
  }, [profile?.userdata, profile?.isprofile, showImage]);

  useEffect(() => {
    if (
      typeof companyProfile?.companydata !== 'undefined' &&
      !companyProfile?.isCompanyDetails &&
      companyProfile?.companydata?.status === 'success'
    ) {
      if (companyProfile?.companydata?.data?.company_tbl_logo) {
        setUserProfile(companyProfile?.companydata?.data?.company_tbl_logo);
        setshowImage(true);
      } else {
        setUserProfile('/assets/images/dashboard/dashboard-img-5.jpg'); // Reset if no user image
        setshowImage(true);
      }
      setshowImage(true);
    }
  }, [companyProfile?.companydata, companyProfile?.isCompanyDetails, showImage]);

  // Toggle Desktop Menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Toggle Mobile Menu
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleMobileSidebar = () => {
    const ele = document.getElementsByClassName('sidemenu-area')[0];
    ele?.classList?.add("active-sidemenu-area");
  }

  return (
    <Fragment>
      {/* Navbar Container */}
      <div
        className="navbar-wrapper navbar-bg"
        style={{
          position: 'fixed',
          top: '0',
          width: '100%',
          zIndex: '1000',
          backgroundColor: '#fff',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          padding: '1rem',
        }}
      >
        {/* Mobile Navbar */}
        <div className="mobile-responsive-nav">
          <div className="container">
            <div className="mobile-responsive-menu" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div className="logo">
                <a>
                  <img src="/assets/images/logo.png" className="main-logo" alt="logo" />
                  <img src="/assets/images/white-logo.png" className="white-logo" alt="logo" />
                </a>
              </div>
              <div className="sidemenu-header">
                <div className="responsive-burger-menu d-block" onClick={handleMobileSidebar}>
                  <i className="fa-solid fa-bars-staggered" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Desktop Navbar */}
        <div className="desktop-nav">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-md navbar-light">
              <a className="navbar-brand">
                {/* Display Logo for Desktop */}
                <img src="/assets/images/logo.png" className="main-logo" alt="logo" />
                <img src="/assets/images/white-logo.png" className="white-logo" alt="logo" />
              </a>
              <div className={`collapse navbar-collapse justify-content-end d-flex ${isMenuOpen ? 'show' : ''}`}>
                <div className="option-inner">
                  <div className="others-options justify-content-end d-flex align-items-center">
                    <div className="option-item">
                      <div className="dropdown profile-nav-item">
                        <a
                          className="dropdown-bs-toggle"
                          role="button"
                        >
                          <div className="menu-profile" style={{ cursor: 'default' }}>
                            {userprofile && (
                              <img
                                src={userprofile}
                                alt="userprofile"
                                className="rounded-circle"
                                style={{ width: '50px', height: '50px' }}
                              />
                            )}
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DashboardNavbar;
