import { ActionTypes } from './reviewsActions';

/**
 * @author      Nandani.V.Patel
 * @date        22 Oct 2024
 * @description reviewlisting Reducer.
 * @param       takes default and previous state value
 * @response    update a state value and store reviewlisting api response  
**/

const defaultState = {
  isReview: false,
};

const reviewsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.REVIEW_LISTING_REQUEST:
      return {
        ...state,
        isReview: true,
      };
    case ActionTypes.REVIEW_LISTING_SUCCESS:
      return {
        ...state,
        data: action.data,
        isReview: false,
      };
    case ActionTypes.REVIEW_LISTING_FAILURE:
      return {
        ...state,
        isReview: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default reviewsReducer;