import React, { useEffect, useState } from 'react';
import Preloader from '../../component/Preloader';

/**
 * @author      Nandani.V.Patel
 * @date        24th Oct 2024
 * @description UI Implementation of Terms-Conditions Page
 * @param
 * @response 
**/

const TermsConditions = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div className="terms-condition-area pt-100 pb-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="condition-content">
                  <h2>Terms &amp; Conditions</h2>
                  <h4>Overview</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                    ipsum suspendisse ultrices gravida. Risus commodo viverrmaecenas
                    accumsan lacus vel facilisis. Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                    labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
                    gravida. commodo viverra maecenas accumsan lacus vel facilisis.
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry's standard dummy text
                    ever since the 1500s, when an unknown printer took a galley of type
                    and scrambled it to make a type specimen book. It has survived not
                    only five centuries, but also the leap into electronic typesetting,
                    remaining essentially unchanged. It was popularised in the 1960s
                    with the release of Letraset sheets containing Lorem Ipsum passages,
                    and more recently with desktop publishing software like Aldus
                    PageMaker including versions of Lorem Ipsum
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                    ipsum suspendisse ultrices gravida. Risus commodo viverra maecen
                    accumsan lacus vel facilisis. Lorem Ipsum is simply dummy text of
                    the printing and typesetting industry. Lorem Ipsum has been the
                    industry's standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a type
                    specimen book. It has survived not only five centuries, but also the
                    leap into electronic typesetting, remaining essentially unchanged.
                    It was popularised in the 1960s with the release of Letraset sheets
                    containing Lorem Ipsum passages, and more recently with desktop
                    publishing software like Aldus PageMaker includi versions of Lorem
                    IpsumLorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's standard
                    dummy text ever since the 1500s, when an unknowprinter took a galley
                    of type and scrambled it to make a type specimen book. It has
                    survived not only five centuries, but also the leap into electronic
                  </p>
                  <h4>When Do We Collect Information</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                    ipsum suspendisse ultrices gravida. Risus commodo viverrmaecenas
                    accumsan lacus vel facilisis. Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                    labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
                    gravida. commodo viverra maecenas accumsan lacus vel facilisis.
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry's standard dummy text
                    ever since the 1500s, when an unknown printer took a galley of type
                    and scrambled it to make a type specimen book. It has survived not
                    only five centuries, but also the leap into electronic typesetting,
                    remaining essentially unchanged. It was popularised in the 1960s
                    with the release of Letraset sheets containing Lorem Ipsum passages,
                    and more recently with desktop publishing software like Aldus
                    PageMaker including versions of Lorem Ipsum
                  </p>
                  <h4>What We Use Your Personal Data For</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                    ipsum suspendisse ultrices gravida. Risus commodo viverrmaecenas
                    accumsan lacus vel facilisis. Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                    labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
                    gravida. commodo viverra maecenas accumsan lacus vel facilisis.
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry's standard dummy text
                    ever since the 1500s, when an unknown printer took a galley of type
                    and scrambled it to make a type specimen book. It has survived not
                    only five centuries, but also the leap into electronic typesetting,
                    remaining essentially unchanged. It was popularised in the 1960s
                    with the release of Letraset sheets containing Lorem Ipsum passages,
                    and more recently with desktop publishing software like Aldus
                    PageMaker including versions of Lorem Ipsum
                  </p>
                  <div className="list">
                    <ul>
                      <li>
                        <i className="fa-solid fa-check" />
                        Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod
                        tempor commodo viverra
                      </li>
                      <li>
                        <i className="fa-solid fa-check" />
                        It is a long established fact that a reader will be distracted
                      </li>
                      <li>
                        <i className="fa-solid fa-check" />
                        Contrary to popular belief, Lorem Ipsum is not simply random
                        text.
                      </li>
                    </ul>
                  </div>
                  <h4>How Do We Protect Information?</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                    ipsum suspendisse ultrices gravida. Risus commodo viverrmaecenas
                    accumsan lacus vel facilisis. Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                    labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
                    gravida. commodo viverra maecenas accumsan lacus vel facilisis.
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry's standard dummy text
                    ever since the 1500s, when an unknown printer took a galley of type
                    and scrambled it to make a type specimen book. It has survived not
                    only five centuries, but also the leap into electronic typesetting,
                    remaining essentially unchanged. It was popularised in the 1960s
                    with the release of Letraset sheets containing Lorem Ipsum passages,
                    and more recently with desktop publishing software like Aldus
                    PageMaker including versions of Lorem Ipsum
                  </p>
                  <h4>Password</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                    ipsum suspendisse ultrices gravida. Risus commodo viverrmaecenas
                    accumsan lacus vel facilisis. Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                    labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
                    gravida. commodo viverra maecenas accumsan lacus vel facilisis.
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry's standard dummy text
                    ever since the 1500s, when an unknown printer took a galley of type
                    and scrambled it to make a type specimen book. It has survived not
                    only five centuries, but also the leap into electronic typesetting,
                    remaining essentially unchanged. It was popularised in the 1960s
                    with the release of Letraset sheets containing Lorem Ipsum passages,
                    and more recently with desktop publishing software like Aldus
                    PageMaker including versions of Lorem Ipsum
                  </p>
                  <h4>How You Can Access, Update or Delete Your Personal Data</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                    ipsum suspendisse ultrices gravida. Risus commodo viverrmaecenas
                    accumsan lacus vel facilisis. Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                    labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
                    gravida. commodo viverra maecenas accumsan lacus vel facilisis.
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry's standard dummy text
                    ever since the 1500s, when an unknown printer took a galley of type
                    and scrambled it to make a type specimen book. It has survived not
                    only five centuries, but also the leap into electronic typesetting,
                    remaining essentially unchanged. It was popularised in the 1960s
                    with the release of Letraset sheets containing Lorem Ipsum passages,
                    and more recently with desktop publishing software like Aldus
                    PageMaker including versions of Lorem Ipsum
                  </p>
                </div>
              </div>
              {/* <div className="col-lg-4">
                <div className="sidebar">
                  <div className="single-sidebar-widget search-bar">
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Search..."
                      />
                      <button type="submit" className="default-btn btn">
                        <i className="flaticon-search" />
                      </button>
                    </div>
                  </div>
                  <div className="single-sidebar-widget categories">
                    <h3>Categories</h3>
                    <ul>
                      <li>
                        <p href="blog-grid.html">Design</p>
                      </li>
                      <li>
                        <p href="blog-grid.html">Lifestyle</p>
                      </li>
                      <li>
                        <p href="blog-grid.html">Camping</p>
                      </li>
                      <li>
                        <p href="blog-grid.html">Job</p>
                      </li>
                      <li>
                        <p href="blog-grid.html">Device</p>
                      </li>
                      <li>
                        <p href="blog-grid.html">Internet</p>
                      </li>
                    </ul>
                  </div>
                  <div className="single-sidebar-widget widget-peru-posts-thumb">
                    <h3>Popular Post</h3>
                    <div className="post-wrap">
                      <article className="item">
                        <p href="blog-details.html" className="thumb">
                          <span className="fullimage cover bg1" role="img" />
                        </p>
                        <div className="info">
                          <time dateTime="2024-06-30">July 30, 2024</time>
                          <h4 className="title usmall">
                            <p href="blog-details.html">
                              We’ve Weeded Through Hundreds Of Job Hunting
                            </p>
                          </h4>
                        </div>
                        <div className="clear" />
                      </article>
                      <article className="item">
                        <p href="blog-details.html" className="thumb">
                          <span className="fullimage cover bg2" role="img" />
                        </p>
                        <div className="info">
                          <time dateTime="2024-06-30">July 30, 2024</time>
                          <h4 className="title usmall">
                            <p href="blog-details.html">
                              Today From Connecting With Potential Employers
                            </p>
                          </h4>
                        </div>
                        <div className="clear" />
                      </article>
                      <article className="item">
                        <p href="blog-details.html" className="thumb">
                          <span className="fullimage cover bg3" role="img" />
                        </p>
                        <div className="info">
                          <time dateTime="2024-06-30">July 30, 2024</time>
                          <h4 className="title usmall">
                            <p href="blog-details.html">
                              We Do Friendly Behave With Our All Employee
                            </p>
                          </h4>
                        </div>
                        <div className="clear" />
                      </article>
                    </div>
                  </div>
                  <div className="single-sidebar-widget tags">
                    <h3>Tags</h3>
                    <p href="blog-grid.html">Business</p>
                    <p href="blog-grid.html">Internet</p>
                    <p href="blog-grid.html">IT &amp; Support</p>
                    <p href="blog-grid.html">SASS</p>
                    <p href="blog-grid.html">Tips</p>
                    <p href="blog-grid.html">Device</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default TermsConditions;
