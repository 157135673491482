import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as CompanyDashboardActions from '../../dashboard/dashboardcountActions';
import StatsBlock from '../../dashboard/statsBlock';
import { getLatestOrder } from '../../companyPanel/redux/actions';
import LatestOrderBlock from './latestOrderBlock';

import styles from './styles.module.scss';

const CompanyDashboard = () => {
  const dashboardreviewcount = useSelector((state) => state?.userdashboardcount?.dashboardreviewcount);
  const dashboardcompanycount = useSelector((state) => state?.userdashboardcount?.dashboardcompanycount);

  const [firstInit, setFirstInit] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (firstInit) {
      dispatch(CompanyDashboardActions.getdashboardreviewcount());
      dispatch(CompanyDashboardActions.getcompanydashboardcount());
      dispatch(getLatestOrder());
      setFirstInit(false);
    }
  }, [firstInit]);

  useEffect(() => {
    setFirstInit(true);
  }, []);

  return (
    <>
      <div className="company-dashboard dashboard-fun-fact-area">
        <div className="row">
          <StatsBlock
            icon="fa-regular fa-hourglass-half"
            label="Pending"
            count={dashboardcompanycount?.pending || 0}
          />
          <StatsBlock
            icon="fa-regular fa-thumbs-up"
            label="Approved"
            count={dashboardcompanycount?.approved || 0}
          />
          <StatsBlock
            icon="fa fa-xmark"
            label="Rejected"
            count={dashboardcompanycount?.rejected || 0}
          />
          <StatsBlock
            icon="fa fa-box-open"
            label="Total Orders"
            count={dashboardreviewcount?.data || 0}
          />
        </div>
      </div>

      <LatestOrderBlock styles={styles} />
    </>
  )
}
export default CompanyDashboard;
