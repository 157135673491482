import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import Breadcrumb from '../../component/breadcrumbs';
import * as TeamLeaderCompaniesActions from './teamleaderCompanylistActions';
import { TEAMLEADERUSERS } from '../../global/routes';
import styles from './styles.module.scss'

/**
 * @author      Nandani.V.Patel
 * @date        8th Oct 2024
 * @description UI Implementation of Team-leader Company list
 * @param
 * @response 
**/

const TeamLeaderCompanyList = (props) => {
  const { teamleadercompanies, actions } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [firstInit, setFirstInit] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [company, setcompany] = useState('');

  const queryParams = new URLSearchParams(location.search);
  const team_member_id = queryParams.get('userid'); // For query parameter

  const breadcrumbs = [
    { name: 'Team Members', link: TEAMLEADERUSERS },
    { name: 'Company List', link: "" },
  ];

  useEffect(() => {
    if (firstInit) {
      actions.getTeamleaderCompanyRequest({ user_id: team_member_id, page, per_page: perPage });
      setFirstInit(false);
    }
  }, [firstInit, dispatch, team_member_id, page, perPage]);

  useEffect(() => {
    setFirstInit(true);
  }, [page, perPage]);

  useEffect(() => {
    if (typeof teamleadercompanies?.data !== 'undefined' && !teamleadercompanies?.isteamleadercompany) {
      setcompany(teamleadercompanies?.data?.data);
    }
  }, [teamleadercompanies?.data, teamleadercompanies?.isteamleadercompany]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setPage(1); // Reset to first page when rows per page changes
  };

  const columns = [
    {
      name: 'Company Name',
      selector: row => row?.company_tbl_name || '-',
      sortable: true,
    },
    {
      name: 'Country Name',
      selector: row => row?.country_name || '-',
    },
    {
      name: 'Company Registration No',
      selector: row => row?.company_registration_number || '-',
    },
    {
      name: 'Company Pincode',
      minWidth: '50px',
      maxWidth: '170px',
      selector: row => row?.company_pincode || '-',
    },
    {
      name: "Action",
      minWidth: '50px',
      maxWidth: '160px',
      center: 'yes',
      cell: (row) => (
        <div className="action-btn">
          <button
            className={`${styles.iconStyle} option-btn d-inline-block`}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="View Review site"
            type="button"
            onClick={() =>
              navigate(`${TEAMLEADERUSERS}?userid=${team_member_id}&companyid=${row.id}`)
            }
          >
            <i className="fa-solid fa-eye" />
          </button >
        </div>
      ),
    },
  ];

  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div className={`d-flex mt-2 p-3 mb-4 header-box`}>
        <h4 className={`col-md-12 header-text`}>
          Company List
        </h4>
      </div>
      <DataTable
        columns={columns}
        data={company}
        theme="solarized"
        fixedHeader
        pagination
        paginationServer
        paginationTotalRows={teamleadercompanies?.data?.total}
        paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerPageChange}
        responsive
      />
    </>
  )
}
const mapStateToProps = (state) => ({
  teamleadercompanies: state.teamleadercompanies,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(TeamLeaderCompaniesActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamLeaderCompanyList);
